import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import uploadProfileImg from "../../imgaes/uploadProfileImg.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import OTP from "../Login/OTP/OTP";
import { getOTPData, sendOTPtoMail } from "../../Store/LoginSlice";
import {
  getUserProfileData,
  updateProfileUserPicture,
  updateProfileUserSettings,
} from "../../Store/ProfileSettingsSlice";
import BaseUrl from "../../utils/config";
import deleteIcon from "../../imgaes/deleteIcon.png";
import axios from "axios";
import TranslateWord from "../sharedComponents/TranslateWord";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const ProfileAboutYourSelf = () => {
  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const dispatch = useDispatch();
  const [profileAboutYourSelf] = Form.useForm();
  const [addressFrom] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const [fileList, setFileList] = useState([]);
  const [fModalVisible, setFModalVisible] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [emptyFieldIndexArr, setEmptyFieldIndexArr] = useState([]);
  const inputRefs = useRef([]);
  const mypancardRef = useRef(null);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [currentFormsList, setCurrentFormsList] = useState(null);
  const [removingItem, setRemovingItem] = useState(null);
  const [removedList, setRemovedList] = useState([]);
  const [subLevelOldData, setSubLevelOldData] = useState(null);
  const [dupIndex, setDupIndex] = useState([]);
  const [fileListForAddhar, setFileListForAddhar] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileListForPANcard, setFileListForPANcard] = useState([]);
  //   const [isModalVisible, setIsModalVisible] = useState(false);
  let curentListValues = [];
  const { data: genderData } = useSelector((state) => state.genderList);
  const { data: maritalStatusData } = useSelector(
    (state) => state.maritalStatusList
  );
  const {      
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const { userProfileData, workExperienceData, educationQualificationData } =
    useSelector((state) => state.profileSettingsList);
  useEffect(() => {
    var dob = new Date(moment(userProfileData?.user_profile?.date_of_birth));
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var ageDiff = Math.abs(year - 1970);
    if (userProfileData?.profile_url) {
      fileList.splice(0, fileList.length);
      // to view image file
      const profImageObj = {
        uid: "-1",
        name: `Profile picture`,
        status: "done",
        url: userProfileData?.profile_url ? userProfileData?.profile_url : null,
        response: '{"status": "success"}', // response from server
        linkProps: '{"download": "image"}', // additional html props of file link
        xhr: "{}",
      };
      fileList.push(profImageObj);
    } else {
      setFileList([]);
    }
    if (userProfileData?.user_profile?.aadhar_image?.image_url) {
      fileListForAddhar.splice(0, fileListForAddhar.length);
      // to view image file
      const addharImageObj = {
        uid: "-1",
        // name: record?.empName,
        name: `Aadhar card`,
        status: "done",
        url: userProfileData?.user_profile?.aadhar_image?.image_url
          ? userProfileData?.user_profile?.aadhar_image?.image_url
          : null,
      };
      fileListForAddhar.push(addharImageObj);
    } else {
      setFileListForAddhar([]);
    }
    if (userProfileData?.user_profile?.pan_image?.image_url) {
      fileListForPANcard.splice(0, fileListForPANcard.length);
      // to view image file
      const panImageObj = {
        uid: "-1",
        // name: record?.empName,
        name: `Pan card`,
        status: "done",
        url: userProfileData?.user_profile?.pan_image?.image_url
          ? userProfileData?.user_profile?.pan_image?.image_url
          : null,
      };
      fileListForPANcard.push(panImageObj);
    } else {
      setFileListForPANcard([]);
    }
    profileAboutYourSelf.setFieldsValue({
      firstname: userProfileData?.user_profile?.firstname,
      lastname: userProfileData?.user_profile?.lastname,
      display_name: userProfileData?.user_profile?.display_name,
      mobile_number: userProfileData?.user_profile?.mobile_number,
      gender: userProfileData?.user_profile?.gender,
      marital_status: userProfileData?.user_profile?.marital_status,
      date_of_birth: moment(userProfileData?.user_profile?.date_of_birth),
      gmail_id: userProfileData?.gmail_id,
      age: ageDiff,
      location: userProfileData?.user_profile?.location,
      state: userProfileData?.user_profile?.state,
      mandal: userProfileData?.user_profile?.mandal,
      district: userProfileData?.user_profile?.district,
      village: userProfileData?.user_profile?.village,
      breif_about_self: userProfileData?.user_profile?.breif_about_self,
      educational_qualification_id:
        userProfileData?.user_profile?.educational_qualification_id,
      work_experience_id: userProfileData?.user_profile?.work_experience_id,
      aadhar_number:  userProfileData?.user_profile?.aadhar?.replace(/-/g, ""),
      
      pancard_number: userProfileData?.user_profile?.pancard,

      bank_account_number: userProfileData?.user_profile?.bank_account_number,
      bank_address: userProfileData?.user_profile?.bank_address,
      bank_ifsc_code: userProfileData?.user_profile?.bank_ifsc_code,
      bank_name: userProfileData?.user_profile?.bank_name,
      swift_code: userProfileData?.user_profile?.swift_code,
    });
  }, [userProfileData]);
  const submitProfileAboutYourSelfForm = (values) => {
    let user_id = userProfileData?.user_id;
    const payload = {
      user_id: user_id,
      firstname: values?.firstname,
      lastname: values?.lastname,
      display_name: values?.display_name,
      mobile_number: values?.mobile_number,
      gender: values?.gender,
      marital_status: values?.marital_status,
      date_of_birth: moment(values?.date_of_birth),
      gmail_id: values?.gmail_id,
      // age: values?.age,
      location: values?.location,
      state: values?.state,
      mandal: values?.mandal,
      district: values?.district,
      village: values?.village,
      breif_about_self: values?.breif_about_self,
      educational_qualification_id: values?.educational_qualification_id,
      work_experience_id: values?.work_experience_id,
      aadhar_number: values?.aadhar_number,
      pancard_number: values?.pancard_number,

      bank_account_number: values?.bank_account_number,
      bank_address: values?.bank_address,
      bank_ifsc_code: values?.bank_ifsc_code,
      bank_name: values?.bank_name,
      swift_code: values?.swift_code,
    };
    dispatch(updateProfileUserSettings(payload)).then((action) => {
      if (updateProfileUserSettings.fulfilled.match(action)) {
        dispatch(getUserProfileData(user_id));
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
    // const payload = {
    //   interface_lang_id: values?.interfaceLang,
    //   user_id: user?.user_id,
    // };
    // dispatch(selectingInterfaceLanguage(payload));
  };
  const submitAddressSelfForm = (values) => {
    // const payload = {
    //   interface_lang_id: values?.interfaceLang,
    //   user_id: user?.user_id,
    // };
    // dispatch(selectingInterfaceLanguage(payload));
  };
  const handleDOB = (value) => {
    console.log("value", value);
    if (value === null) {
      profileAboutYourSelf.setFieldsValue({
        age: "",
      });
      return;
    }
    var dob = new Date(moment(value));
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var ageDiff = Math.abs(year - 1970);
    profileAboutYourSelf.setFieldsValue({
      age: ageDiff,
    });
  };
  const handleuChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let user_id = userProfileData?.user_id;
    var payload = new FormData();

    // if (fileList?.[0]?.originFileObj) {
    // }
    // else {
    //   toast.error('Please Upload image');
    // }

    // if (newFileList?.length === 1 && newFileList?.[0]?.originFileObj) {

    //   if (newFileList?.[0]?.originFileObj) {
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //       const binaryData = e.target.result;
    //       console.log('Binary data:', binaryData);
    //       // You can now use binaryData as needed, such as sending it to a server or processing it further
    //     };
    //     reader.readAsBinaryString(fileList?.[0]?.originFileObj);
    //   }

    //   payload.append("profileimage", fileList?.[0]?.originFileObj);
    //   dispatch(updateProfileUserPicture(payload))
    // }

    // if (newFileList?.length === 1 && newFileList?.[0]?.originFileObj) {
    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     const binaryData = e.target.result;
    //     console.log('Binary data:', binaryData);

    //     const payload = new FormData();
    //    / Append the binary data to the FormData

    //     // Dispatch the action with the FormData containing the binary data
    //     dispatch(updateProfileUserPicture(payload));
    //   };
    //   reader.readAsBinaryString(newFileList?.[0]?.originFileObj);
    // }

    var data1 = new FormData();
    if (newFileList?.[0]?.originFileObj) {
      const file = newFileList[0]?.originFileObj;
      if (file) {
        const formData = new FormData();
        formData.append("profileimage", file);
        console.log("FormData:", formData); // Che

        dispatch(updateProfileUserPicture(formData));
      }
    }
  };
  const uploadButton = (
    <div style={{ position: "relative", top: "12vh" }}>
      <Button>
        <img src={uploadProfileImg} style={{ cursor: "pointer" }} />
        <input
          hidden
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => fileChangedHandler(e)}
        />
      </Button>
    </div>
  );
  const uploadButtonAadhar = (
    <div style={{ position: "relative", top: "12vh" }}></div>
  );
  const uploadButtonPanCard = (
    <div style={{ position: "relative", top: "12vh" }}></div>
  );

  const handlePreview = async (file) => {
    console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setFModalVisible(true);
    setImagePreview(src);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleImageModelCancel = () => {
    // setIsModalVisible(false);
    setFModalVisible(false);
  };
  const handleOpenOTPModel = () => {
    const payload = {
      first_name: userProfileData?.user_profile?.firstname,
      mobile_number: userProfileData?.gmail_id,
    };

    // localStorage.setItem('userDetails', JSON.stringify(payload))
    // handleCancel();
    //  openingOTPlsModel(true);
    let gmail = userProfileData?.gmail_id;
    let user_id = userProfileData?.user_id;
    dispatch(sendOTPtoMail({ gmail, user_id }))
      .then((action) => {
        if (sendOTPtoMail.fulfilled.match(action)) {
          message.success({
            content: "OTP sent successfully",
            duration: 5, // Specify the duration in seconds
            style: {
              marginTop: "20px", // Adjust the margin if needed
              marginBottom: "20px", // Add margin at the bottom
            },
          });
        }
      })
      .catch((err) => {
        message.error({
          content: "Some thing went wrong",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      });
    setIsOTPModalOpen(true);
  };
  const handleCloseLoginModel = (data) => {
    setIsOTPModalOpen(false);
  };

  const handleRemove = (index) => {
    const newValues = [
      ...profileAboutYourSelf.getFieldValue("socialMediaAccounts"),
    ];
    setEmptyFieldIndexArr([]);
    // setDupIndex([])
    // setLengthError([])
    const fieldValueToRemove = newValues[index];
    if (!newValues?.includes("")) {
      setDisableAddBtn(false);
    }

    if (fieldValueToRemove === undefined) {
      const myupdated = newValues.filter((value) => value !== undefined);
      setSubLevelOldData(myupdated);
      profileAboutYourSelf.setFieldsValue({
        socialMediaAccounts: myupdated,
      });
      return;
    }
    setCurrentFormsList(newValues);
    setRemovingItem(fieldValueToRemove);
    if (fieldValueToRemove) {
      updateRemoveValuesFromCurrentList(newValues, fieldValueToRemove);
    }
  };
  const updateRemoveValuesFromCurrentList = (newValues, fieldValueToRemove) => {
    const data = newValues?.filter((value) => value !== fieldValueToRemove);
    const copyRemovedData = [...removedList];
    copyRemovedData.push(fieldValueToRemove);
    setRemovedList(copyRemovedData);
    const afterRemoveoldData = subLevelOldData?.filter(
      (value) => value !== fieldValueToRemove
    );
    setSubLevelOldData(afterRemoveoldData);
    // setSubLevelBackup(afterRemoveoldData);
    profileAboutYourSelf.setFieldsValue({
      socialMediaAccounts: data,
    });
    setCurrentFormsList(data);
    setRemovingItem(null);
  };
  const validateAadharUpload = (rule, value, callback) => {
    if (fileListForAddhar?.length === 0) {
      callback("Upload Aadhar Card");
    } else {
      callback();
    }
  };

  const dummyRequestForAAdhar = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const dummyRequestForPAN = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreviewForAAdhar = async (file) => {
    console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );

    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setFModalVisible(true);
    setImagePreview(src);
  };
  const handlePreviewForPANcard = async (file) => {
    // console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );

    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setFModalVisible(true);
    setImagePreview(src);
  };
  const handleuChangeForAddhar = ({ fileList: newFileList }) => {
    setFileListForAddhar(newFileList);
    // if (currentAction === "update" && buData.aadhaar_url === null) {
    //   deleteform.resetFields();
    //   setDeletedAadharComments("aadharcard");
    //   setModalTitle("Uploading Image");
    //   setIsModalVisible(true);
    // }
    // if (newFileList?.length == 0) {
    //   // showAuditModal("is_primary_tag", true ? "true" : "false", "yest", '')fileListForAddhar fileListForPANcard
    //   if (currentAction === "update") {
    //     deleteform.resetFields();
    //     setDeletedAadharComments("aadharcard");
    //     setModalTitle("Current uploaded Aadhar");
    //     setIsModalVisible(true);
    //   }
    // }
  };
  const handleuChangeForPANcard = ({ fileList: newFileList }) => {
    setFileListForPANcard(newFileList);
    // if (currentAction === "update" && buData.pancard_url === null) {
    //   deleteform.resetFields();
    //   setDeletedPanComments("pancard_number");
    //   setModalTitle("Uploading Image");
    //   setIsModalVisible(true);
    // }
    // if (newFileList?.length == 0) {
    //   if (currentAction === "update") {
    //     deleteform.resetFields();
    //     setDeletedPanComments("pancard_number");
    //     setModalTitle("Current uploaded Pancard");
    //     setIsModalVisible(true);
    //   }
    // }
  };
  const transformInput = (value) => {
    return value ? value.toUpperCase() : value;
  };

  const fileChangedHandler = async (event) => {
    const files = event.target.files;
    var formdata = new FormData();

    formdata.append("profileimage", files[0]);
    let user_id = userProfileData?.user_id;
    // dispatch(updateProfileUserPicture(formData));
    var requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch(`${BaseUrl}/user/profileimage?user_id=${user_id}`, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res?.profile_image_url) dispatch(getUserProfileData(user_id));
        else console.error("something went wrong while uploading user profile");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const kycfilechangehandler = async (event, type) => {
    let user_id = userProfileData?.user_id;
    const files = event.target.files;
    if (files) {
      var formdata = new FormData();

      formdata.append("kycimage", files[0]);
      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch(
        `${BaseUrl}/user/kycimage?user_id=${user_id}&kyc_type=${type}`,

        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res) dispatch(getUserProfileData(user_id));
          else
            console.error("something went wrong while uploading user profile");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const onImageRemove = async (value) => {
    let user_id = userProfileData?.user_id;
    const result = await axios.delete(
      `${BaseUrl}/user/kycimage?image_id=${value}`
    );
    if (result.data?.is_success) dispatch(getUserProfileData(user_id));
    else console.error("something went wrong while uploading user profile");
  };

  return (
    <>
      <Tabs tabPosition="left">
        <TabPane tab={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ABOUTYOURSELF"}
                               text={"ABOUTYOURSELF"}
                               lang={selectedInterfaceLanguage}
                               
                             />
                              )} key="About your self">
          <div style={{ marginBottom: "3vh" }}>
            <h1>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ABOUTYOURSELF"}
                               text={"ABOUTYOURSELF"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={true}
                             />
                              )}</h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileAboutYourSelfForm}
            >
              <Row>
                <Col span={12}>
                  <Form.Item name="firstname" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"FIRSTNAME"}
                               text={"FIRSTNAME"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )} >
                    <Input name="firstname" />
                  </Form.Item>
                  <Form.Item name="lastname" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"LASTNAME"}
                               text={"LASTNAME"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                    <Input name="lastname" />
                  </Form.Item>
                  <Form.Item name="display_name" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"DISPLAYNAME"}
                               text={"DISPLAYNAME"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                    <Input name="display_name" />
                  </Form.Item>
                  <Form.Item name="mobile_number" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"MOBILENUMBER"}
                               text={"MOBILENUMBER"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                    <Input name="mobile_number" />
                  </Form.Item>
                  <Row>
                    <Col span={18}>
                      <Form.Item name="gmail_id" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"EMAIL_ID"}
                               text={"EMAIL_ID"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                        <Input name="gmail_id" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Button
                        style={{ position: "relative", top: "5vh" }}
                        onClick={handleOpenOTPModel}
                        type="link"
                      >
                     {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"SEND_OTP"}
                               text={"SEND_OTP"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={18}>
                      <Form.Item name="date_of_birth" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"DOB"}
                               text={"DOB"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                        {/* <Space direction="vertical"> */}
                        <DatePicker
                          picker="date"
                          placeholder="Enter DOB"
                          format={dateFormat}
                          style={{ width: "24vw" }}
                          onChange={handleDOB}
                          // ref={myDOBRef}

                          // value={
                          //   (entereddate && moment(entereddate, "YYYY-MM-DD")) ||
                          //   null
                          // }
                        />
                        {/* </Space> */}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="age" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"AGE"}
                               text={"AGE"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                        <Input name="age" readOnly />
                      </Form.Item>
                    </Col>
                    <span
                      style={{
                        fontSize: "11px",
                        position: "relative",
                        top: "-3vh",
                      }}
                    >
                      Minimum age of users to post content (user generated
                      content) to be 13 years.
                    </span>
                  </Row>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item name="gender" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"GENDER"}
                               text={"GENDER"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                        <Select placeholder="Select Gender">
                          {genderData?.map((gender) => (
                            <Option key={gender.gender} value={gender.gender}>
                              {gender.gender}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="marital_status" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"MARITAL_STATUS"}
                               text={"MARITAL_STATUS"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                        <Select placeholder="Select Marital Status">
                          {maritalStatusData?.map((status) => (
                            <Option
                              key={status.marital_status}
                              value={status.marital_status}
                            >
                              {status.marital_status}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                    >
                      {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"UPDATE"}
                               text={"UPDATE"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div style={{ marginLeft: "4vw", marginBottom: "5vh" }}>
                    {" "}
                    Upload Profile
                  </div>

                  {/* <div style={{textAlign:'center'}}> <img src={userProfilePic} /></div> */}
                  {/* <div style={{textAlign:'center',marginTop:'2vh'}}> <img src={uploadProfileImg}  style={{cursor:'pointer'}}/></div> */}
                  {/* <div style={{ textAlign: "center" }}>
                    <Upload
                      action="http://localhost:3000/"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleuChange}
                      customRequest={dummyRequest}
                    >
                      {fileList.length === 1 ? null : uploadButton}
                    </Upload>
                  </div> */}

                  <div style={{ textAlign: "center" }}>
                    <div>
                      <img
                        src={userProfileData?.profile_url}
                        width={100}
                        height={100}
                      />
                    </div>

                    <div>
                      <label for="file-upload" class="custom-file-upload">
                        Upload Image
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => fileChangedHandler(e)}
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>

                  <div className="fileViewModel">
                    <Modal
                      title="Image view"
                      visible={fModalVisible}
                      onCancel={handleImageModelCancel}
                      footer={null}
                      width={500}
                    >
                      <div className="modelIcon "></div>
                      {/* <iframe src={imagePreview} width="100%" height="400px" ></iframe> */}
                      <img
                        src={imagePreview}
                        alt="Girl in a jacket"
                        width="450"
                        height="410"
                      />
                    </Modal>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
        <TabPane tab={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ADDRESS"}
                               text={"ADDRESS"}
                               lang={selectedInterfaceLanguage}                               
                             />
                              )} key="Address">
          <div>
            <h1>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ADDRESS"}
                               text={"ADDRESS"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={true}
                             />
                              )}</h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileAboutYourSelfForm}
            >
              <Row>
                <Col span={22}>
                  <Form.Item name="location" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"LOCATION"}
                               text={"LOCATION"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )}>
                    <Input name="location" />
                  </Form.Item>

                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item name="state" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"STATE"}
                               text={"STATE"}
                               lang={selectedInterfaceLanguage}

                             />
                              )}>
                        <Input name="state" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="district" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"DISTRICT"}
                               text={"DISTRICT"}
                               lang={selectedInterfaceLanguage}

                             />
                              )}>
                        <Input name="district" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item name="mandal" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"MANDAL"}
                               text={"MANDAL"}
                               lang={selectedInterfaceLanguage}

                             />
                              )}>
                        <Input name="mandal" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="village" label={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"VILLAGE_AREA_LOCALITY"}
                               text={"VILLAGE_AREA_LOCALITY"}
                               lang={selectedInterfaceLanguage}

                             />
                              )}>
                        <Input name="village" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                    >
                      {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"UPDATE"}
                               text={"UPDATE"}
                               lang={selectedInterfaceLanguage}

                             />
                              )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
        <TabPane tab={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ADDITIONAL_INFORMATION"}
                               text={"ADDITIONAL_INFORMATION"}
                               lang={selectedInterfaceLanguage}
                               
                             />
                              )} key="Additional Information">
          <div style={{ marginBottom: "3vh" }}>
            <h1>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ADDITIONAL_INFORMATION"}
                               text={"ADDITIONAL_INFORMATION"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={true}
                             />
                              )}</h1>
          </div>
          <Form
            form={profileAboutYourSelf}
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={submitProfileAboutYourSelfForm}
          >
            <Row>
              <Col span={22}>
                <Form.Item
                  name="breif_about_self"
                  label={selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"ADDITIONAL_INFORMATION_CLEARLY"}
                    text={"ADDITIONAL_INFORMATION_CLEARLY"}
                    lang={selectedInterfaceLanguage}
                    
                  />
                   )}
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Row gutter={5}>
                  <Col span={12}>
                    <Form.Item
                      name="educational_qualification_id"
                      label={selectedInterfaceLanguage && (
                        <TranslateWord
                        key={"EDUCATIONAL_QUALIFICATIONS"}
                        text={"EDUCATIONAL_QUALIFICATIONS"}
                        lang={selectedInterfaceLanguage}
                        
                      />
                       )}
                    >
                      <Select placeholder="Select">
                        {educationQualificationData?.map((qualification) => (
                          <Option
                            key={qualification.eq_id}
                            value={qualification.eq_id}
                          >
                            {qualification.eq_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="work_experience_id" label="Occupation">
                      <Select placeholder="Select">
                        {workExperienceData?.map((occ) => (
                          <Option key={occ.we_id} value={occ.we_id}>
                            {occ.we_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={5}>
                  <Col span={12}>
                    <Form.Item
                      name="Brief of Educational Qualifications"
                      label="Brief of Educational Qualifications"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="Brief of Occupation"
                      label="Brief of Occupation"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={5}>
                  <Col span={24}>
                    <Form.List name="socialMediaAccounts">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={
                                index === 0
                                  ? "Link your social media accounts"
                                  : ""
                              }
                              required={true}
                              key={field.key}

                              // validateStatus={
                              //   emptyFieldIndexArr.includes(index) ? "error" : dupIndex.includes(index) ? "error" : lengthError.includes(index) ? "error" : ""
                              // }
                              // help={
                              //   emptyFieldIndexArr.includes(index)
                              //     ? "Please Enter Link your social media accounts or delete this field."
                              //     : dupIndex.includes(index)
                              //       ? "Duplicate Data Error" : lengthError.includes(index) ? "Maximum 10 charecters allowed"
                              //         : ""
                              // }
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange"]}
                                // rules={[
                                // {
                                //   required: true,
                                //   whitespace: true,
                                //   message:
                                //     "Please Enter Link your social media accounts or delete this field.",
                                // },
                                // {
                                //   max: 10,
                                //   message: "Link your social media accounts must be lessthan 10 characters",
                                // },
                                // ]}
                                noStyle
                                shouldUpdate={(prevValues, curValues) => {
                                  curentListValues =
                                    curValues?.socialMediaAccounts;
                                }}
                              >
                                <Input
                                  placeholder="Enter Link your social media accounts"
                                  style={{
                                    width: "80%",
                                  }}
                                  ref={(input) =>
                                    (inputRefs.current[index] = input)
                                  }
                                />
                              </Form.Item>
                              <MinusCircleOutlined
                                // onClick={() => {
                                //   remove(field.name);
                                //   getRemovedItem(field);
                                // }}
                                style={{
                                  fontSize: "18px",
                                  color: "#f34b4b",
                                  margin: "4px 0px 0px 5px",
                                }}
                                onClick={() => handleRemove(index)}
                              />
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              disabled={
                                dupIndex.length != 0 ||
                                emptyFieldIndexArr.length != 0
                              }
                              onClick={() => {
                                add();
                                // focusFunction();
                              }}
                              icon={<PlusOutlined />}
                              style={{
                                border: "1px solid lightgray",
                                pointerEvents: disableAddBtn ? "none" : null,
                              }}
                              // disabled={disableAddBtn}
                            >
                              Add Link your social media accounts
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"KYC"}
                               text={"KYC"}
                               lang={selectedInterfaceLanguage}
                               
                             />
                              )} key="KYC">
          <div>
            <h1>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"KYC"}
                               text={"KYC"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={true}
                             />
                              )}</h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileAboutYourSelfForm}
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    name="aadhar_number"
                    label={selectedInterfaceLanguage && (
                      <TranslateWord
                      key={"AADHAR_NUMBER"}
                      text={"AADHAR_NUMBER"}
                      lang={selectedInterfaceLanguage}
                      
                    />
                     )} 
                    rules={[
                      {
                        required: true,
                        message: "Enter Aadhar Number",
                      },
                      {
                        whitespace: true,
                        message: "Aadhar Number can not be empty",
                      },
                      { max: 12, message: "Aadhar Number should be 12 digits" },
                      { min: 12, message: "Aadhar Number should be 12 digits" },
                      // {
                      //   pattern: new RegExp(/^(\d{4}-){3}\d{4}$/),
                      //   message: "Numbers only allowed",
                      // },
                      // {
                      //   pattern: new RegExp(/^\S+$/),
                      //   message: "Spaces not allowed",
                      // },
                      // {
                      //   validator: validateAadhaarNumber,
                      // },
                    ]}
                  >
                    <Input
                      name="aadhar_number"
                      autoComplete="off"
                      placeholder="1111222233334444"
                      // onChange={chandleChange}

                      // ref={myaadharRef}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <div style={{ textAlign: "center" }}>
                    <div>
                      {userProfileData?.user_profile?.aadhar_image
                        ?.image_url && (
                        <img
                          src={
                            userProfileData?.user_profile?.aadhar_image
                              ?.image_url
                          }
                          width={100}
                          height={100}
                        />
                      )}
                    </div>

                    <div>
                      <label
                        for="file-upload-aadhar"
                        class="custom-file-upload"
                      >
                        {selectedInterfaceLanguage && (
                      <TranslateWord
                      key={"UPLOAD_AADHAR"}
                      text={"UPLOAD_AADHAR"}
                      lang={selectedInterfaceLanguage}
                      
                    />
                     )} 
                       
                      </label>
                     { userProfileData?.user_profile?.aadhar_image
                              ?.image_url &&  <span  style={{cursor:'pointer'}}
                              onClick={() => {
                                onImageRemove(
                                  userProfileData?.user_profile?.aadhar_image
                                    ?.image_id
                                );
                              }}
                            >
                              <img src={deleteIcon} width={20} height={20} />
                            </span>} 
                             

                      <input
                        id="file-upload-aadhar"
                        type="file"
                        accept="image/*"
                        onChange={(e) => kycfilechangehandler(e, "Aadhar")}
                        multiple
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    name="pancard_number"
                    label={selectedInterfaceLanguage && (
                      <TranslateWord
                      key={"PANCARD"}
                      text={"PANCARD"}
                      lang={selectedInterfaceLanguage}
                      
                    />
                     )} 
                    getValueFromEvent={(e) => transformInput(e.target.value)}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Enter PAN Number",
                      // },
                      // { whitespace: true, message: "PAN Number can not be empty" },
                      {
                        pattern: new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]$/),
                        message: "PAN Number format should be AAAAA1234D ",
                      },

                      // {
                      //   max: 10,
                      //   message: "PAN Number must be less than 10 characters",
                      // },
                      //  { validator: validateSubCategoryNameExists } ,
                    ]}
                  >
                    <Input
                      name="pancard_number"
                      autoComplete="off"
                      placeholder="Enter PAN Number"
                      maxLength={10}
                      // onChange={chandleChange}
                      // onKeyUp={convertToUpperCase}

                      ref={mypancardRef}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                <div style={{textAlign:'center'}}>
     <div>
    {userProfileData?.user_profile?.pan_image?.image_url && <img src={userProfileData?.user_profile?.pan_image?.image_url} width={100} height={100}/>} 
     </div>
   
     <div>
     <label for="file-upload-pan" class="custom-file-upload">
   {selectedInterfaceLanguage && (
                      <TranslateWord
                      key={"UPLOAD_PANCARD"}
                      text={"UPLOAD_PANCARD"}
                      lang={selectedInterfaceLanguage}
                      
                    />
                     )} 
</label>
{userProfileData?.user_profile?.pan_image?.image_url && <span style={{cursor:'pointer'}}   onClick={() => {
                                 
                                 onImageRemove(
                                   userProfileData?.user_profile?.pan_image?.image_id
                                 );
                               }}><img src={deleteIcon} width={20} height={20}/></span>}


<input id="file-upload-pan" type="file"  accept="image/*"   onChange={(e) =>
                                kycfilechangehandler(e, "Pan")
                              } multiple style={{display:'none'}}/>
     </div>
      </div>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ float: "right" }}
                >
                  {selectedInterfaceLanguage && (
                      <TranslateWord
                      key={"UPDATE"}
                      text={"UPDATE"}
                      lang={selectedInterfaceLanguage}
                      
                    />
                     )} 
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>
        <TabPane tab={selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"BANK_DETAILS"}
                               text={"BANK_DETAILS"}
                               lang={selectedInterfaceLanguage}
                              
                             />
                              )} key="Bank Details">
          <div>
            <h1>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"BANK_DETAILS"}
                               text={"BANK_DETAILS"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={true}
                             />
                              )}</h1>
          </div>
          <div>
            <Form
              form={profileAboutYourSelf}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={submitProfileAboutYourSelfForm}
            >
              <Row>
                <Col span={22}>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item
                        name="bank_account_number"
                        label={selectedInterfaceLanguage && (
                          <TranslateWord
                          key={"ACCOUNT_NUMBER"}
                          text={"ACCOUNT_NUMBER"}
                          lang={selectedInterfaceLanguage}
                         
                        />
                         )}
                      >
                        <Input name="bank_account_number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="bank_ifsc_code" label={selectedInterfaceLanguage && (
                          <TranslateWord
                          key={"IFSC_CODE"}
                          text={"IFSC_CODE"}
                          lang={selectedInterfaceLanguage}
                         
                        />
                         )}>
                        <Input name="bank_ifsc_code" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item name="bank_name" label={selectedInterfaceLanguage && (
                          <TranslateWord
                          key={"BANKNAME"}
                          text={"BANKNAME"}
                          lang={selectedInterfaceLanguage}
                         
                        />
                         )}>
                        <Input name="bank_name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="bank_address" label={selectedInterfaceLanguage && (
                          <TranslateWord
                          key={"BANK_ADDRESS"}
                          text={"BANK_ADDRESS"}
                          lang={selectedInterfaceLanguage}
                         
                        />
                         )}>
                        <Input name="bank_address" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col span={12}>
                      <Form.Item
                        name="swift_code"
                        label={selectedInterfaceLanguage && (
                          <TranslateWord
                          key={"SWIFT_CODE"}
                          text={"SWIFT_CODE"}
                          lang={selectedInterfaceLanguage}
                         
                        />
                         )}
                      >
                        <Input name="swift_code" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                    >
                      {selectedInterfaceLanguage && (
                          <TranslateWord
                          key={"UPDATE"}
                          text={"UPDATE"}
                          lang={selectedInterfaceLanguage}
                         
                        />
                         )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </TabPane>
      </Tabs>
      {isOTPModalOpen && (
        <OTP
          openFlag={isOTPModalOpen}
          closeLoginModel={handleCloseLoginModel}
          sendingFrom="profileSettings"
          optType="gmail"
        />
      )}
    </>
  );
};

export default ProfileAboutYourSelf;
