import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = { 
  status: "idle",
  error:''  
};

const EmojiSlice = createSlice({
  name: "emojis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateEmojisData.pending, (state,action) => {       
          state.status = "loading";
      });
    builder.addCase(updateEmojisData.fulfilled, (state, action) => {    
      state.status = "idle";
    });
  },
});

export const updateEmojisData = createAsyncThunk("emojis/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/storyemojicomments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export default EmojiSlice.reducer;
