import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { Avatar, Badge, Card, Col, Empty, Pagination, Row, Tabs, Tooltip } from "antd";

import whatsappIcon from "../../imgaes/whatsappIcon.svg";
import messageIcon from "../../imgaes/messageIcon.svg";
import smileIcon from "../../imgaes/smileIcon.svg";
import headphoneIcon from "../../imgaes/headphoneIcon.svg";
import adv1 from "../../imgaes/adv1.svg";
import adv2 from "../../imgaes/adv2.svg";
import noImg from "../../imgaes/noImg.svg";
import noImgJPG from "../../imgaes/noImgJPG.jpg";
import smileGif from '../../imgaes/smileGif.gif';
import cryIcon from '../../imgaes/cryIcon.gif';
import heartIcon from '../../imgaes/heartIcon.gif';
import angryIcon from '../../imgaes/angryIcon.gif';
import likeIcon from '../../imgaes/likeIcon.gif';
import wowIcon from '../../imgaes/wowIcon.gif';
import Modal from "../Modal";
import Emojis from "./Emojis";
import MorEellipsisIcon from "../Sections/Common/MorEellipsisIcon";
import CommentsModal from "./CommentsModal";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../utils/config";

let totalCards = 0;
let filterData = [];
let pageUpdate = 1
function SelectedTypeStories(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const { TabPane } = Tabs;
  const navigate = useNavigate();
  // window.scrollTo(0, 0);
  let storiesData = JSON.parse(localStorage.getItem("allStoriesList"));
  // const allCategoriesData = JSON.parse(
  //   localStorage.getItem("allCategoriesList")
  // );
  // let dataRefresh = false;
  // console.log("allCategoriesData", allCategoriesData);
  // const [allStoriesDataList, setAllStoriesDataList] = useState(storiesData);
  const [categoriesNewsData, setCategoriesNewsData] = useState([]);
  // const [filteredStories, setFilteredStories] = useState([]);
  // let { category, subcategory } = useParams();
  // const [oldSubcategory, setOldSubcategory] = useState(subcategory);
  const [currentPage, setCurrentPage] = useState(1);
  // let activeTab = subcategory ?? "All";
  // const [activeTabColor, setActiveTabColor] = useState(subcategory ?? "All");
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  // const subCategories = allCategoriesData?.find(
  //   (item) => item.category_name === category
  // )?.str_sub_categories_names;

  // subCategories?.unshift("All");

  const storyType = queryParams.get("type")?.replace(/%20/g, " ");
  const selectedStoryType = queryParams.get("selectedType")?.replace(/%20/g, " ");
  // const paths = window.location.pathname.split('/')
  // const storyType = paths?.[3]?.replace(/%20/g, " ");
  // const selectedStoryType = paths?.[4]?.replace(/%20/g, " "); 
  // useEffect(() => {
  //   // setCurrentPage(1); 
  //   const urlPath = window.location.pathname.split("/");
  //   console.log("Current URL:", urlPath[2]);
  //   urlPath[2] = urlPath?.[2]?.replace(/%20/g, " ");
  //   if (oldSubcategory !== urlPath[2]) {
  //     setOldSubcategory(urlPath[2]);
  //     // setActiveTab(urlPath[2]);
  //     setActiveTabColor(urlPath[2]);
  //     activeTab = urlPath[2];
  //   }
  //   category = urlPath[1];
  //   // if (urlPath[2] == undefined) setTimeout(()=>{setActiveTab("All")},10);
  //   if (urlPath[2] == undefined) {
  //     activeTab = "All";
  //     setActiveTabColor("All");
  //   }
  // }, [window.location.pathname]);

  let cardsPerPage = 6;
  let indexOfLastCard = 6;
  let indexOfFirstCard = 0;
  // let displayedData = [];
  const gettingStoriesData = () => { 
//  let filterData = []
     if(storyType === 'Tags'){
      filterData = storiesData?.filter((record) => {
        return (
          record?.tags &&
          record?.tags?.includes(selectedStoryType)
        );
      });
     }
    
     if(storyType === 'Category'){
      filterData =  storiesData?.filter((entry) => {
        const categories = entry.categories || [];
        return categories.some((cat) => cat.name === selectedStoryType);
      });
     }
     if(storyType === 'Sub category'){
      filterData = storiesData?.filter((record) => {
        return (
          record?.categories &&
          record?.categories?.some((cat) =>
            cat?.sub_categories?.includes(selectedStoryType)
          )
        );
      });
     }
     console.log('filterData',filterData)
    
   
    totalCards =
    filterData?.length > 0 ? filterData?.length : 0;
    indexOfLastCard = currentPage * cardsPerPage;
    indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const displayedData =
    filterData?.length > 0
        ? filterData?.slice(indexOfFirstCard, indexOfLastCard)
        : [];
    setCategoriesNewsData(displayedData);
  };

  useEffect(() => {
    gettingStoriesData();
    // setCurrentPage(1);
  }, [storyType,selectedStoryType]);
  const handlePageChange = (page, pageSize) => {
    indexOfLastCard = page * cardsPerPage;
    indexOfFirstCard = indexOfLastCard - cardsPerPage;
    totalCards = filterData?.length > 0 ? filterData?.length : 0;

  const displayedData =
    filterData?.length > 0
      ? filterData?.slice(indexOfFirstCard, indexOfLastCard)
      : [];
  setCategoriesNewsData(displayedData);  
  setCurrentPage(page);  

  };

  useEffect(()=>{
    setCurrentPage(1);
  },[pageUpdate])
 

  const getLatestData = (data) => {    
    storiesData = data
    if(data?.length !== 0)  gettingStoriesData();  
  };
  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };

  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  // const subCategoryData = allStoriesDataList?.find(item => item?.category_name === "Business")?.map(val => val.str_sub_categories_names )
  return (
    <>
      <div>      
        <section class="bg-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="politics foryou_page">
                  <div class="heading_page">
                    <h1>{selectedStoryType}</h1>
                  </div>

                  <div class="breadcrumbs-area">
                    <div class="breadcrumbs-content">
                      <ul>
                        <li>
                          <Link to="/">Home </Link> /{" "}
                        </li>  
                        <li> {storyType}/ </li>                     
                        <li> {selectedStoryType} </li>                     
                        
                      </ul>
                    </div>
                  </div>
                  
                  <div class="mb-20-r ne-isotope">
                    <div class="color-cinnabar ">
                 
                      <div class="featuredContainer politics_container">
                        <div class="row allforyou slider_setting">
                          <div class="col-lg-9 col-md-9">
                            <div class="row">
                              {categoriesNewsData?.length > 0 ? (
                                categoriesNewsData?.map((record) => {
                                  const originalDate = new Date(
                                    record?.pubDate
                                  );
                                  const currentDate = new Date();
                                  const timeDifference =
                                    currentDate - originalDate;
                                  const hours = Math.floor(
                                    timeDifference / (1000 * 60 * 60)
                                  );
                                  const minutes = Math.floor(
                                    (timeDifference % (1000 * 60 * 60)) /
                                      (1000 * 60)
                                  );

                                  let displayTime;
                                  if (timeDifference < 24 * 60 * 60 * 1000) {
                                    // Less than 24 hours
                                    displayTime =
                                      hours > 0
                                        ? `${hours} hrs ago`
                                        : minutes > 0
                                        ? `${minutes} min ago`
                                        : "Less than a minute ago";
                                  } else {
                                    // More than 24 hours
                                    displayTime =
                                      originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                                  }
                                  return (
                                    <>
                                      <div class="col-sm-4 col-12">
                                        <div class="position-relative mt-10 mt-10 forpage_content">
                                          <Link
                                             to={`/about?param1=${record?.id}&category=${storyType}&subcategory=${selectedStoryType}`}
                                            class="img-opacity-hover"
                                          >
                                            {/* <img src={record?.image_urls?.[0]} alt="news" class="img-fluid width-100 mb-15 title_img" /> */}
                                            {record?.image_urls?.[0] ? (
                                              <img
                                                src={record?.image_urls?.[0]}
                                                alt="news"
                                                className="img-fluid width-100 mb-15 title_img"
                                              />
                                            ) : record?.video_url ? (
                                              <>
                                                {" "}
                                                <video
                                                  style={{
                                                    width: "298px",
                                                    height: "190px",
                                                    position: "relative",
                                                    top: "-11px",
                                                    zIndex: "2",
                                                  }}
                                                  preload="metadata"
                                                  src={`${record?.video_url}#t=0.1`}
                                                  controls
                                                  muted
                                                ></video>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={noImgJPG}
                                                  alt="No-Image"
                                                />
                                              </>
                                            )}
                                          </Link>
                                          <Link
                                            to={`/about?param1=${record?.id}&category=${storyType}&subcategory=${selectedStoryType}`}
                                            class="title-medium-dark size-lg mb-none"
                                          >
                                            {/* {record?.title.length > 60
                                              ? record?.title.substring(0, 60) +
                                                "..."
                                              : record?.title} */}
                                              {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                          </Link>
                                          <Row>
                                            <Col
                                              span={16}
                                              style={{
                                                marginLeft: "8px",
                                              }}
                                            >
                                              <span>
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>{" "}
                                              &nbsp;
                                              {/* {record?.author.length > 5 ? record?.author.substring(0, 5) + "..." : record?.author} */}
                                              {record?.author}
                                            </Col>
                                            <Col span={7}>
                                              <p
                                                className="duration_slider"
                                                style={{
                                                  marginTop: "3px",
                                                }}
                                              >
                                                {displayTime}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row
                                            style={{
                                              marginBottom: "10px",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <Col
                                              span={11}
                                              style={{
                                                marginTop: "-8px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              {record?.emoji_comments?.map(
                                                (emj) => {
                                                  return (
                                                    <>
                                                      <Badge
                                                        count={emj?.count}
                                                        size="small"
                                                        placement="start"
                                                      >
                                                        {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                                        {emj?.emoji_name ===
                                                          "angry" && (
                                                          <Avatar
                                                            size="small"
                                                            src={
                                                              <img
                                                                style={{
                                                                  width: "18px",
                                                                  height:
                                                                    "18px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                src={angryIcon}
                                                              />
                                                            }
                                                          />
                                                        )}
                                                        {emj?.emoji_name ===
                                                          "love" && (
                                                          <Avatar
                                                            size="small"
                                                            src={
                                                              <img
                                                                style={{
                                                                  width: "18px",
                                                                  height:
                                                                    "18px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                src={heartIcon}
                                                              />
                                                            }
                                                          />
                                                        )}
                                                        {emj?.emoji_name ===
                                                          "like" && (
                                                          <Avatar
                                                            size="small"
                                                            src={
                                                              <img
                                                                style={{
                                                                  width: "18px",
                                                                  height:
                                                                    "18px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                src={likeIcon}
                                                              />
                                                            }
                                                          />
                                                        )}
                                                        {emj?.emoji_name ===
                                                          "laugh" && (
                                                          <Avatar
                                                            size="small"
                                                            src={
                                                              <img
                                                                style={{
                                                                  width: "18px",
                                                                  height:
                                                                    "18px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                src={smileGif}
                                                              />
                                                            }
                                                          />
                                                        )}
                                                        {emj?.emoji_name ===
                                                          "sad" && (
                                                          <Avatar
                                                            size="small"
                                                            src={
                                                              <img
                                                                style={{
                                                                  width: "18px",
                                                                  height:
                                                                    "18px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                src={cryIcon}
                                                              />
                                                            }
                                                          />
                                                        )}
                                                        {emj?.emoji_name ===
                                                          "wow" && (
                                                          <Avatar
                                                            size="small"
                                                            src={
                                                              <img
                                                                style={{
                                                                  width: "18px",
                                                                  height:
                                                                    "18px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                src={wowIcon}
                                                              />
                                                            }
                                                          />
                                                        )}
                                                      </Badge>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Col>
                                            <Col
                                              span={3}
                                              style={{
                                                marginTop: "-6px",
                                              }}
                                            >
                                              <ul>
                                                <li className="float-right">
                                                  <span className="social_icons">
                                                    <Emojis
                                                      record={record}
                                                      sendLatestData={
                                                        getLatestData
                                                      }
                                                    />
                                                  </span>
                                                </li>
                                              </ul>
                                            </Col>
                                            <Col
                                              span={9}
                                              style={{
                                                marginTop: "-6px",
                                              }}
                                            >
                                              <ul>
                                                <li className="float-right">
                                                  <span className="social_icons">
                                                    {/* <Tooltip
                                                    title="Coming soon"
                                                    color="gray"
                                                  >
                                                    <img
                                                      src={smileIcon}
                                                      alt="icon"
                                                      className="icons_img"
                                                    />
                                                  </Tooltip> */}

                                                    <Tooltip
                                                      title="Coming soon"
                                                      color="gray"
                                                    >
                                                      <img
                                                        src={headphoneIcon}
                                                        className="icons_img"
                                                      />
                                                    </Tooltip>
                                                    <Tooltip title="Coming soon" color="gray">
                                    <img
                                      src={messageIcon}
                                      alt="icon"
                                      className="icons_img"
                                      style={{
                                        position: "relative",
                                        top: "2px",
                                      }}
                                      // onClick={() => {
                                      //   hangleCommentsDrawer(record);
                                      // }}
                                    />                                   
                                     </Tooltip>
                                                    {/* <img
                                                      src={messageIcon}
                                                      alt="icon"
                                                      className="icons_img"
                                                      style={{
                                                        position: "relative",
                                                        top: "2px",
                                                      }}
                                                      onClick={() => {
                                                        hangleCommentsDrawer(
                                                          record
                                                        );
                                                      }}
                                                    /> */}
                                                    <WhatsappShareButton
                                                      url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                                      className="Demo__some-network__share-button"
                                                    >
                                                      <img
                                                        src={whatsappIcon}
                                                        alt="icon"
                                                        className="icons_img"
                                                      />
                                                    </WhatsappShareButton>
                                                    <MorEellipsisIcon
                                                      story={record}
                                                      sendLatestData={
                                                        getLatestData
                                                      }
                                                      bookMarkStoryId={
                                                        record?.id
                                                      }
                                                      page='SelectedTypePage'
                                                    />
                                                  </span>
                                                </li>
                                              </ul>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    textAlign: "center",
                                    marginTop: "100px",
                                  }}
                                >
                                  <Empty />
                                </div>
                              )}
                            </div>                          
                            {categoriesNewsData?.length !== 0 && (
                              <Pagination
                                className="pull-right"
                                current={currentPage}
                                pageSize={cardsPerPage}
                                total={totalCards}
                                onChange={handlePageChange}
                              />
                            )}
                          </div>
                          <div class="ne-sidebar sidebar-break-md col-lg-3 col-md-12">
                            <div class="sidebar-box">
                              <div class="ne-banner-layout1 text-center">
                                <a href="/">
                                  <img
                                    src={adv2}
                                    alt="adv"
                                    class="img-fluid"
                                    style={{ marginBottom: "5px" }}
                                  />
                                </a>
                                <a href="/">
                                  <img src={adv1} alt="adv" class="img-fluid" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
          </div>
        </section>
        {showCommentsDrawer && (
          <CommentsModal
            showDrawer={showCommentsDrawer}
            closeDrawer={handleCloseDrawer}
            story={storyRecord}
          />
        )}       
      </div>
    </>
  );
}

export default SelectedTypeStories;
