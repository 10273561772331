import React, { PureComponent, useState,useEffect } from "react";
import { CommentSection } from "react-comments-section";
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Input,
  Empty,
  Radio,
  List,
  Avatar,
  Image,
  Tooltip,
  Typography,
} from "antd";
import "react-comments-section/dist/index.css";
import {
  useCreateChatMutation,  
  useGetChatDataQuery,
  useReplyChatMutation 
} from "../../Services/ChattingAPI";

// import downloadImage from "downloadjs";


import moment from "moment";
const { Text } = Typography;
const ChatComponent = ({ storyId }) => {
  const [documentFlag, setDocumentFlag] = useState(false);
  const [sendMessageAPI] = useCreateChatMutation();
  const [replyMessageAPI] = useReplyChatMutation();

 
  const {
    data: datas,
    isLoading: ischatloading,
    isSuccess: datafetched,
  } = useGetChatDataQuery({storyId });

  
  const storyData = JSON.parse(localStorage.getItem('myDataArray'))

  const saveCommentsData = datas?.data.map((com)=>{
    const obj = {}
    console.log('replies',com?.replies)
    let replyData = []
    if(com.replies.length > 0){

     
      replyData = com.replies?.map((val)=>{
        const replyObj = {}
        replyObj['userId'] = val.reply_login_user_details?.login_user_id
        replyObj['fullName'] = val?.reply_login_user_details?.first_name
        replyObj['comId'] = val?.reply_id
        replyObj['text'] = val?.reply_desrciption
        replyObj['userProfile'] ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAMAAABC4vDmAAAAZlBMVEX///8AAADy8vL8/Pzs7OwqKipHR0f19fXm5uYjIyPY2NgEBASgoKBUVFTR0dGkpKRlZWXf3992dnasrKwVFRV9fX21tbVqamqampqPj4+FhYVfX19aWlpAQEC7u7vLy8sxMTE5OTmhVC0gAAAEqklEQVR4nO2a2bqqMAxGgTKIyCAKgrPv/5InDRuFDorHFr3I2vvCr1T5adI0DXUcgiAIgiAIgiAIgiAIgiAIgsPwHz9FHhKx7yq6s8jy1S4MkHC3yrPFlwVFyyaMt+6IbRw2y+hrkhZ56mpI8++M17qK/xTE12NRlFVVFsXxem+s1rNL8sqku3nQbpaD26+XdRt0V5LSm1VStOoG5NRmivnGsvbUjdZqLt8CFeuik1Rrh8Krb9ilmM2GG7xfeGYO08QlHsHOIXbbzBO69uAurpv7cGOdKMTPseN+Bkk+hoHCn9QXzZxO6vsJLORPX0kOnF3O50smtkYVH6zQsgV91HQWWi+7PmjuLsKlM7qfxbGCB+a2SwaauFf5d0koC31toIrHs9SeKIftuaahkeD2tbjE1PfkAcm4qr09C2IsGNkONSXJQxH/XI+/hRbc2NK0ljR1d0yG45S4alWWomh0gDtW4zY/UOUIgeDYJXzxYGfFgVgo/XSl0uSK0uFxINra0OTzNdgf+bCzGDvUw61G2RRMUGiObcSFUvG0jXqgXLcROuby8JlgnSiCoD7zFDr6oRVfL+WZ56xjnahAzIbP4OulaU0LuP9JjIBLrah4KXRlkPbFpvN27hS12KgfqVgyVa1wyQ+JwHtOUp75jigPhio1G6uWcKNWbp1uPsdpoVlu/YQG/FT+Re+mE3WTs/cl/IQYKT4DZvRVsdAfdKIOcl8GK1JoUhOfe7L1/pZaFWLw4LQqV/uAbKuYew56r7TK8ERBnhMOzr+tlDB/wArCofIhN+qBUmZPa7DfyqyoqzLyMeVCkyrzzMXVqCgGWfhRfck/uaMsj388afKBI2Tw5tLiCCZfobm2voqi1IYGYBcYmgufXqATxXj9ZWw7XmtRjweICswVYrgo/RLfVw0QXmHQURoX9TRFW7aHEDi0T9eRal5R4HY+8MJhzIvSmC/KqrHB2LnKNOLMm0/p6KzmcarI77JYzgstaa10LLOOrgwJzInqoA9St3LfNPvy1oeqoFaMltmQoA6evLCRwH5qvMnCBmjZyWNiNngql5mLYikeqROrQoaXGdWCvNk+1wQpgbAs8wXZZJYupy65am8smFGQYDp1GSZ5WBFbvRqmzuHRWr0btaY3WTD9gmF5boImZFj0M50OY9HgsYTALuaFl/djNdjV8A2R2Y3DaIvFQneqqEFl2PwWa7gZZdpai4rmT5WFzehw267fgqroDWhh2z4scJQvhYzoVnIrBY5HKch7T5ProtWtlIIeRbN2mpP3JDhBLBXNcKhg4fDSd0WlXrc/tFBe7Aqxi5frsELWhRds7RRiYQImbsGmLDACK1bAV62UrLtqeBO+1CAR8sBmqbiPr0G2b1sP7MezHGsvkzXljClYe2GEr9b+Z6SsvlrDav77sw9r/RZV+VMThJEmm69rOTxteUcW72r7xXZ/BOAd7B8BcLrDEs83DfdhSmY6LAEW3Ey1IO8007ESSK6KqaYrZjzbhUeVEvzTDBFeivN5j8F5VfLMiHixmvdQF+dx/E3JN46/cX7woCDnB49Udvzc4dOenzumSxAEQRAEQRAEQRAEQRAE8Tv8A3iFMOg4ApuFAAAAAElFTkSuQmCC" 
        replyObj['avatarUrl']= "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAMAAABC4vDmAAAAZlBMVEX///8AAADy8vL8/Pzs7OwqKipHR0f19fXm5uYjIyPY2NgEBASgoKBUVFTR0dGkpKRlZWXf3992dnasrKwVFRV9fX21tbVqamqampqPj4+FhYVfX19aWlpAQEC7u7vLy8sxMTE5OTmhVC0gAAAEqklEQVR4nO2a2bqqMAxGgTKIyCAKgrPv/5InDRuFDorHFr3I2vvCr1T5adI0DXUcgiAIgiAIgiAIgiAIgiAIgsPwHz9FHhKx7yq6s8jy1S4MkHC3yrPFlwVFyyaMt+6IbRw2y+hrkhZ56mpI8++M17qK/xTE12NRlFVVFsXxem+s1rNL8sqku3nQbpaD26+XdRt0V5LSm1VStOoG5NRmivnGsvbUjdZqLt8CFeuik1Rrh8Krb9ilmM2GG7xfeGYO08QlHsHOIXbbzBO69uAurpv7cGOdKMTPseN+Bkk+hoHCn9QXzZxO6vsJLORPX0kOnF3O50smtkYVH6zQsgV91HQWWi+7PmjuLsKlM7qfxbGCB+a2SwaauFf5d0koC31toIrHs9SeKIftuaahkeD2tbjE1PfkAcm4qr09C2IsGNkONSXJQxH/XI+/hRbc2NK0ljR1d0yG45S4alWWomh0gDtW4zY/UOUIgeDYJXzxYGfFgVgo/XSl0uSK0uFxINra0OTzNdgf+bCzGDvUw61G2RRMUGiObcSFUvG0jXqgXLcROuby8JlgnSiCoD7zFDr6oRVfL+WZ56xjnahAzIbP4OulaU0LuP9JjIBLrah4KXRlkPbFpvN27hS12KgfqVgyVa1wyQ+JwHtOUp75jigPhio1G6uWcKNWbp1uPsdpoVlu/YQG/FT+Re+mE3WTs/cl/IQYKT4DZvRVsdAfdKIOcl8GK1JoUhOfe7L1/pZaFWLw4LQqV/uAbKuYew56r7TK8ERBnhMOzr+tlDB/wArCofIhN+qBUmZPa7DfyqyoqzLyMeVCkyrzzMXVqCgGWfhRfck/uaMsj388afKBI2Tw5tLiCCZfobm2voqi1IYGYBcYmgufXqATxXj9ZWw7XmtRjweICswVYrgo/RLfVw0QXmHQURoX9TRFW7aHEDi0T9eRal5R4HY+8MJhzIvSmC/KqrHB2LnKNOLMm0/p6KzmcarI77JYzgstaa10LLOOrgwJzInqoA9St3LfNPvy1oeqoFaMltmQoA6evLCRwH5qvMnCBmjZyWNiNngql5mLYikeqROrQoaXGdWCvNk+1wQpgbAs8wXZZJYupy65am8smFGQYDp1GSZ5WBFbvRqmzuHRWr0btaY3WTD9gmF5boImZFj0M50OY9HgsYTALuaFl/djNdjV8A2R2Y3DaIvFQneqqEFl2PwWa7gZZdpai4rmT5WFzehw267fgqroDWhh2z4scJQvhYzoVnIrBY5HKch7T5ProtWtlIIeRbN2mpP3JDhBLBXNcKhg4fDSd0WlXrc/tFBe7Aqxi5frsELWhRds7RRiYQImbsGmLDACK1bAV62UrLtqeBO+1CAR8sBmqbiPr0G2b1sP7MezHGsvkzXljClYe2GEr9b+Z6SsvlrDav77sw9r/RZV+VMThJEmm69rOTxteUcW72r7xXZ/BOAd7B8BcLrDEs83DfdhSmY6LAEW3Ey1IO8007ESSK6KqaYrZjzbhUeVEvzTDBFeivN5j8F5VfLMiHixmvdQF+dx/E3JN46/cX7woCDnB49Udvzc4dOenzumSxAEQRAEQRAEQRAEQRAE8Tv8A3iFMOg4ApuFAAAAAElFTkSuQmCC"
        return replyObj 
     })
            
   
         

    }
    obj['userId']=com?.login_user_details?.login_user_id;
    obj['comId']=com?.comment_id.toString();
    obj['fullName']=com?.login_user_details?.first_name;
    obj['text']=com?.comment_desrciption;
    obj['replies']=replyData   
    obj['userProfile'] ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAMAAABC4vDmAAAAZlBMVEX///8AAADy8vL8/Pzs7OwqKipHR0f19fXm5uYjIyPY2NgEBASgoKBUVFTR0dGkpKRlZWXf3992dnasrKwVFRV9fX21tbVqamqampqPj4+FhYVfX19aWlpAQEC7u7vLy8sxMTE5OTmhVC0gAAAEqklEQVR4nO2a2bqqMAxGgTKIyCAKgrPv/5InDRuFDorHFr3I2vvCr1T5adI0DXUcgiAIgiAIgiAIgiAIgiAIgsPwHz9FHhKx7yq6s8jy1S4MkHC3yrPFlwVFyyaMt+6IbRw2y+hrkhZ56mpI8++M17qK/xTE12NRlFVVFsXxem+s1rNL8sqku3nQbpaD26+XdRt0V5LSm1VStOoG5NRmivnGsvbUjdZqLt8CFeuik1Rrh8Krb9ilmM2GG7xfeGYO08QlHsHOIXbbzBO69uAurpv7cGOdKMTPseN+Bkk+hoHCn9QXzZxO6vsJLORPX0kOnF3O50smtkYVH6zQsgV91HQWWi+7PmjuLsKlM7qfxbGCB+a2SwaauFf5d0koC31toIrHs9SeKIftuaahkeD2tbjE1PfkAcm4qr09C2IsGNkONSXJQxH/XI+/hRbc2NK0ljR1d0yG45S4alWWomh0gDtW4zY/UOUIgeDYJXzxYGfFgVgo/XSl0uSK0uFxINra0OTzNdgf+bCzGDvUw61G2RRMUGiObcSFUvG0jXqgXLcROuby8JlgnSiCoD7zFDr6oRVfL+WZ56xjnahAzIbP4OulaU0LuP9JjIBLrah4KXRlkPbFpvN27hS12KgfqVgyVa1wyQ+JwHtOUp75jigPhio1G6uWcKNWbp1uPsdpoVlu/YQG/FT+Re+mE3WTs/cl/IQYKT4DZvRVsdAfdKIOcl8GK1JoUhOfe7L1/pZaFWLw4LQqV/uAbKuYew56r7TK8ERBnhMOzr+tlDB/wArCofIhN+qBUmZPa7DfyqyoqzLyMeVCkyrzzMXVqCgGWfhRfck/uaMsj388afKBI2Tw5tLiCCZfobm2voqi1IYGYBcYmgufXqATxXj9ZWw7XmtRjweICswVYrgo/RLfVw0QXmHQURoX9TRFW7aHEDi0T9eRal5R4HY+8MJhzIvSmC/KqrHB2LnKNOLMm0/p6KzmcarI77JYzgstaa10LLOOrgwJzInqoA9St3LfNPvy1oeqoFaMltmQoA6evLCRwH5qvMnCBmjZyWNiNngql5mLYikeqROrQoaXGdWCvNk+1wQpgbAs8wXZZJYupy65am8smFGQYDp1GSZ5WBFbvRqmzuHRWr0btaY3WTD9gmF5boImZFj0M50OY9HgsYTALuaFl/djNdjV8A2R2Y3DaIvFQneqqEFl2PwWa7gZZdpai4rmT5WFzehw267fgqroDWhh2z4scJQvhYzoVnIrBY5HKch7T5ProtWtlIIeRbN2mpP3JDhBLBXNcKhg4fDSd0WlXrc/tFBe7Aqxi5frsELWhRds7RRiYQImbsGmLDACK1bAV62UrLtqeBO+1CAR8sBmqbiPr0G2b1sP7MezHGsvkzXljClYe2GEr9b+Z6SsvlrDav77sw9r/RZV+VMThJEmm69rOTxteUcW72r7xXZ/BOAd7B8BcLrDEs83DfdhSmY6LAEW3Ey1IO8007ESSK6KqaYrZjzbhUeVEvzTDBFeivN5j8F5VfLMiHixmvdQF+dx/E3JN46/cX7woCDnB49Udvzc4dOenzumSxAEQRAEQRAEQRAEQRAE8Tv8A3iFMOg4ApuFAAAAAElFTkSuQmCC" 
     obj['avatarUrl']= "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAMAAABC4vDmAAAAZlBMVEX///8AAADy8vL8/Pzs7OwqKipHR0f19fXm5uYjIyPY2NgEBASgoKBUVFTR0dGkpKRlZWXf3992dnasrKwVFRV9fX21tbVqamqampqPj4+FhYVfX19aWlpAQEC7u7vLy8sxMTE5OTmhVC0gAAAEqklEQVR4nO2a2bqqMAxGgTKIyCAKgrPv/5InDRuFDorHFr3I2vvCr1T5adI0DXUcgiAIgiAIgiAIgiAIgiAIgsPwHz9FHhKx7yq6s8jy1S4MkHC3yrPFlwVFyyaMt+6IbRw2y+hrkhZ56mpI8++M17qK/xTE12NRlFVVFsXxem+s1rNL8sqku3nQbpaD26+XdRt0V5LSm1VStOoG5NRmivnGsvbUjdZqLt8CFeuik1Rrh8Krb9ilmM2GG7xfeGYO08QlHsHOIXbbzBO69uAurpv7cGOdKMTPseN+Bkk+hoHCn9QXzZxO6vsJLORPX0kOnF3O50smtkYVH6zQsgV91HQWWi+7PmjuLsKlM7qfxbGCB+a2SwaauFf5d0koC31toIrHs9SeKIftuaahkeD2tbjE1PfkAcm4qr09C2IsGNkONSXJQxH/XI+/hRbc2NK0ljR1d0yG45S4alWWomh0gDtW4zY/UOUIgeDYJXzxYGfFgVgo/XSl0uSK0uFxINra0OTzNdgf+bCzGDvUw61G2RRMUGiObcSFUvG0jXqgXLcROuby8JlgnSiCoD7zFDr6oRVfL+WZ56xjnahAzIbP4OulaU0LuP9JjIBLrah4KXRlkPbFpvN27hS12KgfqVgyVa1wyQ+JwHtOUp75jigPhio1G6uWcKNWbp1uPsdpoVlu/YQG/FT+Re+mE3WTs/cl/IQYKT4DZvRVsdAfdKIOcl8GK1JoUhOfe7L1/pZaFWLw4LQqV/uAbKuYew56r7TK8ERBnhMOzr+tlDB/wArCofIhN+qBUmZPa7DfyqyoqzLyMeVCkyrzzMXVqCgGWfhRfck/uaMsj388afKBI2Tw5tLiCCZfobm2voqi1IYGYBcYmgufXqATxXj9ZWw7XmtRjweICswVYrgo/RLfVw0QXmHQURoX9TRFW7aHEDi0T9eRal5R4HY+8MJhzIvSmC/KqrHB2LnKNOLMm0/p6KzmcarI77JYzgstaa10LLOOrgwJzInqoA9St3LfNPvy1oeqoFaMltmQoA6evLCRwH5qvMnCBmjZyWNiNngql5mLYikeqROrQoaXGdWCvNk+1wQpgbAs8wXZZJYupy65am8smFGQYDp1GSZ5WBFbvRqmzuHRWr0btaY3WTD9gmF5boImZFj0M50OY9HgsYTALuaFl/djNdjV8A2R2Y3DaIvFQneqqEFl2PwWa7gZZdpai4rmT5WFzehw267fgqroDWhh2z4scJQvhYzoVnIrBY5HKch7T5ProtWtlIIeRbN2mpP3JDhBLBXNcKhg4fDSd0WlXrc/tFBe7Aqxi5frsELWhRds7RRiYQImbsGmLDACK1bAV62UrLtqeBO+1CAR8sBmqbiPr0G2b1sP7MezHGsvkzXljClYe2GEr9b+Z6SsvlrDav77sw9r/RZV+VMThJEmm69rOTxteUcW72r7xXZ/BOAd7B8BcLrDEs83DfdhSmY6LAEW3Ey1IO8007ESSK6KqaYrZjzbhUeVEvzTDBFeivN5j8F5VfLMiHixmvdQF+dx/E3JN46/cX7woCDnB49Udvzc4dOenzumSxAEQRAEQRAEQRAEQRAE8Tv8A3iFMOg4ApuFAAAAAElFTkSuQmCC"
    return obj
  })
  console.log('storyData',saveCommentsData)
  const data = [
    {
      userId: "01a",
      comId: "012",
      fullName: "Riya Negi ",
      avatarUrl: "http://localhost:8081/employee/image/volun_aadhar.jpg",
      userProfile: "https://www.linkedin.com/in/riya-negi-8879631a9/",
      text: "Hey, Loved your blog! ",
      images: [
        "http://localhost:8081/employee/image/volun_profile.jpg",
        "http://localhost:8081/employee/image/volun_profile.jpg",
      ],
      replies: [
        {
          userId: "02a",
          comId: "013",
          userProfile: "https://www.linkedin.com/in/riya-negi-8879631a9/",
          fullName: "Adam Scott",
          avatarUrl: "https://ui-avatars.com/api/name=Adam&background=random",

          text: "Thanks! It took me 1 month to finish this project but I am glad it helped out someone!🥰",
        },
        {
          userId: "01a",
          comId: "014",
          userProfile: "https://www.linkedin.com/in/riya-negi-8879631a9/",
          fullName: "Riya Negi",
          avatarUrl: "https://ui-avatars.com/api/name=Riya&background=random",
          text: "thanks!😊",
        },
      ],
    },
    {
      userId: "02b",
      comId: "017",
      fullName: "Lily",
      userProfile: "https://www.linkedin.com/in/riya-negi-8879631a9/",
      text: "I have a doubt about the 4th point🤔",
      avatarUrl: "https://ui-avatars.com/api/name=Lily&background=random",
      replies: [],
    },
  ];

  const [commentsData, setCommentsData] = useState(
    datas?.data && JSON.parse(JSON.stringify(datas?.data))
  );
  // let mapData = [];
  // useEffect(() => {
  //   const data = datas?.data && JSON.parse(JSON.stringify(datas?.data));

  //   mapData = data?.map((d) => {
  //     if (d) {
  //       d.fullName = `${d?.fullName} ${d.commentedDateTime}`;
  //     }
  //     return d;
  //     console.log({mapData})
  //   });
  // });
  
  const [radioValue, setRadioValue] = useState("comments");
  const loginUserName = localStorage.getItem("loginUsername");
  
  const loginUserId = localStorage.getItem("loginuserId");
  const submitterType =
    localStorage.getItem("isEmployee") === "true" ? "Staff" : "Volunteer";
  const avatarUrl = localStorage.getItem("avatarUrl");
  useEffect(() => {
    console.log(commentsData);
  }, [commentsData]);

  const handleDataFromChild = (data) => {
    setDocumentFlag(data);
  };

  const handleImageDownload = (img) => {
    {
    //   downloadImage(img);
    }
  };

  const onSubmitAction = async (data) => {
    // console.log("this comment was posted!,data", commentsData,data.text);
    console.log("data.text", data.text);

    const payload = {
      comId: 0,
      

      story_id: storyId,

      comment_desrciption: data?.text,
      login_user_id: 14,
    //   userId: loginUserId,
    };
    try {
      await sendMessageAPI(payload)
        .unwrap()
        .then((payload) => {
          console.log("payload", payload);
          if (payload.is_success === true) {
            console.log({payload})
          
          } else {
          
          }
        })
        .catch((error) => {
         
        });
    } catch (err) {
      console.error("Failed to save the post: ", err);
    }
  };

  

  const onSubmitReplayAction = async (data) => {
   
    // console.log({ commentsData });
    const payload = {     
      comment_id: +data?.repliedToCommentId,
      reply_id: 0,
      reply_desrciption: data?.text,
      login_user_id: 14,
    };

    console.log( payload );
    try {
      await replyMessageAPI(payload)
        .unwrap()
        .then((payload) => {
          console.log("payload", payload);
          if (payload.is_success === true) {
        
          } else {
                     }
        })
        .catch((error) => {
         
        });
    } catch (err) {
      console.error("Failed to save the post: ", err);
    }
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };
 
  const customNoComment = () => (
    <div className="no-com">No comments wohoooo!</div>
  );

  function timeAgoInIST(targetTime) {
    const savedTime = moment(targetTime);

        
    const currentTime = moment(new Date());
    console.log("savedTime",savedTime);
    console.log("currentTime",currentTime);
    const timeDifference = currentTime - savedTime; // in milliseconds
  
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(weeks / 4.34812); // Average days in a month
    const years = Math.floor(months / 12);
  
    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return 'Just now';
    }
  }
  return (
    <>
    
        <div style={{marginTop:'20px'}}>
         
        
        
            <CommentSection
              currentUser={{
                currentUserId: loginUserId,
                currentUserImg:
                  avatarUrl ??
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAMAAABC4vDmAAAAZlBMVEX///8AAADy8vL8/Pzs7OwqKipHR0f19fXm5uYjIyPY2NgEBASgoKBUVFTR0dGkpKRlZWXf3992dnasrKwVFRV9fX21tbVqamqampqPj4+FhYVfX19aWlpAQEC7u7vLy8sxMTE5OTmhVC0gAAAEqklEQVR4nO2a2bqqMAxGgTKIyCAKgrPv/5InDRuFDorHFr3I2vvCr1T5adI0DXUcgiAIgiAIgiAIgiAIgiAIgsPwHz9FHhKx7yq6s8jy1S4MkHC3yrPFlwVFyyaMt+6IbRw2y+hrkhZ56mpI8++M17qK/xTE12NRlFVVFsXxem+s1rNL8sqku3nQbpaD26+XdRt0V5LSm1VStOoG5NRmivnGsvbUjdZqLt8CFeuik1Rrh8Krb9ilmM2GG7xfeGYO08QlHsHOIXbbzBO69uAurpv7cGOdKMTPseN+Bkk+hoHCn9QXzZxO6vsJLORPX0kOnF3O50smtkYVH6zQsgV91HQWWi+7PmjuLsKlM7qfxbGCB+a2SwaauFf5d0koC31toIrHs9SeKIftuaahkeD2tbjE1PfkAcm4qr09C2IsGNkONSXJQxH/XI+/hRbc2NK0ljR1d0yG45S4alWWomh0gDtW4zY/UOUIgeDYJXzxYGfFgVgo/XSl0uSK0uFxINra0OTzNdgf+bCzGDvUw61G2RRMUGiObcSFUvG0jXqgXLcROuby8JlgnSiCoD7zFDr6oRVfL+WZ56xjnahAzIbP4OulaU0LuP9JjIBLrah4KXRlkPbFpvN27hS12KgfqVgyVa1wyQ+JwHtOUp75jigPhio1G6uWcKNWbp1uPsdpoVlu/YQG/FT+Re+mE3WTs/cl/IQYKT4DZvRVsdAfdKIOcl8GK1JoUhOfe7L1/pZaFWLw4LQqV/uAbKuYew56r7TK8ERBnhMOzr+tlDB/wArCofIhN+qBUmZPa7DfyqyoqzLyMeVCkyrzzMXVqCgGWfhRfck/uaMsj388afKBI2Tw5tLiCCZfobm2voqi1IYGYBcYmgufXqATxXj9ZWw7XmtRjweICswVYrgo/RLfVw0QXmHQURoX9TRFW7aHEDi0T9eRal5R4HY+8MJhzIvSmC/KqrHB2LnKNOLMm0/p6KzmcarI77JYzgstaa10LLOOrgwJzInqoA9St3LfNPvy1oeqoFaMltmQoA6evLCRwH5qvMnCBmjZyWNiNngql5mLYikeqROrQoaXGdWCvNk+1wQpgbAs8wXZZJYupy65am8smFGQYDp1GSZ5WBFbvRqmzuHRWr0btaY3WTD9gmF5boImZFj0M50OY9HgsYTALuaFl/djNdjV8A2R2Y3DaIvFQneqqEFl2PwWa7gZZdpai4rmT5WFzehw267fgqroDWhh2z4scJQvhYzoVnIrBY5HKch7T5ProtWtlIIeRbN2mpP3JDhBLBXNcKhg4fDSd0WlXrc/tFBe7Aqxi5frsELWhRds7RRiYQImbsGmLDACK1bAV62UrLtqeBO+1CAR8sBmqbiPr0G2b1sP7MezHGsvkzXljClYe2GEr9b+Z6SsvlrDav77sw9r/RZV+VMThJEmm69rOTxteUcW72r7xXZ/BOAd7B8BcLrDEs83DfdhSmY6LAEW3Ey1IO8007ESSK6KqaYrZjzbhUeVEvzTDBFeivN5j8F5VfLMiHixmvdQF+dx/E3JN46/cX7woCDnB49Udvzc4dOenzumSxAEQRAEQRAEQRAEQRAE8Tv8A3iFMOg4ApuFAAAAAElFTkSuQmCC",

                currentUserFullName: `${loginUserName}`,
              }}
            //   commentData={
            //     datas?.data ? JSON.parse(JSON.stringify(datas?.data)) : []
            //   }
               commentData={saveCommentsData}
              onSubmitAction={(data) => onSubmitAction(data)}
              currentData={(data) => {
                console.log("curent data", data);
              }}
              onReplyAction={(value) => onSubmitReplayAction(value)}
              customNoComment={() => customNoComment()}
             
            />
         
        </div>
     
     
    </>
  );
};
export default ChatComponent;
