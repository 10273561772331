import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

function InfoForm() {
    // State variables for form fields
    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');

    // State variables for form validation
    const [nameError, setNameError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        // Simple validation
        if (!name) {
            setNameError('Name is required');
            return;
        } else {
            setNameError('');
        }

        if (!mobileNumber) {
            setMobileNumberError('Mobile number is required');
            return;
        } else if (!/^\d{10}$/.test(mobileNumber)) {
            setMobileNumberError('Mobile number should be exactly 10 digits');
            return;
        } else {
            setMobileNumberError('');
        }

        // Email.js integration
        const templateParams = {
            name,
            mobileNumber,
            email,
            location,
            description,
        };

        emailjs
            .sendForm('service_1r5z8nq', 'template_k8m3l9v', form.current, 'YYJ3fUS_8s8GVsBzV')
            .then(
                (result) => {
                    console.log('result @@', result.text);
                    alert('Form submitted successfully!');
                    // Optional: Reset form fields after successful submission
                    setName('');
                    setMobileNumber('');
                    setEmail('');
                    setLocation('');
                    setDescription('');
                },
                (error) => {
                    console.error('Error sending email:', error);
                    alert('Form submission failed. Please try again.');
                }
            )
            .catch((error) => {
                console.error('Unhandled promise rejection:', error);
                alert('Form submission failed. Please try again.');
            });
    };

    return (
        <>
            <div className="info_form">
                <h2>Leave us your info</h2>
                <p>and we will get back to you.</p>
                <form id="contact-form" className="contact-form" onSubmit={sendEmail} ref={form}>
                    <fieldset>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Name <span className="mandatory"> * </span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="user_name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {nameError && <span style={{ color: 'red' }}>{nameError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Mobile <span className="mandatory"> * </span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="user_mobile"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                    />
                                    {mobileNumberError && <span style={{ color: 'red' }}>{mobileNumberError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>E-mail</label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="user_email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Interesed Location (if any)
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="user_location"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    />

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea
                                        className="textarea form-control"
                                        name="user_description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group mb-none pull-right">
                                    <button type="submit" className="btn-ftg-ptp-56 disabled">
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-6 col-sm-12">
                                <div className="form-response"></div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    );
}

export default InfoForm;
