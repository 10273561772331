import React, { Suspense, lazy } from "react";
import "antd/dist/antd.css";
// import TabsContainer from "../sharedComponents/TabsContainer";

import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import Trending from "../Trending";
import BreakingNewsMain from "../Sections/Breaking News/BreakingNewsMain";
import PoliticsSectionDB from "../Sections/PoliticsSection/PoliticsSectionDB";
import TranslateWord from "../sharedComponents/TranslateWord";
import { useSelector } from "react-redux";
import EntertainmentSectionDB from "../Sections/EntertainmentSection/EntertainmentSectionDB";
import SportsSectionDB from "../Sections/SportsSection/SportsSectionDB";
import BusinessSectionDB from "../Sections/BusinessSection/BusinessSectionDB";
import TechnologySectionDB from "../Sections/TechnologySection/TechnologySectionDB";
import AdvtHereImg from '../../imgaes/AdvtHereImg.png'
const LazyTabsContainer = lazy(() =>
  import("../sharedComponents/TabsContainer")
);
 const LazyTrending =  lazy(() => import('../Trending'));
const CategoriesSectionDB = () => {
  const {data: interfaceLanguagesData,selectedInterfaceLanguage} =useSelector(state => state.interfaceLanguageList );
  const {   
    politicsSelectedLang
  } = useSelector((state) => state.politicsList);
  const {   
    entertainmentSelectedLang
  } = useSelector((state) => state.entertainmentList);
  const {   
    sportsSelectedLang
  } = useSelector((state) => state.sportsList);
  const {   
    businessSelectedLang
  } = useSelector((state) => state.businessList);
  const {   
    technologySelectedLang
  } = useSelector((state) => state.technologyList);
  return (
    <>
      <section>
        {/* <Suspense
          fallback={
            <div>
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 2, width: [1000, 1000] }}
              />
            </div>
          }
        >         
          <LazyTrending />
        </Suspense> */}
        <Trending/>
        <BreakingNewsMain/>
      </section>
      <section className="bg-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <PoliticsSectionDB sectionType= {<TranslateWord key={'POLITICS'} text={"POLITICS"}  headingStyle={true} lang={politicsSelectedLang}/>}/>
              <hr></hr>
              <EntertainmentSectionDB sectionType= {<TranslateWord key={'ENTERTAINMENT'} text={"ENTERTAINMENT"}  headingStyle={true} lang={entertainmentSelectedLang}/>}/>
              <hr></hr>
              <SportsSectionDB sectionType= {<TranslateWord key={'SPORTS'} text={"SPORTS"}  headingStyle={true} lang={sportsSelectedLang}/>}/>
              <hr></hr>
              <BusinessSectionDB sectionType= {<TranslateWord key={'BUSINESS'} text={"BUSINESS"}  headingStyle={true} lang={businessSelectedLang}/>}/>
              <hr></hr>
              <TechnologySectionDB sectionType= {<TranslateWord key={'TECHNOLOGY'} text={"TECHNOLOGY"}  headingStyle={true} lang={technologySelectedLang}/>}/>
              {/* {[
                "Politics",
                "Business",
                "Sports",
                "Entertainment",
                "Technology",
              ]?.map((section) => {
                return (
                  <>                   
                    <Suspense
                      fallback={
                        <div>
                          <Skeleton
                            active
                            title={false}
                            paragraph={{ rows: 2, width: [1500, 1500] }}
                          />
                        </div>
                      }
                    >
                      <LazyTabsContainer sectionType={section} />
                    </Suspense>

                    <hr></hr>
                  </>
                );
              })
              } */}
            </div>
            <div className="ne-sidebar sidebar-break-md col-lg-3 col-md-12">
              <div className="sidebar-box">
                <ins class="adsbygoogle"
     style={{display:'block'}}
     data-ad-format="fluid"
     data-ad-layout-key="-fl+5z+3v-d3+9d"
     data-ad-client="ca-pub-9456780621476296"
     data-ad-slot="9924505440"></ins>
              </div>
              <div className="latest_polls">
                <div className="sidebar-box">
                  <div className="ne-banner-layout1 text-center">
                    {/* <a>
                      <img
                        src={AdvtHereImg}
                        alt="ad"
                        className="img-fluid"
                      />
                    </a> */}
                  </div>
                </div>
                <div
                      className="ne-banner-layout1 mt-20-r text-center"
                      style={{ marginTop: "1px" }}
                    >
                      {/* <a>
                        <img
                          src="img/imw_img/adv3.png"
                          alt="ad"
                          className="img-fluid"
                        />
                      </a> */}
                    </div>
                <div className="sidebar-box">
                  <div className="ne-banner-layout1 text-center">
                    {/* <a>
                      <img
                        src="img/imw_img/adv3.png"
                        alt="ad"
                        className="img-fluid"
                      />
                    </a> */}
                  </div>
                </div>
                <div className="sidebar-box">
                  <div className="ne-banner-layout1 text-center">
                    {/* <a>
                      <img
                        src="img/imw_img/adv3.png"
                        alt="ad"
                        className="img-fluid"
                      />
                    </a> */}
                  </div>
                </div>
                <div
                      className="ne-banner-layout1 mt-20-r text-center"
                      style={{ marginTop: "1px" }}
                    >
                      {/* <a>
                        <img
                          src="img/imw_img/adv3.png"
                          alt="ad"
                          className="img-fluid"
                        />
                      </a> */}
                    </div>
                <div className="sidebar-box">
                  <div className="ne-banner-layout1 text-center">
                    {/* <a>
                      <img
                        src="img/imw_img/adv3.png"
                        alt="ad"
                        className="img-fluid"
                      />
                    </a> */}
                  </div>
                </div>
                <div
                      className="ne-banner-layout1 mt-20-r text-center"
                      style={{ marginTop: "1px" }}
                    >
                      {/* <a>
                        <img
                          src="img/imw_img/adv3.png"
                          alt="ad"
                          className="img-fluid"
                        />
                      </a> */}
                    </div>
                <div className="sidebar-box">
                  <div className="row">
                    <div className="ne-banner-layout1 mt-20-r text-center">
                      {/* <a>
                        <img
                          src="img/imw_img/adv4.png"
                          alt="ad"
                          className="img-fluid"
                        />
                      </a> */}
                    </div>
                    <div
                      className="ne-banner-layout1 mt-20-r text-center"
                      style={{ marginTop: "1px" }}
                    >
                      {/* <a>
                        <img
                          src="img/imw_img/adv3.png"
                          alt="ad"
                          className="img-fluid"
                        />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoriesSectionDB;
