import React, { useEffect, useState } from "react";
import { Tabs, Select } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
import { getAllBusinessData, selectedBusinessInterfaceAction } from "../../../Store/BusinessSlice";
import BusinessSection from "./BusinessSection";

const BusinessSectionDB = ({ sectionType }) => {
  console.log({sectionType})
  const dispatch = useDispatch();
  const { data: allCategoriesList } = useSelector(
    (state) => state.categoriesList
  );
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } =
    useSelector((state) => state.interfaceLanguageList);
  const { data: feedLanguageData } = useSelector(
    (state) => state.feedLanguageList
  );
  const {

    politicsSelectedLang,
  } = useSelector((state) => state.politicsList);
  const {
    data: allBusinessData,   
    businessSelectedLang,
  } = useSelector((state) => state.businessList);  
  let filterCategory =
    businessSelectedLang?.name === "తెలుగు" ? "వాణిజ్యం" : "Business";
  const ALLWORD =
    businessSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);

  
  const { Option } = Select;
  useEffect(() => {
    setActiveKey(ALLWORD)
    const selectedCategoriesList = allCategoriesList?.find(
      (item) => item.category_name === filterCategory
    );
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length)
      setAllSubCategoriesList([ALLWORD, ...subCategories]);  
  }, [allCategoriesList,filterCategory]);
 
  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
  };

  const handleChange = (value) => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);
    dispatch(selectedBusinessInterfaceAction(selectedIngerfaceLang?.[0]));
    // window.scrollTo(window.scrollX, window.scrollY);
    // const payload = {
    //   category_name: 'Business',
    //   news_feed_lang_ids:feedLangPayload,
    //   page: 0,
    //   pageSize: 9      
    // };  
    //   if(payload?.news_feed_lang_ids?.length)   dispatch(getAllBusinessData(payload));
  };
  console.log('ALLWORD',ALLWORD)
  console.log('ALLWORD',activeKey)
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "60vw",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <div
          className="heading headig_technology politics_heading"
          style={{ width: "15vw" }}
        >
          <h1>
            <Link to={`/${filterCategory}`}>{sectionType}</Link>
          </h1>
        </div>
        {/* <div style={{ position: "relative", left: "22vw" }>
          <Select
            style={{ width: "9vw" }}
            getPopupContainer={(node) => node.parentNode}
            onChange={handleChange}
            value="More"
          >
            {children}
          </Select>
        </div> */}
        <div className="more-info-link-light">
          {/* <span className="dropdown lang_selection">
            <a
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              English
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span className="dropdown-item">English</span>
              <span className="dropdown-item">Telugu</span>
              <span className="dropdown-item">Hindi</span>
            </div>
          </span> */}
          {businessSelectedLang && (
            <Select
              size="small"
              defaultValue={
                businessSelectedLang && businessSelectedLang?.interface_lang_id
              }
              style={{
                width: 95,
                position: "relative",
                top: "-1px",
              }}
              onChange={handleChange}
            >
              {interfaceLanguagesData?.map((lang) => {
                return (
                  <>
                    <Select.Option value={lang?.interface_lang_id}>
                      {lang?.name}
                    </Select.Option>
                  </>
                );
              })}
            </Select>
          )}

          <span style={{ position: "relative", left: "5px" }}>
            <Link to={`/${filterCategory}`}>
              {
                <TranslateWord
                  key={"VIEWALL"}
                  text={"VIEWALL"}
                  headingStyle={false}
                  lang={businessSelectedLang}
                />
              }
            </Link>
          </span>
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        onChange={onChange}
        moreIcon={<span style={{ display: "flex" }}><TranslateWord
        key={"MORE"}
        text={"MORE"}
        headingStyle={false}
        lang={politicsSelectedLang}
      />&#11206;</span>}
        style={{ maxHeight: "85vh" }}
        className="custom-tabs-container"
      >
        {allSubCategoriesList?.map((tabrecord, index) => {
          const tabKey = tabrecord;
          return (
            <>
              <TabPane
                tab={tabrecord}
                key={tabKey}
                className="custom-width-tabpane"
                style={{ position: "relative", top: "-10vh" }}
              >
                <BusinessSection
                  key={index + tabKey}
                  subCategoryName={activeKey}
                  categoryName={filterCategory}
                  sectionInterfaceLang={businessSelectedLang}
                />
              </TabPane>
            </>
          );
        })}
      </Tabs>
    </>
  );
};

export default BusinessSectionDB;
