



  // Dev
//const BaseUrl = "https://api-dev.politikos.cloud";
//export default BaseUrl
//export const BaseUrlForUI = "http://localhost:3000";
//export const shareStoryUrl = "https://api-dev.politikos.cloud";


// prod
 const BaseUrl = "https://api-prod.politikos.cloud";
 export default BaseUrl
 export const BaseUrlForUI = "https://www.politikos.in";
 export const shareStoryUrl = "https://www.politikos.in";


