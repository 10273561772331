import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import React, { useState } from "react";
import politikosLogo from "../../../imgaes/politikosLogo.svg";
import { useGetAllOtpMutation } from "../../../Services/OtpAPI";
import { useDispatch } from "react-redux";
import { getOTPData } from "../../../Store/LoginSlice";

function LoginDetails({ openFlag, closeLoginDetailsModel,openingOTPlsModel }) {
  const dispatch = useDispatch();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(openFlag);
  const [loginForm] = Form.useForm();
  const [loginData, setloginDataData] = React.useState({
    first_name: "",
    mobile_number: "",
  });

  const [getOTPAPI] = useGetAllOtpMutation();
  const handleOk = () => {
    setIsLoginModalOpen(false);
  };
  const handleCancel = () => {
    closeLoginDetailsModel(false);
  };
  const handleSubmitForm = async () => {
    console.log("values", loginData);  
    const payload = {
        "first_name": loginData.first_name,
        "mobile_number": loginData.mobile_number
      }
      localStorage.setItem('userDetails', JSON.stringify(payload))
      handleCancel();               
       openingOTPlsModel(true);

       dispatch(getOTPData(payload)).then((action) => {
        if (getOTPData.fulfilled.match(action)) {
          // localStorage.setItem("user_id", payload.data.user_id)                
                message.success({
                  content: "OTP sent successfully",
                  duration: 5, // Specify the duration in seconds
                  style: {
                      marginTop: '20px', // Adjust the margin if needed
                      marginBottom: '20px', // Add margin at the bottom
                  },
              });
        }
      }).catch((err) => {
        message.error({
          content: "Some thing went wrong",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
        
      })      
     
  };
  const chandleChange = (e) => {
    const { value, name } = e.target;
    setloginDataData({
      ...loginData,
      [name]: value,
    });
  };
  const handleFormSubmittion = () =>{
    
  }
  return (
    <>
      <Modal
        open={isLoginModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row style={{ marginBottom: "40px" }}>
          <Col span={12} offset={6}>
            <img src={politikosLogo} />
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={4}>
            <h2>Enter your Name & Mobile Number</h2>           
          </Col>          
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col span={14} offset={6}>
          <h3>we will send you a OTP number</h3>           
          </Col>          
        </Row>
        
        <Row style={{ marginBottom: "10px" }}>
          <Col span={16} offset={5}>
            <div>
              <Form
                form={loginForm}
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmitForm}
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Please Enter First Name" },
                    { whitespace: true, message: "Email can't be empty" },
                  ]}
                  name="first_name"
                >
                  <Input
                    style={{ height: "40px", width:'93%'}}
                    placeholder="First Name"
                    autoComplete="off"
                    name="first_name"
                    onChange={chandleChange}
                  />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: "Please Enter Mobile Number" }]}
                  name="mobile_number"
                >
                  <Input
                    style={{ height: "40px",width:'93%' }}
                    placeholder="Mobile Number"
                    name="mobile_number"
                    id="mobile_number"
                    onChange={chandleChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    // onClick={handleSubmitForm}
                    type="primary"
                    htmlType="submit"
                    danger
                    block
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default LoginDetails;
