import React, { Suspense, lazy, useEffect, useState } from "react";
import DateDisplay from "../Components/Sections/HeaderSection/DateDisplay";

import Navbar from "../Components/Sections/HeaderSection/Navbar";
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  Tooltip,
  Divider,
  Select,
  Skeleton,
  Dropdown,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import logoIcon from "../imgaes/logoIcon.svg";
import appleIcon from "../imgaes/appleIcon.svg";
import palystoreIcon from "../imgaes/palystoreIcon.svg";
import podcastIcon from "../imgaes/podcastIcon.svg";
import livetvIcon from "../imgaes/livetvIcon.svg";

import LoginModal from "../../src/Components/Sections/HeaderSection/LoginModal";
import LoginPage from "./Login/LoginPage/LoginPage";
import LoginDetails from "./Login/LoginDetails/LoginDetails";
import OTP from "./Login/OTP/OTP";
import { useAuth0 } from "@auth0/auth0-react";
import TranslateWord from "./sharedComponents/TranslateWord";
import { useDispatch, useSelector } from "react-redux";
import { selectedInterfaceAction } from "../Store/interfaceLanguageSlice";
import { use } from "i18next";
import { selectedfeedAction } from "../Store/feedLanguageSlice";
import { getAllBreakingNewsData } from "../Store/breakingNewsSlice";
import { getAllTrendingStories } from "../Store/trendingSlice";
import { selectedPoliticsInterfaceAction } from "../Store/PoliticsSlice";
import { selectedEntertainmentInterfaceAction } from "../Store/EntertainmentSlice";
import { selectedSportsInterfaceAction } from "../Store/SportsSlice";
import { selectedBusinessInterfaceAction } from "../Store/BusinessSlice";
import { selectedTechnologyInterfaceAction } from "../Store/TechnologySlice";
import { userLogout } from "../Store/LoginSlice";

// import { useGetAllTrendingMutation } from "../Services/TrendingAPI";
// import { useGetAllActiveCategoryQuery } from "../Services/CategoryAPI";

const LazyWeatherReport = lazy(() =>
  import("../Components/Sections/HeaderSection/WeatherReport")
);
const LazyLocation = lazy(() =>
  import("../Components/Sections/HeaderSection/Location")
);

let categoryData = [];

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLoginDetailsModalOpen, setIsLoginDetailsModalOpen] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [allCategoryData, setAllCategoryData] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState([]);
  const [interfaceLangsList, setInterfaceLangsList] = useState([]);
  const {
    data: interfaceLanguagesData,
    state,
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const {
    data: feedLanguageData,
    status,
    selectedfeedLanguage,
  } = useSelector((state) => state.feedLanguageList);
  const { userSelectedNewsFeedData } = useSelector(
    (state) => state.profileSettingsList
  );
  let loggedInUserData = JSON.parse(
    localStorage.getItem("LoggedInUserDetails")
  );


  useEffect(()=>{
    if(selectedInterfaceLanguage?.interface_lang_id){     
      handleChange(selectedInterfaceLanguage?.interface_lang_id) 
    }
  },[selectedInterfaceLanguage?.interface_lang_id])
  useEffect(()=>{
    if(selectedInterfaceLanguage?.interface_lang_id){     
      handleChange(selectedInterfaceLanguage?.interface_lang_id) 
    }
  },[])
  console.log("selectedInterfaceLanguage", selectedInterfaceLanguage);

  // const [saveTrendingAPI] = useGetAllTrendingMutation();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();
    const handleLogout = () =>{
      dispatch(userLogout(loggedInUserData?.user_id)).then((action) => {
        if (userLogout?.fulfilled?.match(action)) {   
          localStorage.removeItem("LoggedInUserDetails");    
          localStorage.removeItem("userDetails");  
          localStorage.removeItem("googleLoggedInuserDetails");  
          localStorage.removeItem("LanguageId");  
          localStorage.removeItem("Language");  
          navigate(`/`)

        }
      });
    }
    const items = [
      {
        key: '1',
        label: (
          <a >
             {selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"PROFILE"}
                                  text={"PROFILE"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )}
          </a>
        ),
        onClick : () =>{navigate(`/profile`)}
      },
      {
        key: '2',
        label: (
          <a >
               {selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"LOGOUT"}
                                  text={"LOGOUT"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )}
          </a>
        ),
        // disabled: true,
         onClick :handleLogout
      },
     
    ];
  // const { data: categoryDataResponse, isLoading: isLoadingCategoryAPI } =
  //   useGetAllActiveCategoryQuery();
  // if (!isLoadingCategoryAPI) {
  //   categoryData = categoryDataResponse?.data ?? [];
  //   localStorage.setItem("allCategoriesList", JSON.stringify(categoryData));
  // }

  // useEffect(() => {
  //   setAllCategoryData(categoryData);
  //   setAllCategoryData(categoryData);
  //   let feedLangs = JSON.parse(localStorage.getItem("feedLanguagesData")) ?? [];
  //   let EngId = feedLangs?.filter((cat) => {
  //     if (cat?.name == "English") {
  //       return cat;
  //     }
  //   });
  //   setSelectedInterface(EngId);
  // }, [JSON.parse(localStorage.getItem("feedLanguagesData"))]);

  const filterCategoriesBasedOnInterfaceLang = () => {};
  // const getTrendingDataFunc = async () => {
  //   const feedLangId = localStorage.getItem("LanguageId");
  //   const payload = {
  //     news_feed_lang_ids: [+feedLangId],
  //     page: 0,
  //     pageSize: 500,
  //     login_user_id: 16,
  //   };
  //   if (payload) {
  //     try {
  //       await saveTrendingAPI(payload)
  //         .unwrap()
  //         .then((payload) => {
  //           const allStoryListRes = payload?.data ?? [];
  //           localStorage.setItem(
  //             "allStoriesList",
  //             JSON.stringify(allStoryListRes)
  //           );
  //         });
  //     } catch (err) {
  //       console.log("Some thing went wrong");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (interfaceLanguagesData?.length) {
  //     const langList = [];
  //     interfaceLanguagesData?.map((lang) => {
  //       const obj = {};
  //       obj.label = lang?.name;
  //       obj.value = +`${lang?.interface_lang_id}`;
  //       langList.push(obj);
  //     });
  //     setInterfaceLangsList(langList);
  //   }
  // }, [interfaceLanguagesData]);
  console.log("langList", interfaceLangsList);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  const handleCloseLoginModel = (data) => {
    setIsLoginModalOpen(data);
  };
  const hangleLoginDetialModelPopup = (data) => {
    setIsLoginDetailsModalOpen(data);
  };
  const handleOpeningLoginDetailsModel = (data) => {
    setIsLoginDetailsModalOpen(true);
  };
  const handleOpeningOTPModel = () => {
    setIsOTPModalOpen(true);
  };
  const updateInterface = (interfaceL) => {
    setSelectedInterface(interfaceL);
  };
  // let selectedIngerfaceLang = [];
  const handleChange = (value) => {
    localStorage.setItem("LanguageId", value);
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    let feedData = []
    if(feedLanguageData?.length === 0) feedData = JSON.parse(localStorage.getItem('feedDataInLocalStroage'))
      else feedData = [...feedLanguageData]
    const userSelectedFeedLanguages = feedData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData
        ?.filter((item) => item.selected)
        ?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }

    console.log("feedLangPayload", feedLangPayload);
    const breakingNewsPayload = {
      news_feed_lang_ids: feedLangPayload,
        page: 0,
        pageSize: 50,
        login_user_id:loggedInUserData?.user_id,
    };
    const trendingpayload = {
      news_feed_lang_ids: feedLangPayload,
        page: 0,
        pageSize: 50,
        login_user_id:loggedInUserData?.user_id,
    };
    // const politicsPayload = {
    //   category_name: "Politics",
    //   news_feed_lang_ids: feedLangPayload,
    //   page: 0,
    //   pageSize: 9,

    // };
    if (feedLangPayload?.length) {
      dispatch(selectedfeedAction(userSelectedFeedLanguages));
      dispatch(selectedInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedPoliticsInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(
        selectedEntertainmentInterfaceAction(selectedIngerfaceLang?.[0])
      );
      dispatch(selectedSportsInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedBusinessInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedTechnologyInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(getAllTrendingStories(trendingpayload));
      dispatch(getAllBreakingNewsData(breakingNewsPayload));
    }

    // setSelectedInterface(selectedIngerfaceLang);
    // updateInterface(selectedIngerfaceLang);
    // getTrendingDataFunc()
    navigate("/loading");
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  const handlePlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=cloud.politikos.mob_app_prod.twa&pcampaignid=web_share', '_blank');
  };

  const handleIOSplaystore = () =>{
    window.open('https://mob-app-prod.politikos.cloud/', '_blank');
  }
  // useEffect(()=>{
  //   setSelectedInterface(selectedIngerfaceLang)
  //   localStorage.setItem("interfaceLangObj", selectedIngerfaceLang)},[localStorage.getItem("LanguageId")])
  return (
    <>
      <header>
        <div className="header_bg">
          <div id="header-layout2" className="header-style7">
            <div className="main-menu-area" id="sticker">
              <div className="header-top-bar">
                <div className="container">
                  <div className="row no-gutters d-flex align-items-center">
                    <div className="col-lg-2 col-md-2 d-none d-lg-block">
                      <div className="logo-area">
                        <Link to="/">
                          {" "}
                          <img
                            src={logoIcon}
                            alt="logo"
                            className="img-fluid"
                            style={{ position: "relative", top: "-10px" }}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-10 d-none d-lg-block position-static min-height-none">
                      <div className="row">
                        <div className="col-lg-7 col-md-12">
                          <ul className="news-info-list text-center--md">
                            <li>
                              <DateDisplay /> |
                            </li>
                            <li>
                              <Suspense fallback={"Loading..."}>
                                <LazyLocation />
                              </Suspense>
                              |
                            </li>
                            {/* <li>
                              <Suspense fallback={"Loading..."}>
                                <LazyWeatherReport />
                              </Suspense>
                            </li> */}
                          </ul>
                        </div>
                        <div className="col-lg-5">
                          <div className="downloads pull-right">
                            <span>
                              {selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"DOWNLOADAPPON"}
                                  text={"DOWNLOADAPPON"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )}
                            </span>
                            <ul className="store_app">
                              <li>
                                {/* <Tooltip
                                  title={
                                    selectedInterfaceLanguage && (
                                      <TranslateWord
                                        key={"palystoreIcon"}
                                        text={"COMINGSOON"}
                                        lang={selectedInterfaceLanguage}
                                      />
                                    )
                                  }
                                  color="gray"
                                > */}
                                  <img
                                    src={palystoreIcon}
                                    alt="playstore"
                                    style={{ cursor: "pointer" }}
                                    onClick={handlePlayStore}
                                  />
                                {/* </Tooltip> */}
                              </li>
                              <li style={{ borderRight: "1px solid #111111" }}>
                                <Tooltip
                                  title={
                                    selectedInterfaceLanguage && (
                                      <TranslateWord
                                        key={"appleIcon"}
                                        text={"COMINGSOON"}
                                        lang={selectedInterfaceLanguage}
                                      />
                                    )
                                  }
                                  color="gray"
                                >
                                   <img
                                    src={appleIcon}
                                    alt="applestore"
                                    style={{ cursor: "pointer" }}
                                    // onClick={handleIOSplaystore}
                                  />
                                  {/* <Divider type="vertical" /> */}
                                </Tooltip>
                               
                                 
                              </li>
                              <li>
                                <Tooltip
                                  title={
                                    selectedInterfaceLanguage && (
                                      <TranslateWord
                                        key={"podcastIcon"}
                                        text={"COMINGSOON"}
                                        lang={selectedInterfaceLanguage}
                                      />
                                    )
                                  }
                                  color="gray"
                                >
                                  <img
                                    src={podcastIcon}
                                    alt="podcast"
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "2px",
                                    }}
                                  />
                                </Tooltip>
                              </li>
                              <li>
                                <Tooltip
                                  title={
                                    selectedInterfaceLanguage && (
                                      <TranslateWord
                                        key={"livetvIcon"}
                                        text={"COMINGSOON"}
                                        lang={selectedInterfaceLanguage}
                                      />
                                    )
                                  }
                                  color="gray"
                                >
                                  <img
                                    src={livetvIcon}
                                    alt="Live"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </li>
                              {/* <li><button>Subscribe</button></li> */}
                            </ul>
                            {/* <Select
                              size="small"
                              defaultValue={10}
                              style={{
                                width: 95,
                                position: "relative",
                                top: "2px",
                              }}
                              onChange={handleChange}
                              options={interfaceLangsList}
                            /> */}
                            {selectedInterfaceLanguage && (
                              <Select
                                size="small"
                                disabled
                                defaultValue={
                                  selectedInterfaceLanguage?.interface_lang_id
                                }
                                style={{
                                  width: 95,
                                  position: "relative",
                                  top: "2px",
                                }}
                                onChange={handleChange}
                              >
                                {interfaceLanguagesData?.map((lang) => {
                                  return (
                                    <>
                                      <Select.Option
                                        value={lang?.interface_lang_id}
                                      >
                                        {lang?.name}
                                      </Select.Option>
                                    </>
                                  );
                                })}
                              </Select>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-10">
                          <div className="ne-main-menu">
                            <Navbar
                              key={selectedInterface?.[0]?.name}
                              selectedInterfaceLang={selectedInterface}
                              categoryDataResponse={[]}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 text-right position-static">
                          <div className="header-action-item on-mobile-fixed">
                            <ul>
                              <Tooltip
                                title={
                                  <TranslateWord
                                    text={"COMINGSOON"}
                                    lang={selectedInterfaceLanguage}
                                  />
                                }
                                color="gray"
                              >
                                <li
                                  style={{ borderRight: "1px solid #111111" }}
                                >
                                  <a>
                                    <i
                                      className="fa fa-bars"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </Tooltip>
                              <li>
                                {/* <Link to="/InterfaceLanguage" className='sign_fun'> <i className="fa fa-user" aria-hidden="true"></i> Sign in</Link> */}
                                <span
                                  className="sign_fun"                                 
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fa fa-user"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;
                                  {loggedInUserData?.user_id ? (
                                    <>
                                      <Dropdown
                                        menu={{
                                          items,
                                        }}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <Button type="text" style={{padding:'0px'}}> {JSON.parse(localStorage.getItem('userDetails'))?.first_name}</Button>
                                       
                                      </Dropdown>
                                    </>
                                  ) : (
                                    selectedInterfaceLanguage && (
                                     <span  onClick={handleLogin}><TranslateWord
                                     text={"SIGNIN"}
                                     key={"SIGNIN"}
                                     lang={selectedInterfaceLanguage}
                                   /></span>
                                      
                                    )
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoginModalOpen && (
          <LoginPage
            openFlag={isLoginModalOpen}
            closeLoginModel={handleCloseLoginModel}
            openingLoginDetailsModel={handleOpeningLoginDetailsModel}
          />
        )}
        {isLoginDetailsModalOpen && (
          <LoginDetails
            openFlag={isLoginDetailsModalOpen}
            closeLoginDetailsModel={hangleLoginDetialModelPopup}
            openingOTPlsModel={handleOpeningOTPModel}
          />
        )}
        {isOTPModalOpen && (
          <OTP
            openFlag={isOTPModalOpen}
            closeLoginModel={handleCloseLoginModel}
            sendingFrom="signIn"
          />
        )}
      </header>
    </>
  );
}

export default Header;
