
import React, { useState } from 'react';
import { Form, Button, Space, Input, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
    useGetAllValidateOtpMutation, useGetAllOtpMutation
} from '../../Services/OtpAPI.js';

function OtpGenerate() {
    const [saveCategoryAPI] = useGetAllValidateOtpMutation();
    const [ReSendOtpAPI] = useGetAllOtpMutation();

    const [otp, setOtp] = useState(['', '', '', '']);
    const navigate = useNavigate();

    const onFinish = async () => {
        const payload = {
            "user_id": localStorage.getItem("user_id"),
            "otp": otp.join('') // Combine all OTP digits into a single string
        };

        try {
            const response = await saveCategoryAPI(payload);
            if ("error" in response) {
                if (response.error.status === 500 && response.error.data.is_success === false) {
                    message.error({
                        content: "Invalid OTP, Please check again",
                        duration: 5, // Specify the duration in seconds
                        style: {
                            marginTop: '20px', // Adjust the margin if needed
                            marginBottom: '20px', // Add margin at the bottom
                        },
                    });
                }
            } else {
                const payloadData = response.data;
                if (payloadData.is_success === true && payloadData.data.is_otp_verified === true) {
                    navigate('/NewsFeedLanguage');
                }
            }

        } catch (err) {
            console.log("Something went wrong");
        }
    };

    const onChange = (index, e) => {
        const value = e.target.value;

        // Ensure that the value is numeric (0-9) and update the state accordingly
        if (/^[0-9]*$/.test(value)) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);

            // Move cursor to the next box if the current box is not the last one
            if (index < otp.length - 1 && value !== '') {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
            }
        } else if (e.key === 'Backspace' && index > 0) {
            // Move cursor to the previous box on Backspace
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
            }
        }
    };
    const handleResendOtp = async () => {
        const payload = {
            "first_name": localStorage.getItem("f_name"),
            "mobile_number": localStorage.getItem("f_num")
        }
        if (payload) {
            try {
                await ReSendOtpAPI(payload)
                    .unwrap()
                    .then((payload) => {
                        if (payload.is_success === true && payload.data.is_otp_generated === true) {
                            message.success({
                                content: "OTP resent successfully",
                                duration: 5, // Specify the duration in seconds
                                style: {
                                    marginTop: '20px', // Adjust the margin if needed
                                    marginBottom: '20px', // Add margin at the bottom
                                },
                            });
                        }
                    })

            } catch (err) {
                console.log("Some thing went wrong");
            }
        }
    };
    return (
        <section className="bg-body section-space-less30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-30">
                        <div className="login_page">
                            <a href="/" className="img-fluid">
                                <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                            </a>
                            <h1>Check your Mobile for OTP</h1>
                            <Space direction="vertical">
                                <Form
                                    name="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item>
                                        {otp.map((digit, index) => (
                                            <Input
                                                key={index}
                                                id={`otp-input-${index}`}
                                                onChange={(e) => onChange(index, e)}
                                                className='otp_data'
                                                maxLength={1} // Limit to one character
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            />
                                        ))}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className='login_mobile'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <p>Didn’t receive OTP? <Link to='' onClick={handleResendOtp}>Resend</Link></p>
                                </Form>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OtpGenerate;
