import React from 'react';
function Products() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Products</h1></div>
                                <p>Our products range from digital platforms to white-label apps and are game changers in the media industry. Our digital platforms provide a personalised experience to our viewers. The users are presented first news and other content that is relevant to their location and preferences. Further, they can also manually customise app settings to follow topics or categories of their interest. App can be downloaded on the phone, tablets, the web and TV (including Fire TV, Android TV, LG TV (AI ThinQ), Roku and others) and other suitable devices.</p>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <section class="bg-body">
                                    <div class="container menu-list-wrapper">
                                        <div class="row">
                                        <div class="row zoom-gallery menu-list">
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products1.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Content Applications</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products2.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">White Label Applications</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products3.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Digital News & Video Channels</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products4.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Podcast</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products5.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Live TV </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products6.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Mini TV</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_products7.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">OTT Channels</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
        </>
    )
}

export default Products
