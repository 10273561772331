import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoForm from '../InnerPages/InfoForm';

function PeopleCenter() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>People Center</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/people_center_banner.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>The Politikos People Center functions as a community assistance hub, dedicated to facilitating solutions for local challenges through the expertise and resources of Politikos. Additionally, it plays an instrumental role in fostering and enhancing the well-being of the surrounding community. For emerging entrepreneurs, the People Center presents an opportunity to cultivate and demonstrate their entrepreneurial capabilities in a supportive environment.</p>
                                <p>Each People Center is a tangible representation of Politikos on the ground, tailored to suit the specific needs and dynamics of its locality. Depending on the scope of activities and operations, the size and infrastructure of each centre may vary. </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>Join PolitikosPeople Center as a franchisee:</h2>
                                <div class="innerpage_list people_center">
                                    <ul>
                                        <li><strong>Be the Favourite : </strong>Join the PolitikosPeople Center Franchise and make a positive impact on your community.</li>
                                        <li><strong>Bridge the Gap : </strong>Connect citizens with government services and empower them to voice their concerns and needs.</li>
                                        <li><strong>A Rewarding Opportunity : </strong>Earn while serving the people and contribute to the development of your village/community. Reach a wider section of people and multiply your earning opportunities through our Politikos Media platforms.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>For More Information & to join the Politikos People Center Franchise contact:</h2>
                                <video width="640" height="360" controls>
                                    <source src='../img/imw_img/PPC_Intro_002.mp4' type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <p className='video_title'>Intro Video</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <video width="640" height="360" controls>
                                    <source src='../img/imw_img/PPC_Explainer_004.mp4' type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <p className='video_title'>Explainer Video</p>
                            </div>
                        </div>
                    </div> */}
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
        </>
    )
}

export default PeopleCenter
