import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import noImg from '../imgaes/noImg.svg'
import noImgJPG from '../imgaes/noImgJPG.jpg'
import videoPlayIcon from '../imgaes/videoPlayIcon.svg'
import TranslateWord from './sharedComponents/TranslateWord';
import { teluguFontStyleHeading } from '../Translation/Words';
import { useSelector } from 'react-redux';


// let feedLangData = [];
// let selectedIngerface = [];
const Trending = () => {
  const {data:trendingData,status:trendingLoading} = useSelector(state =>state.trendingList); 
  const {
    data: interfaceLanguagesData,   
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const navigate = useNavigate();
  const options = {
    items: 1,
    // loop: true,
     loop: trendingData?.data?.length > 4 ? true :false,
    autoplay: true,
    autoplayTimeout: 4000,
    animatout: 'slidOutUp',
    nav: true,
    dots: false,
    margin: 0,
    responsive: {
      1100: {
        items: 4,
      },
      724: {
        items: 1,
      },
      500: {
        items: 1,
      },
      370: {
        items: 1,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    }
  }

  if(trendingLoading === 'loading'){
       return <div> <Skeleton
       active
       title={false}
       paragraph={{ rows: 2, width: [1000, 1000] }}
     /></div>
  }
//   feedLangData =
//   JSON.parse(localStorage.getItem("feedLanguagesData")) ?? [];
// selectedIngerface = feedLangData?.filter((cat) => {
//   if (cat?.newsfeed_lang_id == JSON.parse(localStorage.getItem("LanguageId"))) {
//     return cat;
//   }
// });
  const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40px',
    height: '40px',
  }; 

  const navigateToStoryDetails = (record) =>{
    const url = `/about?param1=${record?.id}&category=${'Trending'}`
    navigate(`${url}`)
  }

 
console.log('trendingData',trendingData)
  return (
    <>
     {trendingData?.data?.length > 0 && <>
      <div className="section-space-bottom trending_section">
        <div className="container" style={{marginTop:'10px'}}>
          <div className="heading headig_blue">
            <h1 style={selectedInterfaceLanguage?.name === 'తెలుగు' ? teluguFontStyleHeading :{}}><TranslateWord key={'trending'} text={"TRENDING"} headingStyle={true} lang={selectedInterfaceLanguage}/></h1>
          </div>
         
            <div className="row">

              <OwlCarousel className='owl-theme' {...options}>
                {trendingData?.data?.map((record) => {            

                  const originalDate = new Date(record?.pubDate);
                  const currentDate = new Date();
                  const timeDifference = currentDate - originalDate;
                  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                  let displayTime;
                  if (timeDifference < 24 * 60 * 60 * 1000) {
                    // Less than 24 hours
                    displayTime =
                      hours > 0
                        ? `${hours} hrs ago`
                        : minutes > 0
                          ? `${minutes} min ago`
                          : 'Less than a minute ago';
                  } else {
                    // More than 24 hours
                    displayTime = originalDate.toLocaleDateString('en-GB'); // Format the date as DD-MM-YYYY
                  }

                  return (
                    <>
                      <div className="item">
                        <div className="hover-show-play-btn item-shadow-gray trending_box">
                          <div
                            className="img-overlay-70 trending_img"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigateToStoryDetails(record)}
                          >
                            {record?.image_urls?.[0] ? (
                              <img
                                src={record?.image_urls?.[0]}
                                alt="news"
                                className="img-fluid width-100"
                              />
                            ) : record?.video_url ? (
                              <>
                                <span>
                                  <img style={imageStyle} src={videoPlayIcon} />
                                </span>{" "}
                                <video
                                  style={{
                                    width: "72px",
                                    height: "67px",
                                    backgroundColor: "black",
                                  }}
                                  muted
                                  preload="metadata"
                                  src={`${record?.video_url}#t=0.1`}
                                  onClick={() => navigateToStoryDetails(record)}
                                ></video>
                              </>
                            ) : (
                              <>
                                <img src={noImgJPG} alt="No-Image" />
                              </>
                            )}
                          </div>
                          {/* <p><Link to={`/GotoSource?param1=${record?.user_id}`}>{record?.author} </Link></p> */}
                          <div className="trending_text">
                            <h3 className="title-medium-dark">
                              <Link
                                to={`/about?param1=${
                                  record?.id
                                }&category=${"Trending"}`}
                              >
                                {" "}
                                {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                {/* <span dangerouslySetInnerHTML={{ __html: record?.title }} />   */}
                              </Link>                         

                              <p>{displayTime}</p>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

              </OwlCarousel>

            </div>
          
          <hr></hr>
        </div>

      </div>
      </>}
    </>
  )
}

export default Trending
