import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = { 
  data:{},  
  status: "idle",
  error:''  
};

const StoryDetailsSlice = createSlice({
  name: "storyDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStoryDetailsPage.pending, (state,action) => {       
          state.status = "loading";
      });
    builder.addCase(getStoryDetailsPage.fulfilled, (state, action) => {  
      state.data=action?.payload;    
      state.status = "idle";

    });
  },
});

export const getStoryDetailsPage = createAsyncThunk("storyDetailsPage/get", async (payload) => {   
        const data = await fetch(`${BaseUrl}/story/storyid/${payload}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await data.json();
        return result;      
});

export default StoryDetailsSlice.reducer;
