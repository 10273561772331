import React, { useState } from "react";
import { Popover, Modal, Button, message, Row, Col, Alert, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {shareStoryUrl} from '../../../utils/config';
import logoIcon from '../../../imgaes/logoIcon.svg'
import {
  faBookmark,
  faShareNodes,
  faBan,
  faFlag,
  faEllipsisV,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  useAddBookmarkMutation,
  useGetAllTrendingMutation,
  useRemoveBookmarkMutation,
} from "../../../Services/TrendingAPI";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
  LinkedinIcon,
  PinterestIcon,
  XIcon,
  TumblrIcon,
  FacebookIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  WhatsappShareButton
} from "react-share";
import { useDispatch } from "react-redux";
import { addBookmarkStory, removeBookmarkStory } from "../../../Store/BookmarkedStorySlice";

const MorEellipsisIcon = ({ story, sendLatestData, page, bookMarkStoryId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addBookmarkAPI] = useAddBookmarkMutation();
  const [removeBookmarkAPI] = useRemoveBookmarkMutation();
  const [getAllStoriesAPI] = useGetAllTrendingMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const sharingUrl = `${shareStoryUrl}/about?param1=${story?.id}`
  const dispatch = useDispatch();
  let loggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserDetails'))
  console.log("page", page);
  let iconsList = [
    { icon: faBookmark, icon_name: "bookMark",tooltip:'Bookmark' },
    { icon: faShareNodes, icon_name: "share" ,tooltip:'Share'},
    { icon: faSquareArrowUpRight, icon_name: "gotoSource" ,tooltip:'Coming soon'},
    { icon: faBan, icon_name: "block",tooltip:'Coming soon' },
    { icon: faFlag, icon_name: "flag" ,tooltip:'Coming soon'},
  ];
  iconsList =
    page === "storyDetails"
      ? iconsList.filter((item) => item.icon_name !== "share")
      : iconsList;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

 
  const addBookmarkToStory = async () => {
    if(!loggedInUserData?.user_id){
      message.warning({
        content: "Please Login",
        duration: 5, 
        style: {
            marginTop: '20px', 
            marginBottom: '20px', 
        },
    });
    return;
    }
    const payload = {
      login_user_id: loggedInUserData?.user_id,
      story_id: bookMarkStoryId ?? 0,
    };
    dispatch(addBookmarkStory(payload)).then((action) => {
      if (addBookmarkStory.fulfilled.match(action)) {
        message.success({
          content: "Bookmarked successfully",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
        sendLatestData();
      }
    });
    
  };
  const removeBookmarkToStory = async () => {
    const userId = loggedInUserData?.user_id;
    const storyId = bookMarkStoryId ?? 0;
    dispatch(removeBookmarkStory({ userId, storyId })).then((action) => {
      if (removeBookmarkStory.fulfilled.match(action)) {
        message.success({
          content: "Removed Bookmark successfully",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
        sendLatestData();
      }
    });     
  };
  const selectIcon = (icon_name) => {
    if (icon_name === "share") {
      setIsModalVisible(true);
    } else if (icon_name === "bookMark") {
      if (!story?.isbookmark) addBookmarkToStory();
      else removeBookmarkToStory();
    }
  };
  const content = (   
    <div>
      {iconsList?.map((i) => {
        return (
          <Tooltip title={i.tooltip}>
          <FontAwesomeIcon
            icon={i?.icon}
            onClick={() => {
              selectIcon(i?.icon_name);
            }}
            style={{
              fontSize: "14px",
              marginLeft: "15px",
              cursor: "pointer",
              color:
                story?.isbookmark && i?.icon_name === "bookMark"
                  ? "red"
                  : "black",
            }}
          />
          </Tooltip>
        );
      })}
    </div>
  );
  return (
    <>
      <Popover content={content}>
        <span className="icon_data">
          <FontAwesomeIcon
            icon={faEllipsisV}
            style={{ cursor: "pointer", position: "relative", top: "-2px" }}
          />
        </span>
      </Popover>
      <div>
        <Modal
          className="share_more_modal"
          title="Share More"
          visible={isModalVisible}
          //onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="share_mre share_more_icons">
            <Row gutter={16}>
              <Col span={12} style={{marginBottom:'10px'}}>             
                <FacebookShareButton
                  url={sharingUrl}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={25} round /> Facebook
                </FacebookShareButton>
              </Col>
              <Col span={12} style={{marginBottom:'10px'}}>
                <TwitterShareButton
                  url={sharingUrl}
                  title={"title"}
                  className="Demo__some-network__share-button"
                >
                  <XIcon size={25} round />
                  &nbsp;Twitter
                </TwitterShareButton>
              </Col>
              <Col span={12} style={{marginBottom:'10px'}}>
                <TelegramShareButton
                  url={sharingUrl}
                  title={story?.title}
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={25} round />
                  &nbsp;Telegram
                </TelegramShareButton>
              </Col>              
              <Col span={12} style={{marginBottom:'10px'}}>
             
                <LinkedinShareButton
                  url={sharingUrl}
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={25} round /> Linkedin
                </LinkedinShareButton>
              </Col>
              <Col span={12}>             
             <TumblrShareButton
               url={sharingUrl}
               className="Demo__some-network__share-button"
             >
               <TumblrIcon size={25} round /> Tumblr
             </TumblrShareButton>
           </Col>
              {/* <Col span={12} style={{marginBottom:'10px'}}>
                <FacebookMessengerShareButton
                  url={sharingUrl}
                  title={"title"}
                  className="Demo__some-network__share-button"
                >
                  <FacebookMessengerIcon size={25} round />
                  &nbsp;Messenger
                </FacebookMessengerShareButton>
              </Col>              */}
             
            </Row>

            {/* <ul>
              <li>
                <a href="https://www.linkedin.com/" target="_blank">
                  <i className="fa fa-linkedin" aria-hidden="true"></i> LinkedIn{" "}
                </a>
              </li>
              <li>
                <a href="https://twitter.com/login" target="_blank">
                  <i className="fa fa-twitter" aria-hidden="true"></i> Twitter{" "}
                </a>
              </li>
              <li>
                <a
                  title="youtube"
                  href="https://www.youtube.com/@Politikosnews"
                  target="_blank"
                >
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>{" "}
                  Youtube
                </a>
              </li>
              <li>
                <a
                  title="vimeo"
                  href="https://vimeo.com/user205451233"
                  target="_blank"
                >
                  <i className="fa fa-vimeo" aria-hidden="true"></i> Vimeo
                </a>
              </li>
              <li>
                <a
                  title="pinterest"
                  href="https://in.pinterest.com/imwpolitikos/"
                  target="_blank"
                >
                  <i className="fa fa-pinterest" aria-hidden="true"></i>{" "}
                  Pinterest
                </a>
              </li>
              <li>
                <a
                  title="tumblr"
                  href="https://www.tumblr.com/imwpolitikos"
                  target="_blank"
                >
                  <i className="fa fa-tumblr" aria-hidden="true"></i> Tumblr
                </a>
              </li>
            </ul> */}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MorEellipsisIcon;
