import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error:'',
  sportsSelectedLang :{} 
};

const SportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    selectedSportsInterfaceAction: (state, action) => {
      state.sportsSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {  
    builder.addCase(getAllSportsData.pending, (state, action) => {   
      state.data = [];  
      state.status = "loading";
    });
    builder.addCase(getAllSportsData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllSportsData.rejected, (state, action) => {  
      state.data = []
      state.status = "error";
    });
  },
});

export const getAllSportsData = createAsyncThunk("sports/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/story/publishedcategorylisting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const { selectedSportsInterfaceAction } = SportsSlice.actions;
export default SportsSlice.reducer;
