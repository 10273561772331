import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error: "",
  userSelectedNewsFeedData: [],
  userSelectedInterfaceData: [],
  defaultselectedInterface: [],
  userProfileData:[],
  userLocation:[],
  workExperienceData:[],
  educationQualificationData:[]
};

const ProfileSettingsSlice = createSlice({
  name: "profileSettings",
  initialState,
  reducers: {
    defaultSelectedInterfaceAction: (state, action) => {
      state.defaultselectedInterface = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectingInterfaceLanguage.pending, (state, action) => {
      state.data = [];
      state.status = "loading";
    });
    builder.addCase(selectingInterfaceLanguage.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(updateLocation.pending, (state, action) => {
      state.userLocation = [];
      state.status = "loading";
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.userLocation = action?.payload;
      state.status = "idle";
    });
    builder.addCase(updateSelectedCategories.pending, (state, action) => {     
      state.status = "loading";
    });
    builder.addCase(updateSelectedCategories.fulfilled, (state, action) => {      
      state.status = "idle";
    });

    builder.addCase(updateProfileUserSettings.pending, (state, action) => {     
      state.status = "loading";
    });
    builder.addCase(updateProfileUserSettings.fulfilled, (state, action) => {      
      state.status = "idle";
    });
    builder.addCase(updateProfileUserSettings.rejected, (state, action) => {     
      state.status = "Failed to Getting Gender data";
    });
    builder.addCase(updateProfileUserPicture.pending, (state, action) => {     
      state.status = "loading";
    });
    builder.addCase(updateProfileUserPicture.fulfilled, (state, action) => {      
      state.status = "idle";
    });
    builder.addCase(updateProfileUserPicture.rejected, (state, action) => {     
      state.status = "Failed to Getting Gender data";
    });
    
    builder.addCase(selectingNewsFeedLanguage.pending, (state, action) => {     
      state.status = "loading";
    });
    builder.addCase(selectingNewsFeedLanguage.fulfilled, (state, action) => {      
      state.status = "idle";
    });
    builder.addCase(getUserselectedInterface.pending, (state, action) => {
      state.userSelectedInterfaceData = [];
      state.status = "loading";
    });
    builder.addCase(getUserselectedInterface.fulfilled, (state, action) => {
      state.userSelectedInterfaceData = action?.payload?.data;
      state.status = "idle";
    });
    builder.addCase(
      getUserselectedNewsFeedLanguages.pending,
      (state, action) => {
        state.userSelectedNewsFeedData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getUserselectedNewsFeedLanguages.fulfilled,
      (state, action) => {
        state.userSelectedNewsFeedData = action?.payload?.data;
        state.status = "idle";
      }
    );
    builder.addCase(
      getUserProfileData.pending,
      (state, action) => {
        state.userProfileData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getUserProfileData.fulfilled,
      (state, action) => {
        state.userProfileData = action?.payload?.data;
        state.status = "idle";
      }
    );
    builder.addCase(
      getUserProfileData.rejected,
      (state, action) => {
        state.userProfileData = [];
        state.status = "Something went wrong";
      }
    );
    builder.addCase(
      getWorkExperienceData.pending,
      (state, action) => {
        state.workExperienceData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getWorkExperienceData.fulfilled,
      (state, action) => {
        state.workExperienceData = action?.payload?.data;
        state.status = "idle";
      }
    );
    builder.addCase(
      getWorkExperienceData.rejected,
      (state, action) => {
        state.workExperienceData = [];
        state.status = "Something went wrong";
      }
    );
    builder.addCase(
      getEducationalQualificationData.pending,
      (state, action) => {
        state.educationQualificationData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getEducationalQualificationData.fulfilled,
      (state, action) => {
        state.educationQualificationData = action?.payload?.data;
        state.status = "idle";
      }
    );
    builder.addCase(
      getEducationalQualificationData.rejected,
      (state, action) => {
        state.educationQualificationData = [];
        state.status = "Something went wrong";
      }
    );
    
  },
});

export const selectingInterfaceLanguage = createAsyncThunk(
  "selectInterface/post",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/inteface/lan/select`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);
export const updateLocation = createAsyncThunk(
  "location/post",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/location`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);
export const updateSelectedCategories = createAsyncThunk(
  "updateCategories/post",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/category/select`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);
export const updateProfileUserSettings = createAsyncThunk(
  "updateProfileUserSettings/post",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);
export const selectingNewsFeedLanguage = createAsyncThunk(
  "newsFeed/post",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/newsfeed/lan/select`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);
export const getUserselectedInterface = createAsyncThunk(
  "userSelctedInterface/get",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/inteface?user_id=${payload}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);
export const getUserselectedNewsFeedLanguages = createAsyncThunk(
  "userSelctedNewsFeed/get",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/newsfeed?user_id=${payload}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);

export const getUserProfileData = createAsyncThunk(
  "userProfile/get",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/profile/${payload}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);
export const getWorkExperienceData = createAsyncThunk(
  "workExperience/get",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/WorkExperience/available/${payload}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);
export const getEducationalQualificationData = createAsyncThunk(
  "educationalQualifications/get",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/EducationalQualification/available/${payload}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);
export const updateProfileUserPicture = createAsyncThunk(
  "updateProfileUserPicture/post",
  async (formData) => {
    const data = await fetch(`${BaseUrl}/user/profileimage?user_id=${66}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const result = await data.json();
    return result;
  }
);
export default ProfileSettingsSlice.reducer;
