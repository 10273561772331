import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';

function SubmitCorrections() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Submit Corrections</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/submitcorrections.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>Our goal is to provide you with the best content possible. If you notice any errors, inaccuracies, or issues in our articles, kindly let us know by emailing us at  <a href="mailto: contact@politikos.in">contact@politikos.in</a> We welcome your assistance in improving our content.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default SubmitCorrections
