import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';

function SIIMW() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>SIIMW</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/siimw.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1 siimw_page">
                                <h2>Research:</h2>
                                <p>SMART INSTITUTE OF INTERNET MEDIA WORLD (SIIMW) is a think tank that conducts and engages in media research, analysis and interpretative activities.</p>
                                <p>While doing research and reporting, SIIMW considers various aspects of media and society, like the impact of the media, new trends in the field, and how news and media should benefit society and people.</p>
                                <h2>Training:</h2>
                                <p>SIIMW offers job-oriented skill development courses in media and related subjects. These courses' duration ranges from 6 weeks to 1 year in various subjects like digital storytelling, Mobile Journalism, Social Media influencers, Video Editing, Photography, etc. By providing deep knowledge and in-depth understanding, these courses help to acquire job opportunities in the media, OTT and other digital platforms, as well as skill enhancement to the existing professionals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default SIIMW
