import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  selectedInterfaceLanguage: {},
};

const interfaceLanguageSlice = createSlice({
  name: "interfaceLanguages",
  initialState,
  reducers: {
    selectedInterfaceAction: (state, action) => {
      state.selectedInterfaceLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {   
    builder.addCase(getAllInterfaceLanguages.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.status = "idle";
    });
  },
});

export const getAllInterfaceLanguages = createAsyncThunk(
  "interfaceLanguage/get",
  async () => {
    const data = await fetch(`${BaseUrl}/interfacelanguage/available`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);

export const { selectedInterfaceAction } = interfaceLanguageSlice.actions;
export default interfaceLanguageSlice.reducer;
