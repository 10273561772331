import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error:'',
  businessSelectedLang :{} 
};

const BusinessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    selectedBusinessInterfaceAction: (state, action) => {
      state.businessSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {  
    builder.addCase(getAllBusinessData.pending, (state, action) => {   
      state.data = [];  
      state.status = "loading";
    });
    builder.addCase(getAllBusinessData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllBusinessData.rejected, (state, action) => {  
      state.data = []
      state.status = "error";
    });
  },
});

export const getAllBusinessData = createAsyncThunk("business/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/story/publishedcategorylisting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const { selectedBusinessInterfaceAction } = BusinessSlice.actions;
export default BusinessSlice.reducer;
