import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import ReactPlayer from "react-player";
import viewImg from "../../imgaes/viewIcon.svg";
import deleteImg from "../../imgaes/delete.png";
import downloadImg from "../../imgaes/download.png";
import upload from "../../imgaes/upload.png";
//   import downloadImage from "downloadjs";
import moment from "moment";
import BaseUrl from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import TranslateWord from "../sharedComponents/TranslateWord";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

let imgIndex = null;
let replaceImgId = null;
let fileCropedDetails = null;
let updatingImages = [];
let storyId = null;
const StoryDetailsTab = ({
  open,
  sendStoryId,
  tabData,
  sendStoryIdtoParent,
  sendStepFlag,
}) => {
  console.log("tabData ", tabData);
  const Quill = ReactQuill.Quill;
  var Font = Quill.import("formats/font");
  Font.whitelist = ["faustina"];

  useEffect(() => {
    if (tabData?.newsfeed_lang_name === "తెలుగు") {
      Font.whitelist = ["Mallanna"];
    } else {
      Font.whitelist = ["faustina"];
    }
  }, [tabData]);
  if (Font.whitelist?.length === 0) Font.whitelist = ["faustina"];
  Quill.register(Font, true);
  const [storyDetailsForm] = Form.useForm();
  const inputRef = React.useRef();

  const [descriptionError, setDescriptionError] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [imagesForCard, setImagesForCard] = useState([]);
  const [fModalVisible, setFModalVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [youtubeLinkData, setYoutubeLinkData] = useState("");
  const [videoURLpath, setVideoURLpath] = useState(null);
  const [youtubeLinkFlag, setYoutubeLinkFlag] = useState(false);
  const [isAnonymously, setIsAnonymously] = useState(false);
  const [commentsVisibility, setCommentsVisibility] = useState(true);
  const [latestType, setLatestType] = useState("");
  const [editedImagesId, setEditedImagesId] = useState([]);
  const [deletedImagesId, setDeletedImagesId] = useState([]);
  const [videoBinaryFormat, setVideoBinaryFormat] = useState("");
  const [videoURLId, setVideoURLId] = useState(null);
  const [storyTypeData, setStoryTypeData] = useState([]);
  const [storyLevelData, setStoryLevelData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allPrimaryTags, setAllPrimaryTags] = useState([]);
  const [allCategoriesIds, setAllCategoriesIds] = useState([]);
  const [allSubCategoriesIds, setAllSubCategoriesIds] = useState([]);
  const [defaultValuesForTags, setDefaultValuesForTags] = useState([]);
  const [allPrimaryTagsIds, setAllPrimaryTagsIds] = useState([]);
  const [oldImagesCount, setOldImagesCount] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [storyImages, setStoryImages] = useState([]);
  // const [storyId, setStoryId] = useState(null);
  const [stepFlag, setStepFlag] = useState(false);
  const [interfaceLangBackupData, setInterfaceLangBackupData] = useState([]);
  const [storyTypeBackupData, setStoryTypeBackupData] = useState([]);
  
  const {      
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);

  const [adminStroyListData, setAdminStroyListData] = React.useState({
    story_date: null,
    title: null,
    story: null,
    storyLocation: null,
    storyLevelId: null,
    storyLevel: null,
    storyTypeId: null,
    story_type_id: null,
    newsFeedLanguageId: null,
    newsfeed_language_id: null,
    targetArea: null,
    publish: null,
    categories: [],
    primaryTags: [],
    secondaryTags: [],
    submitterId: null,
    badge: null,
    submitterType: null,
    submitterName: null,
    sno: null,
  });

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {
          color: [
            "#FF0000",
            "#00008B",
            "#ADD8E6",
            "#FFD700",
            "black",
            "#964B00",
            "#808080",
            "#023020",
            "#FF4D01",
            "#FF69B4",
            "#A020F0",
            "#8F00FF",
            "#bb1919",
            " #800000",
          ],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
  ];
  const props = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error(`${file.name} is not an image file`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
  };

  const handleRemoveYoutubeLink = () => {
    setYoutubeLinkFlag(false);
    setYoutubeLinkData("");
    setLatestType("");
    storyDetailsForm.setFieldsValue({
      youtube_video_link: null,
    });
  };
  const handleChangeRTF = (html) => {
    const withoutTags = html.replace(/<[^>]*>/g, "");
    if (withoutTags?.length > 10000) setDescriptionError(true);
    else setDescriptionError(false);
    setEditorHtml(html);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    console.log("file", file);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const updatingImgs = (imgs) => {
    setSelectedImages(imgs);
  };

  const handleYoutubeLink = () => {
    setYoutubeLinkFlag(true);
    setLatestType("youtube");
  };
  const handleGetYoutubeLink = (e) => {
    setYoutubeLinkData(e.target.value);
  };

  const handleImageModelCancel = () => {
    setFModalVisible(false);
  };

  const handlePreview = async (file) => {
    // setPreviewOpen(true);
    setFModalVisible(true);
    setImagePreview(file);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    updatingImages = storyImages && [...storyImages];

    let lastElement = updatingImages?.slice(-1);
    console.log("selectedImages1", lastElement);
    if (imgIndex !== null) updatingImages[imgIndex] = lastElement?.[0];

    console.log("updatingImages", updatingImages);
    let updatedElement = updatingImages?.slice(-1)?.[0];
    if (imgIndex !== null) updatingImages[imgIndex] = updatedElement;
    let allImages = updatingImages && [...updatingImages];
    console.log("selectedImages3", allImages);
    setImagesForCard(allImages);
  }, [storyImages]);
  const uploadButton = (
    <Button
      type="primary"
      style={{
        width: "10vw",
        height: "5vh",
        position: "relative",
        top: "-6.2vh",
        left: "19vw",
        border: "none !important",
      }}
    >
      <div>Upload Images</div>
    </Button>
  );

  const handleChoose = (event) => {
    inputRef?.current?.click();
    setLatestType("video");
  };

  const getRemovedImgIds = async (id, index) => {
    const result = await axios.delete(`${BaseUrl}/story/image?images_id=${id}`);
    if (result.data?.is_success) {
      message.success({
        content: "Image deleted",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
      
      const list = storyImages.filter(function (item) {
        return item.image_id !== id;
      });

      setStoryImages(list);
    } else {
      message.error({
        content: "Image not deleted",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
     
    }

    // storyImages.splice(index, 1);
    // let deleteSelectImg = [...storyImages];
    // deleteSelectImg.splice(index, 1);
    // setImagesForCard(deleteSelectImg);
    // const ids = [...deletedImagesId, id];
    // setDeletedImagesId(ids);
  };
  const handleFileChange = async (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      // Check if the file size is below 100MB (in bytes)
      // const maxSize = 100 * 1024 * 1024; // 100MB
      // if (file?.size > maxSize) {
      //   toast.error("File size exceeds 100MB. Please choose a smaller file.");
      //   inputRef.current.value = "";
      //   return;
      // }
      setVideoBinaryFormat(file);
      const url = URL.createObjectURL(file);
      setVideoURLpath(url);
      const formData = new FormData();

      formData.append("file", file);      
        const response = await fetch(`${BaseUrl}/story/uploadvideo`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        console.log('video',data)
        setVideoURLpath(data?.video_url);
        setVideoURLId(data?.video_id);
        // setVideoId(data.data.video_id);
      
    }
  };

  const deleteVideo = async () => {
    const result = await axios.delete(
      `${BaseUrl}/story/video?video_id=${videoURLId}`
    );
    if (result.data?.is_success) {
      message.success({
        content: "Video deleted",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
      

      setLatestType("");
      setVideoURLpath(null);
    } else {
      message.error({
        content: "Video not deleted",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });     
    }
  };

  // const handleStateError = () => {
  //   const state = storyDetailsForm.getFieldValue("state");
  //   const dist = storyDetailsForm.getFieldValue("district");
  //   if (!state) {
  //     // setStateError("");
  //   }
  //   if (state && dist) {
  //     try {
  //       checkStateExistOrNotAPI({ state, dist })
  //         .unwrap()
  //         .then((payload) => {
  //           if (payload?.is_success) {
  //             if (payload?.error) {
  //               // setStateError(payload?.error);
  //             } else {
  //               // setStateError("");
  //             }
  //           } else {
  //             // setStateError("");
  //           }
  //         })
  //         // .catch((error) => {
  //         //   toast.error(error?.data?.error);
  //         // });
  //     } catch (err) {
  //       toast.error("Failed to get data");
  //     }
  //   }
  // };

  // const handleDistError = () => {
  //   const state = storyDetailsForm.getFieldValue("state");
  //   const dist = storyDetailsForm.getFieldValue("district");
  //   if (!dist) {
  //     // setDistError("");
  //   }
  //   if (state && dist) {
  //     try {
  //       checkDistExistOrNotAPI({ dist, state })
  //         .unwrap()
  //         .then((payload) => {
  //           if (payload?.is_success) {
  //             if (payload?.error) {
  //               // setDistError(payload?.error);
  //             } else {
  //               // setDistError("");
  //             }
  //           } else {
  //             // setDistError("");
  //           }
  //         })
  //         // .catch((error) => {
  //         //   toast.error(error?.data?.error);
  //         // });
  //     } catch (err) {
  //       toast.error("Failed to get data");
  //     }
  //   }
  // };

  const getStoryTypeDataFromSever = async (lang) => {
    const response = await fetch(
      `${BaseUrl}/storytype/available/langname/${lang}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const payload = await response.json();
    console.log("daaa", payload.data);
    if (payload.is_success === true) setStoryTypeData(payload?.data);
  };
  const changeFeedlanguage = async (value) => {
    const lang = value?.label;
    setSelectedLanguage(lang);
    localStorage.setItem("feedLang", JSON.stringify(lang));
    // getStoryTypeDataFromSever(lang);
    getStoryTypeDataAPIcall(lang);
    storyDetailsForm.setFieldsValue({
      story_type_id: null,
    });
  };
  const saveStoryDetailsTabData = async (payload) => {
    if (payload) {
      const response = await fetch(`${BaseUrl}/story/storydetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),  
      });
      const res = await response.json();
      console.log("daaa", res.data);
      if (res?.data?.is_saved === true) { 
        message.success({
          content: "Successfully Saved",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
        storyId = res?.data?.story_id;
        sendStoryIdtoParent(res?.data?.story_id);
        setStepFlag(true);
      }
    }
  };

  const submitingFormData = (values) => {   
    const LOGIN_USER_ID =   localStorage.getItem("loginuserId");
    localStorage.setItem('storyDetailsTabData',JSON.stringify(values))  
    const storyMediaData = {
      images:storyImages,
      video_id:videoURLId,
      video_url:videoURLpath
    }
    localStorage.setItem('storyMediaData',JSON.stringify(storyMediaData))
    // localStorage.setItem('feedLang',JSON.stringify(lang))
    if(!values?.newsfeed_language_id?.value){
     let langData =   interfaceLangBackupData?.data?.filter((val) =>{
          return val?.newsfeed_lang_id === values?.newsfeed_language_id
        })
        console.log('langData ',langData)
        let lang = langData?.[0]?.name
        console.log('langData ',lang)
        localStorage.setItem('feedLang',JSON.stringify(lang))
    }
    const payload = {
      belongs_to_story_id: 0,
      district: values?.district,
       employee_id: +LOGIN_USER_ID,
      id: tabData?.id ? tabData?.id : sendStoryId,
      image_urls: storyImages?.map(item => item?.image_url),
      is_images_uploaded: storyImages?.length ? true : false,
      is_video_uploaded:videoURLpath ?  true :false,
      location: values?.location,
      // log_in_user_id: 0,
      mandal: values?.mandal,
      newsfeed_language_id: values?.newsfeed_language_id?.value ? values?.newsfeed_language_id?.value :values?.newsfeed_language_id,
      state: values?.state,
      stored_images_ids: storyImages?.map(item => item?.image_id),
      story: values?.story,
      // story_date: values?.story_date,
      story_date:!isNaN(tabData?.story_date) ? moment(tabData?.story_date).toISOString() :moment().toISOString(),
      story_type_id: values?.story_type_id?.value ? values?.story_type_id?.value :values?.story_type_id,
      title: values?.title,
      video_id: videoURLId,
      video_url: videoURLpath,
      village: values?.village,
      youtube_video_link: values?.youtube_video_link,
    };
    if (payload.belongs_to_story_id === 0) {
      delete payload.belongs_to_story_id;
  }
  if (!tabData?.id && !sendStoryId) {
    delete payload?.id;
  }
  
  if(!videoURLpath){
    delete payload?.video_id
    delete payload?.video_url
  }
    console.log('payload',    console.log(payload));
    saveStoryDetailsTabData(payload)
  };

  const getFeedLangData = async () => {
    const data = await fetch(`${BaseUrl}/newsfeedlanguage/available`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    setInterfaceLangBackupData(result);
  };

  const getStoryDetailsTabData = async (id) =>{
    const data = await fetch(`${BaseUrl}/story/storytabwisedetails/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      
    });
    const result = await data.json();
    if(result?.data){
      getStoryTypeDataAPIcall(result?.data?.storyDetails?.newsfeed_lang_name)
      setStoryImages(result?.data?.storyDetails?.stored_images);
      getStoryTypeDataFromSever(result?.data?.storyDetails?.newsfeed_lang_name);
      setVideoURLpath(result?.data?.storyDetails?.video?.video_url)
      setVideoURLId(result?.data?.storyDetails?.video?.video_id)
      storyDetailsForm.setFieldsValue({
        newsfeed_language_id:result?.data?.storyDetails?.newsfeed_language_id,
        story_date:!isNaN(result?.data?.storyDetails?.story_date) ?moment(result?.data?.storyDetails?.story_date)?.format('DD-MM-YYYY HH:mm:ss') :moment().format('DD-MM-YYYY HH:mm:ss'),
        title:result?.data?.storyDetails?.title,
        story:result?.data?.storyDetails?.story,
        story_type_id:result?.data?.storyDetails?.story_type_id,
        location:result?.data?.storyDetails?.location,
        state:result?.data?.storyDetails?.state,
        district:result?.data?.storyDetails?.district,
        mandal:result?.data?.storyDetails?.mandal,
        village:result?.data?.storyDetails?.village,  
        youtube_video_link:result?.data?.storyDetails?.youtube_video_link     
      });
      if(result?.data?.storyDetails?.youtube_video_link){
        setYoutubeLinkFlag(true)
        setYoutubeLinkData(result?.data?.storyDetails?.youtube_video_link)
      }else {
          setYoutubeLinkFlag(false)
        }
    }
    console.log('result',result)
  }
  const getStoryTypeDataAPIcall = async (lang) => {
    const data = await fetch(
      `${BaseUrl}/storytype/available/langname/${lang}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await data.json();
    setStoryTypeBackupData(result);
  };

  useEffect(() =>{
    getFeedLangData()
    const firstTabData = JSON.parse(localStorage.getItem('storyDetailsTabData'));
    const lang = JSON.parse(localStorage.getItem('feedLang'));
    const storyMediaData = JSON.parse(localStorage.getItem('storyMediaData'));
   
    console.log('interfaceLangBackupData',interfaceLangBackupData)
    if(firstTabData?.title || firstTabData?.story){  
      storyDetailsForm.resetFields(); 
      lang && getStoryTypeDataAPIcall(lang);
      //  setStoryImages(storyMediaData?.images);
       lang && getStoryTypeDataFromSever(lang);
      setVideoURLpath(storyMediaData?.video_url)
      setVideoURLId(storyMediaData?.video_id);     
    }
    if(tabData?.id || sendStoryId){
// getting story details tab data from db
let Sid = null
   if(tabData?.id){
    Sid = tabData?.id 
   }else{
    if(sendStoryId){
      Sid = sendStoryId 
    }
   }
   getStoryDetailsTabData(Sid)    
       Font.whitelist =
       tabData && tabData?.newsfeed_lang_name === "English"
      ? ["faustina"]
      :  tabData && tabData?.newsfeed_lang_name === "తెలుగు"
      ? ["Mallanna"]
      : ["Roboto"];
     if(Font.whitelist?.length == 0){
      if(tabData?.newsfeed_lang_name === "తెలుగు"){
        Font.whitelist = ["Mallanna"]
      }else{
        Font.whitelist = ["faustina"]
      }
     }   
    }
  },[tabData?.id])
  const handleNextStep = () => {
    sendStepFlag(true);
  };

  const onChange = async ({ fileList: newFileList }) => {
    console.log("newFileList", newFileList);

    if (newFileList[newFileList?.length - 1]?.percent === 100) {
      const imageData_url = await getBase64(
        newFileList[newFileList?.length - 1]?.originFileObj
      );

      var requestOptions = {
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          story_image: imageData_url,
        }),
      };

      fetch(`${BaseUrl}/story/uploadimages1`, requestOptions)
        .then((response) => response.json())
        .then(async (_result) => {
          if (_result?.is_success) {
            console.log("_result", _result);
            let allUploadedImages = storyImages
              ? [...storyImages, _result?.data?.[0]]
              : [_result?.data?.[0]];
            setStoryImages(allUploadedImages);
            setFileList([]);
            // list = [...list, ..._result?.data];
            // if (list.length <= 5) setImages(list as never[]);
          }
        })
        .catch((error) => console.log("error", error));
    }
    // fileList = []
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={storyDetailsForm}
        className="userFormclass"
        onFinish={submitingFormData}
        initialValues={{
          remember: true,
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="newsfeed_language_id"
              label=  {selectedInterfaceLanguage && (
                <TranslateWord
                key={"LANGUAGE"}
                text={"LANGUAGE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                {
                  required: true,
                  message:selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"VALIDATION_LANGUAGE"}
                    text={"VALIDATION_LANGUAGE"}
                    lang={selectedInterfaceLanguage}
    
                  />
                   ),
                },
              ]}
            >
              <Select
                // placeholder="Select News feed Language"
                onChange={changeFeedlanguage}
                showSearch
                labelInValue
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {interfaceLangBackupData?.data
                  ? interfaceLangBackupData?.data?.map((Lang) => (
                      <Option
                        key={Lang.newsfeed_lang_id}
                        value={Lang.newsfeed_lang_id}
                      >
                        {Lang.name}
                      </Option>
                    ))
                  : "No data available"}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="story_date" label= {selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_DATE_TIME"}
                text={"STORY_DATE_TIME"}
                lang={selectedInterfaceLanguage}

              />
               )}>
              <Input
                name="story_date"
                readOnly
                defaultValue={
                  !isNaN(tabData?.story_date)
                    ? moment(tabData?.story_date)?.format("DD-MM-YYYY HH:mm:ss")
                    : moment().format("DD-MM-YYYY HH:mm:ss")
                }
                style={{ backgroundColor: "#fdfbfb" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="title"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"TITLE"}
                text={"TITLE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                {
                  required: true,
                  message: selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"VALIDATION_TITLE"}
                    text={"VALIDATION_TITLE"}
                    lang={selectedInterfaceLanguage}
    
                  />
                   ),
                },
                {
                  max: 200,
                  message: "Maximum 200 Charecters allowed",
                },
              ]}
            >
              <TextArea name="title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <pre>
              <code>
                <Form.Item
                  name="story"
                  label={selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"STORY_DESCRIPTION"}
                    text={"STORY_DESCRIPTION"}
                    lang={selectedInterfaceLanguage}
    
                  />
                   )}
                  rules={[
                    {
                      required: true,
                      message: selectedInterfaceLanguage && (
                        <TranslateWord
                        key={"VALIDATION_DESCRIPTION"}
                        text={"VALIDATION_DESCRIPTION"}
                        lang={selectedInterfaceLanguage}
        
                      />
                       ),
                    },
                  ]}
                >
                  <ReactQuill
                    theme={"snow"}
                    onChange={handleChangeRTF}
                    value={editorHtml}
                    modules={modules}
                    formats={formats}
                    placeholder={"Write"}
                  />
                </Form.Item>
              </code>
            </pre>
            {descriptionError && (
              <div style={{ color: "red", marginBottom: "20px" }}>
                Maximum 10000 Charecters allowed
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={48} style={{ marginBottom: "-50px" }}>
          <Col span={24}>
            <Form.Item
              className="custom-label"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"PHOTES_VIDEOS_LABLE"}
                text={"PHOTES_VIDEOS_LABLE"}
                lang={selectedInterfaceLanguage}

              />
               )}
            ></Form.Item>
          </Col>
        </Row>

        <Row gutter={48} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <>
              <span>
                <ImgCrop rotate quality={1} aspect={406 / 228}>
                  <Upload onChange={onChange}>
                    {(storyImages?.length === undefined ||
                      storyImages === 0 ||
                      storyImages?.length < 5) &&
                      !videoURLpath &&
                      !youtubeLinkFlag &&
                      uploadButton}
                  </Upload>
                </ImgCrop>
              </span>
              {!videoURLpath &&
                !storyImages?.length > 0 &&
                !youtubeLinkFlag && (
                  <Button
                    type="primary"
                    style={{
                      position: "relative",
                      top: "-11.3vh",
                      left: "30vw",
                    }}
                    onClick={handleChoose}
                  >
                    Upload Video
                  </Button>
                )}
              {!youtubeLinkFlag &&
                !storyImages?.length > 0 &&
                !videoURLpath && (
                  <Button
                    type="primary"
                    onClick={handleYoutubeLink}
                    style={{
                      position: "relative",
                      top: "-11.3vh",
                      left: "31vw",
                    }}
                  >
                    Add Youtube Link
                  </Button>
                )}
              <Row gutter={4}>
                {storyImages?.map((image, index) => {
                  return (
                    true && (
                      <>
                        <Col span={5}>
                          <Card
                            hoverable
                            cover={
                              <>
                                {" "}
                                <img
                                  src={image?.image_url}
                                  alt=""
                                  width={100}
                                  height={100}
                                />
                              </>
                            }
                          >
                            <Tooltip title="View" color={"white"}>
                              <img
                                src={viewImg}
                                style={{
                                  marginRight: "12px",
                                  cursor: "pointer",
                                }}
                                width={15}
                                height={15}
                                onClick={() => handlePreview(image.image_url)}
                              />
                            </Tooltip>

                            <Tooltip title="Delete" color={"white"}>
                              <img
                                src={deleteImg}
                                style={{
                                  marginRight: "12px",
                                  cursor: "pointer",
                                }}
                                width={15}
                                height={15}
                                onClick={() => {
                                  getRemovedImgIds(image?.image_id, index);
                                }}
                              />
                            </Tooltip>
                          </Card>
                        </Col>
                      </>
                    )
                  );
                })}
              </Row>
            </>
          </Col>
        </Row>
        {videoURLpath && (
          <Row>
            <Col span={12}>
              <ReactPlayer
                width={500}
                height={200}
                controls
                url={videoURLpath}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        )}
        {youtubeLinkFlag && (
          <Row>
            <Col span={12}>
              <Form.Item name="youtube_video_link" label="Youtube Link">
                <Input
                  name="youtube_video_link"
                  onChange={handleGetYoutubeLink}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Tooltip title="Delete" color={"white"}>
                <img
                  src={deleteImg}
                  style={{
                    marginRight: "12px",
                    cursor: "pointer",
                    position: "relative",
                    top: "36px",
                    left: "15px",
                  }}
                  width={15}
                  height={15}
                  onClick={handleRemoveYoutubeLink}
                />
              </Tooltip>
            </Col>
          </Row>
        )}
        {youtubeLinkData && (
          <Row>
            <Col span={12}>
              <ReactPlayer
                width={500}
                height={200}
                controls
                url={youtubeLinkData}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col span={12}>
            <div>
              <input
                ref={inputRef}
                className="VideoInput_input"
                type="file"
                onChange={handleFileChange}
                accept=".mov,.mp4"
              />

              <div style={{ margin: "20px 0px" }}>
                {videoURLpath && (
                  <Tooltip title="Upload edited Video" color={"white"}>
                    <img
                      src={upload}
                      style={{ marginLeft: "20px", cursor: "pointer" }}
                      width={15}
                      height={15}
                      onClick={handleChoose}
                    />
                  </Tooltip>
                )}
                {videoURLpath && (
                  <Tooltip title="Delete" color={"white"}>
                    <img
                      src={deleteImg}
                      style={{ marginLeft: "100px", cursor: "pointer" }}
                      width={15}
                      height={15}
                      onClick={deleteVideo}
                    />
                  </Tooltip>
                )}
                {videoURLpath && (
                  <>
                    <a
                      href={`${BaseUrl}/story/download?file_id=${videoURLId}`}
                      download
                    >
                      <Tooltip title="Download" color={"white"}>
                        <img
                          src={downloadImg}
                          style={{ marginLeft: "100px", cursor: "pointer" }}
                          width={15}
                          height={15}
                        />
                      </Tooltip>
                    </a>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Col span={12}>
              <Form.Item
                name="storyLevel"
                label="Story Level"              
              >
                <Select
                  placeholder="Story Level"
                  // onChange={changeMandal}
                  labelInValue
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {storyLevelData
                    ? storyLevelData?.map((story) => (
                        <Option
                          key={story.story_level_name_id}
                          value={story.story_level_name_id}
                        >
                          {story.story_Level_name}
                        </Option>
                      ))
                    : "No data available"}
                </Select>
              </Form.Item>
            </Col> */}
          <Col span={24}>
            <Form.Item
              name="story_type_id"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_TYPE"}
                text={"STORY_TYPE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                {
                  required: true,
                  message:  selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"VALIDATION_STORYTYPE"}
                    text={"VALIDATION_STORYTYPE"}
                    lang={selectedInterfaceLanguage}
    
                  />
                   ),
                },
              ]}
            >
              <Select
                // placeholder="StoryType"
                labelInValue
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {storyTypeBackupData
                  ? storyTypeBackupData?.data?.map((story) => (
                      <Option
                        key={story.story_type_id}
                        value={story.story_type_id}
                      >
                        {story.story_type_name}
                      </Option>
                    ))
                  : "No data available"}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="storyLocation" label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_LOCATION"}
                text={"STORY_LOCATION"}
                lang={selectedInterfaceLanguage}

              />
               )}>
              <TextArea
                name="storyLocation"
                // readOnly
                // style={{ backgroundColor: '#fdfbfb' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="state"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"STATE"}
                text={"STATE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                {
                  required: true,
                  message:  selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"VALIDATION_STATE"}
                    text={"VALIDATION_STATE"}
                    lang={selectedInterfaceLanguage}
    
                  />
                   ),
                },
                {
                  whitespace: true,
                  message: "State can not be empty",
                },
              ]}
            >
              {/* <Input name="state" onChange={handleStateError} /> */}
              <Input name="state" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"DISTRICT"}
                text={"DISTRICT"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                {
                  required: true,
                  message: selectedInterfaceLanguage && (
                    <TranslateWord
                    key={"VALIDATION_DISTRICT"}
                    text={"VALIDATION_DISTRICT"}
                    lang={selectedInterfaceLanguage}
    
                  />
                   ),
                },
                {
                  whitespace: true,
                  message: "District can not be empty",
                },
              ]}
            >
              {/* <Input name="district" onChange={handleDistError} /> */}
              <Input name="district" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="mandal" label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"MANDAL"}
                text={"MANDAL"}
                lang={selectedInterfaceLanguage}

              />
               )}>
              <Input name="mandal" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="village" label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"VILLAGE_AREA_LOCALITY"}
                text={"VILLAGE_AREA_LOCALITY"}
                lang={selectedInterfaceLanguage}

              />
               )}>
              <Input name="village" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: "80px" }}>
          <Col span={3}></Col>
          
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"SAVE"}
                text={"SAVE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              disabled={!stepFlag}
              onClick={handleNextStep}
            >
             {selectedInterfaceLanguage && (
                <TranslateWord
                key={"NEXT"}
                text={"NEXT"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Button>
          </Col>
        </Row>
      </Form>
      <ToastContainer />;
      <div className="fileViewModel">
        <Modal
          title="Image view"
          visible={fModalVisible}
          onCancel={handleImageModelCancel}
          footer={null}
          width={500}
        >
          <div className="modelIcon "></div>
          <img
            src={imagePreview}
            alt="No image Found"
            width="450"
            height="410"
          />
        </Modal>
      </div>
    </div>
  );
};

export default StoryDetailsTab;
