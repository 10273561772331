import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
};

const trendingSlice = createSlice({
  name: "trendingStories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTrendingStories.pending, (state,action) => {
        state. data = action?.payload
          state.status = "loading";
      });
    builder.addCase(getAllTrendingStories.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
  },
});

export const getAllTrendingStories = createAsyncThunk(
  "trending/get",
  async (payload) => {  
    const data = await fetch(`${BaseUrl}/story/trending`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);

export default trendingSlice.reducer;
