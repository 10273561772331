import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';

function OurBrands() {
    return (
        <>        
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Our Brands</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/ourbrands.png" alt="news" class="img-fluid" /></div>
                               
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>Internet Media World digital product Brands simplify and enhance people's everyday lives. We believe in operating our Brands responsibly, treating communities fairly with integrity and supporting programs that contribute to the vitality of the media sector for sustainable communities.</p>
                                <p>Watch this Space for Our Upcoming Brands very soon. We will excite you with Hundreds of Solutions to your lifestyle needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default OurBrands
