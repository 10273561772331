import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TranslateWord from '../../sharedComponents/TranslateWord';

function Features() {
    const {      
        selectedInterfaceLanguage,
      } = useSelector((state) => state.interfaceLanguageList);
    return (
        <>
           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>{selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'45px',fontWeight:'700'}}>ప్ర‌త్యేక‌ క‌థ‌నం (ఫీచ‌ర్‌)</span>:'Features'}</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/features_inner_bg.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"FEATURE_P1"}
                               text={"FEATURE_P1"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_network.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"VOLUNTEERS_NETWORK"}
                               text={"VOLUNTEERS_NETWORK"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_petition.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"PETITIONS"}
                               text={"PETITIONS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_grievances.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3> {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"GRIEVANCES"}
                               text={"GRIEVANCES"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_shopping.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"PURSE_FRIEND"}
                               text={"PURSE_FRIEND"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_classifieds.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"CLASSIFIEDS"}
                               text={"CLASSIFIEDS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_polls.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"POLLS"}
                               text={"POLLS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                         </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_polls.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"SURVEYS"}
                               text={"SURVEYS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                         </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_imw.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"POLITIKOS_STORE"}
                               text={"POLITIKOS_STORE"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/marketplace_icon.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"MARKET_PLACE"}
                               text={"MARKET_PLACE"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_sponsorships.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"SPONSORSSHIPS"}
                               text={"SPONSORSSHIPS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_couponsdeals.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"COUPONS_DEALS"}
                               text={"COUPONS_DEALS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_votershub.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className={selectedInterfaceLanguage?.name === "తెలుగు" ? 'feature_text_extra_voterhub' :'feature_text_xlg'}>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"VOTERS_HUBnELECTION_LAND"}
                               text={"VOTERS_HUBnELECTION_LAND"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_discussionroom.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"DISCUSSION_ROOM"}
                               text={"DISCUSSION_ROOM"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                       </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_tvottguide.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"TVnOTTGUIDE"}
                               text={"TVnOTTGUIDE"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_startupfunding.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className={selectedInterfaceLanguage?.name === "తెలుగు" ? 'feature_text_startup' :'feature_text'}>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"STARTUP_FUNDING"}
                               text={"STARTUP_FUNDING"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                       
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_supportcauses.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className= {selectedInterfaceLanguage?.name === "తెలుగు" ? 'feature_text_extra_lg' :'feature_text'}>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"SUPPORT_CAUSES"}
                               text={"SUPPORT_CAUSES"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                       </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_campaigns.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"CAMPAIGNS"}
                               text={"CAMPAIGNS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/callforpilots_icon.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"CALLFORPILOTS"}
                               text={"CALLFORPILOTS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/listenmusic_icon.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"LISTENMUSIC"}
                               text={"LISTENMUSIC"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_jobmarket.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"JOBMARKET"}
                               text={"JOBMARKET"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/betterhalf_icon.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"BETTERHALR"}
                               text={"BETTERHALR"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                       </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_marketcities.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"MARKET_CITIES"}
                               text={"MARKET_CITIES"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_epaper.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"E_PAPER"}
                               text={"E_PAPER"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_emagazines.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"E_MAGAZINES"}
                               text={"E_MAGAZINES"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_cartooncorners.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"CARTOON_CORNER"}
                               text={"CARTOON_CORNER"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                       </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_reviews.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"REVIEWS"}
                               text={"REVIEWS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_ratings.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"RATINGS"}
                               text={"RATINGS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="feature_content">
                                <div className='icon_feature'>
                                    <img src="img/imw_img/icon_rankings.png" alt="icon" class="img-fluid width-100" />
                                </div>
                                <div className='feature_text'>
                                    <h3>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"RANKINGS"}
                               text={"RANKINGS"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                        </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
        </>
    )
}

export default Features
