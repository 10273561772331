import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error:''
  
};

const categoryWiseSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCategoryData.pending, (state,action) => {
        state. data = []
          state.status = "loading";
      });
    builder.addCase(getAllCategoryData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
  },
});

export const getAllCategoryData = createAsyncThunk("categoryWise/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/story/publishedcategorylisting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export default categoryWiseSlice.reducer;
