import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { useSelector } from 'react-redux';
import TranslateWord from '../../sharedComponents/TranslateWord';

function InfoForm() {
    // State variables for form fields
    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    // State variables for form validation
    const [nameError, setNameError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');

    const form = useRef();
    const {      
        selectedInterfaceLanguage,
      } = useSelector((state) => state.interfaceLanguageList);
    const sendEmail = (e) => {
        e.preventDefault();

        // Simple validation
        if (!name) {
            setNameError('Name is required');
            return;
        } else {
            setNameError('');
        }

        if (!mobileNumber) {
            setMobileNumberError('Mobile number is required');
            return;
        } else if (!/^\d{10}$/.test(mobileNumber)) {
            setMobileNumberError('Mobile number should be exactly 10 digits');
            return;
        } else {
            setMobileNumberError('');
        }
        if (!selectedOption) {
            alert('Please select an option for "Enquiry from"');
            return;
        }
        // Email.js integration
        const templateParams = {
            name,
            mobileNumber,
            email,
            description,
            selectedOption,
        };

        emailjs
            .sendForm('service_1r5z8nq', 'template_q4ayz2j', form.current, 'YYJ3fUS_8s8GVsBzV')
            // .sendForm('service_rtkt16f', 'template_xt7x9ra', form.current, 'TQNTZN3jcN7G_9b22')
            .then(
                (result) => {
                    console.log('result @@', result.text);
                    alert('Form submitted successfully!');
                    // Optional: Reset form fields after successful submission
                    setName('');
                    setMobileNumber('');
                    setEmail('');
                    setDescription('');
                    setSelectedOption('');
                },
                (error) => {
                    console.error('Error sending email:', error);
                    alert('Form submission failed. Please try again.');
                }
            )
            .catch((error) => {
                console.error('Unhandled promise rejection:', error);
                alert('Form submission failed. Please try again.');
            });
    };

    return (
        <>
            <div className="info_form">
                <h2>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"LEAVEUS_FORM_HEADING"}
                               text={"LEAVEUS_FORM_HEADING"}
                               headingStyle={true}
                               lang={selectedInterfaceLanguage}
                             />
                              )}  </h2>
                {/* <p>and we will get back to you.</p> */}
                <form id="contact-form" className="contact-form" onSubmit={sendEmail} ref={form}>
                    <fieldset>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"NAME"}
                               text={"NAME"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}  <span className="mandatory"> * </span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="user_name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {nameError && <span style={{ color: 'red' }}>{nameError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"MOBILE"}
                               text={"MOBILE"}
                               lang={selectedInterfaceLanguage}
                             />
                              )} <span className="mandatory"> * </span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="user_mobile"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                    />
                                    {mobileNumberError && <span style={{ color: 'red' }}>{mobileNumberError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label> {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"EMAIL"}
                               text={"EMAIL"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="user_email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ENQUIRY_FORM"}
                               text={"ENQUIRY_FORM"}
                               lang={selectedInterfaceLanguage}
                             />
                              )} <span className="mandatory"> * </span></label>
                                    <select
                                        className="form-control"
                                        name="user_enquiry_from"
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                    >
                                        <option value="">{selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>ఒక ఎంపికను ఎంచుకోండి</span>:'Select an option'} </option>
                                        <option value="people_center">  {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>పీపుల్ సెంటర్</span>:'People Center'}</option>
                                        <option value="politikos_volunteers"> {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>పోలిటికోస్ వాలంటీర్లు</span>:'Politikos Volunteers'} </option>
                                        <option value="politikos_affiliates"> {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>పోలిటికోస్ అఫిలియేట్స్</span>:' Politikos Affiliates'}</option>
                                        <option value="partner_with_us"> {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>మాాతో భాగస్వామ్యం కండి</span>:'Partner with us'}</option>
                                        <option value="book_talent"> {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>బుక్ టాలెంట్</span>:' Book Talent'}</option>
                                        <option value="advertise_with_us"> {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>ప్రకటనలు ఇవ్వండి</span>:'Advertise with us'} </option>
                                        <option value="classifieds"> {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',}}>వర్గీకృత  ప్రకటనలు (క్లాసిఫైడ్)</span>:'Classifieds'} </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>{selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"DESCRIPTION"}
                               text={"DESCRIPTION"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</label>
                                    <textarea
                                        className="textarea form-control"
                                        name="user_description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group mb-none pull-right">
                                    <button type="submit" className="btn-ftg-ptp-56 disabled">
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"SUBMIT"}
                               text={"SUBMIT"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-6 col-sm-12">
                                <div className="form-response"></div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    );
}

export default InfoForm;
