import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error:'',
  technologySelectedLang :{} 
};

const TechnologySlice = createSlice({
  name: "technology",
  initialState,
  reducers: {
    selectedTechnologyInterfaceAction: (state, action) => {
      state.technologySelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {  
    builder.addCase(getAllTechnologyData.pending, (state, action) => {   
      state.data = [];  
      state.status = "loading";
    });
    builder.addCase(getAllTechnologyData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllTechnologyData.rejected, (state, action) => {  
      state.data = []
      state.status = "error";
    });
  },
});

export const getAllTechnologyData = createAsyncThunk("technology/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/story/publishedcategorylisting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const { selectedTechnologyInterfaceAction } = TechnologySlice.actions;
export default TechnologySlice.reducer;
