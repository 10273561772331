import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import  BaseUrl  from '../utils/config';

export const OtpAPI = createApi({
    reducerPath: 'OtpAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}`,
    }),
    tagTypes: ['SelectOtpAPI'],
    endpoints: (builder) => ({
        getLoggedInUsersList: builder.query({
            query: () => ({
                url: 'user/loggedin',
                method: 'GET',
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },
            }),
            providesTags: ['SelectOtpAPI']
        }),      
        getAllOtp: builder.mutation({
            query: (payload) => ({
                url: `/user/generate/otp`,
                method: 'POST',
                body: payload,
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            invalidatesTags: ['SelectOtpAPI']
        }),
        getAllValidateOtp: builder.mutation({
            query: (payload) => ({
                url: `/user/validate/otp`,
                method: 'POST',
                body: payload,
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            invalidatesTags: ['SelectOtpAPI']
        }),
    })
})


export const {    
    useGetAllOtpMutation,
    useGetAllValidateOtpMutation,
    useGetLoggedInUsersListQuery
} = OtpAPI