import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
    data:[],
   status:'idle'
  }

const forYouSlice = createSlice({
  name: "stories",
  initialState ,
  reducers: {
   
  },
  extraReducers: (builder) => { 
    builder.addCase(getAllStories.fulfilled, (state,action) => {    
      state. data = action?.payload
      state.status = "idle";
    });
   
    
 }
});


export const getAllStories = createAsyncThunk('stories/get', async (payload)=>{  
  
    const data = await fetch(`${BaseUrl}/story/listing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result
    
})

export default forYouSlice.reducer;
