import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import uploadProfileImg from "../../imgaes/uploadProfileImg.svg";
import editIcon from "../../imgaes/editIcon.svg";
import sharingIcon from "../../imgaes/sharingIcon.svg";
import commentsIcon from "../../imgaes/commentsIcon.svg";
import groupUsersIcon from "../../imgaes/groupUsersIcon.svg";
import viewIcon from "../../imgaes/viewIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import OTP from "../Login/OTP/OTP";
import { getOTPData, sendOTPtoMail } from "../../Store/LoginSlice";
import {
  getUserProfileData,
  updateProfileUserPicture,
  updateProfileUserSettings,
} from "../../Store/ProfileSettingsSlice";
import BaseUrl from "../../utils/config";
import deleteIcon from "../../imgaes/deleteIcon.png";
import axios from "axios";
import TranslateWord from "../sharedComponents/TranslateWord";
import { getAllSubmissionTabStories } from "../../Store/SubmissionStoriesSlice";
import { getAllSubmissionTabPetitions } from "../../Store/SubmissionPetitionsSlice";
import { getAllSubmissionTabGrievance } from "../../Store/SubmissionGrievanceSlice";
import { size } from "lodash";
import ReasonModel from "./ReasonModel";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";
import { PlusCircleOutlined } from '@ant-design/icons';
import AddNewStory from "./AddNewStory";


const { Text } = Typography;
let filterText = "";
const SubmissionsTab = () => { 
  const { TabPane } = Tabs;
 
  const dispatch = useDispatch();
  const [profileAboutYourSelf] = Form.useForm();
  const [currentPageForStories, setCurrentPageForStories] = useState(1);
  const [currentPageForPetitions, setCurrentPageForPetitions] = useState(1);
  const [currentPageForGrievances, setCurrentPageForGrievances] = useState(1);
  const [rejectReasonFlag, setRejectReasonFlag] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [reasonTitle, setReasonTitle] = useState(null);
  const [searchIconHide, setsearchIconHide] = useState(false);
  const [searchInputHide, setSearchInputHide] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("Draft");
  const [addNewStoryFlag,setAddNewStoryFlag] = useState(false);
  const [storyData, setStoryData] = useState([]);
  const cardsPerPage = 10;

  const { selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );

  const { data: submissionStoriesData } = useSelector(
    (state) => state.submissionStoriesList
  );
  const { data: submissionPetitionsData } = useSelector(
    (state) => state.submissionPetitionsList
  );
  const { data: submissionGrievancesData } = useSelector(
    (state) => state.submissionGrievanceList
  );
  const { userProfileData, workExperienceData, educationQualificationData } =
    useSelector((state) => state.profileSettingsList);

    let user_id = userProfileData?.user_id;
  const getSubmissionTabStoriesData = (page) => {
    const payload = {
      user_id:user_id,
      page:page - 1,
      pageSize:10

    }
    // dispatch(getAllSubmissionTabStories(payload));
  };
  const getSubmissionTabPetitionsData = (page) => {
    const payload = {
      user_id:user_id,
      page:page - 1,
      pageSize:10

    }
    dispatch(getAllSubmissionTabPetitions(payload));
  };
  const getSubmissionTabGrievanceData = (page) => {
    const payload = {
      user_id:user_id,
      page:page - 1,
      pageSize:10

    }
    dispatch(getAllSubmissionTabGrievance(payload));
  };
  useEffect(() =>{
    getSubmissionTabStoriesData(1);
    getSubmissionTabPetitionsData(1);
    getSubmissionTabGrievanceData(1)
  },[])

 




 
 
 


  const iconStyle = {
    marginTop: !searchIconHide ? "1px" : "0px",
    marginLeft: "5px",
  };

  const scrollCSS = {
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
    justifyContent: "center",
    width: "84vw",
    marginTop: "20px",
  };



 
  const handlePageChangeForStories = (page, pageSize) => {  
    setCurrentPageForStories(page);
     getSubmissionTabStoriesData(page)
  };
  const handlePageChangeForPetitions = (page, pageSize) => {  
    setCurrentPageForPetitions(page);
    
    getSubmissionTabPetitionsData(page)
  };
  const handlePageChangeForGrievances = (page, pageSize) => {  
    setCurrentPageForGrievances(page);
    getSubmissionTabGrievanceData(page)
  };

  const hangleRejectReason = (story,title) =>{
    setSelectedStory(story)
    setRejectReasonFlag(true);
    setReasonTitle(title)
  }
  const closeReasonModel = () =>{
    setRejectReasonFlag(false)
  }

  const openStoryModalPopup = async (story) => {
    // setIsLoading(true);
    setAddNewStoryFlag(true);
    setStoryData([]);
    const data = await fetch(
      `${BaseUrl}/story/storytabwisedetails/${story.story_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await data.json();
    // setIsLoading(false);
    setStoryData(result);
    return result;
  };

  const showInput = () => {
    setSearchInputHide(false);
    setsearchIconHide(true);
  };

  const onSearch = (event) => {
    setSearchText(event?.target?.value);
    filterText = event?.target?.value;
    if (event?.key === "Enter") getPreviousStoresListData(1);
  };

  const handleEnter = (e) => {
    if (e?.key === "Enter") {
      setCurrentPageForStories(1);
      if (currentPageForStories === 1) {
        getPreviousStoresListData(1);
      }
    }
  };
  useEffect(() => {
    getPreviousStoresListData(0);
  }, []);

  useEffect(() => {
    getPreviousStoresListData(currentPageForStories);
  }, [currentPageForStories]);
  useEffect(() => {
    setCurrentPageForStories(1);
    getPreviousStoresListData(1);
  }, [type]);
  const getAllPreviousStoriesFunc = async (payload) => {
    // setIsLoading(true);
    // const {user_id,page,pageSize} = payload
    const data = await fetch(`${BaseUrl}/story/previousstorieslist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    // setPreviousStoriesList(result);
    // setIsLoading(false);
    return result;
  };
  const handleDashboardData = (type) => {
    setType(type);
    setCurrentPageForStories(1);
  };
  const closeInputBox = () => {
    setSearchInputHide(true);
    setsearchIconHide(false);
    onSearch("");
    setSearchText("");
    filterText = "";
    getPreviousStoresListData(1);
  };
  const openNewStoryModel = () =>{
    setAddNewStoryFlag(true);
    localStorage.removeItem('storyDetailsTabData')
    localStorage.removeItem('storyOthersTabData')
    localStorage.removeItem('storyMediaData')
    localStorage.removeItem('feedLang')
  }
  const getPreviousStoresListData = (page) => {
    const LOGIN_USER_ID = localStorage.getItem("loginuserId");
    const payload = {
      employee_id: +LOGIN_USER_ID,
      filter_by_search_text: filterText,
      status: type,
      page: page === 0 ? page : page - 1,
      pageSize: 12,
    };
    dispatch(getAllSubmissionTabStories(payload))
    // getAllPreviousStoriesFunc(payload);
  };

  const closeModal =() =>{
    setAddNewStoryFlag(false)
  }
  return (
    <>
      <Tabs tabPosition="left" className="custom-tab-width">
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord
                key={"STORIES"}
                text={"STORIES"}
                lang={selectedInterfaceLanguage}
              />
            )
          }
          key="STORIES"
        >
          <div style={{ marginBottom: "3vh" }}>
          <Row style={{ margin: "0px 10px 10px 10px" }}>
        <Col
          style={{ marginTop: "20px" }}
          lg={{
            span: 10,
          }}
          xs={{
            span: 24,
          }}
        >
          <Row>
            <Col>
              {" "}
              <Text className="userName">{selectedInterfaceLanguage && (
                <TranslateWord
                key={"PREVIOUS_STORIES"}
                text={"PREVIOUS_STORIES"}
                lang={selectedInterfaceLanguage}

              />
               )}</Text>
            </Col>
            <Col style={iconStyle}>
              <AiOutlineSearch
                hidden={searchIconHide}
                onClick={showInput}
                style={{ cursor: "pointer" }}
                size={20}
              />
              <div>
                <Input
                  //  prefix={<SearchOutlined />}
                  // allowClear
                  placeholder="search text then press enter"
                  hidden={searchInputHide}
                  onChange={onSearch}
                  value={searchText}
                  onKeyUp={handleEnter}
                  style={{
                    width: 215,
                    marginLeft: "4px",
                  }}
                />
                {searchIconHide ? (
                  <AiOutlineCloseCircle
                    title="Close search box"
                    onClick={closeInputBox}
                    style={{ color: "red", cursor: "pointer" }}
                    size={20}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <span style={{ marginRight: "5px" }}>
            <Button
              onClick={() => handleDashboardData("Draft")}
              style={{
                position: "relative",
                top: "20px",
                cursor: "pointer",
                color: type === "Draft" ? "white" : "#616161",
                backgroundColor: type === "Draft" ? "#CC0001" : "white",
                border: type === "Draft" ? "1px solid white" : "white",
              }}
              ghost
            >
               {selectedInterfaceLanguage && (
                <TranslateWord
                key={"DRAFT"}
                text={"DRAFT"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Button>
          </span>
          <span style={{ marginRight: "5px" }}>
            <Button
              onClick={() => handleDashboardData("Waiting for Approval")}
              style={{
                position: "relative",
                top: "20px",
                cursor: "pointer",
                color: type === "Waiting for Approval" ? "white" : "#616161",
                backgroundColor:
                  type === "Waiting for Approval" ? "#CC0001" : "white",
                border:
                  type === "Waiting for Approval" ? "1px solid white" : "white",
              }}
              ghost
            >
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"WAITING_FOR_APPROVAL"}
                text={"WAITING_FOR_APPROVAL"}
                lang={selectedInterfaceLanguage}

              />
               )}
              {/* Waiting for approval */}
            </Button>
          </span>
          <span style={{ marginRight: "5px" }}>
            <Button
              onClick={() => handleDashboardData("Published")}
              style={{
                position: "relative",
                top: "20px",
                cursor: "pointer",
                color: type === "Published" ? "white" : "#616161",
                backgroundColor: type === "Published" ? "#CC0001" : "white",
                border: type === "Published" ? "1px solid white" : "white",
              }}
              ghost
            >
               {selectedInterfaceLanguage && (
                <TranslateWord
                key={"PUBLISHED"}
                text={"PUBLISHED"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Button>
          </span>
          <span style={{ marginRight: "5px" }}>
            <Button
              onClick={() => handleDashboardData("Returned")}
              style={{
                position: "relative",
                top: "20px",
                cursor: "pointer",
                color: type === "Returned" ? "white" : "#616161",
                backgroundColor: type === "Returned" ? "#CC0001" : "white",
                border: type === "Returned" ? "1px solid white" : "white",
              }}
              ghost
            >
               {selectedInterfaceLanguage && (
                <TranslateWord
                key={"RETURNED"}
                text={"RETURNED"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Button>
          </span>
          <span style={{ marginRight: "5px" }}>
            <Button
              onClick={() => handleDashboardData("Rejected")}
              style={{
                position: "relative",
                top: "20px",
                cursor: "pointer",
                color: type === "Rejected" ? "white" : "#616161",
                backgroundColor: type === "Rejected" ? "#CC0001" : "white",
                border: type === "Rejected" ? "1px solid white" : "white",
              }}
              ghost
            >
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"REJECTED"}
                text={"REJECTED"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Button>
          </span>
          {/* <span style={{cursor:'pointer'}} onClick={openNewStoryModel}>       
             <PlusCircleOutlined style={{ fontSize: '30px', color: '#08c', position:'relative',top:'2vh',left:'3vw'}}/>

        </span> */}
        </Col>
      </Row>
      <Row>
      {submissionStoriesData?.data?.map((story) => {
              return (
                <>
                <Col style={{ marginBottom: "5px"}} span={6}>
                  <Card
                    className="customCard"
                    hoverable
                    style={{ width: "19vw" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>        {selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_ID"}
                text={"STORY_ID"}
                lang={selectedInterfaceLanguage}

              />
               )} : {story.story_id}</div>
                        <div>
                          {story?.story_status?.story_status_name ===
                            "Draft" && (
                            <Badge color="#616161" placement="start" />
                          )}
                          {story?.story_status?.story_status_name ===
                            "Edit and Resend" && (
                            <Badge color="#FAAC40" placement="start" />
                          )}
                          {story?.story_status?.story_status_name ===
                            "Rejected" && (
                            <Badge color="#CC0001" placement="start" />
                          )}
                          {story?.story_status?.story_status_name ===
                            "Waiting for Approval" && (
                            <Badge color="#f50" placement="start" />
                          )}
                          {story?.story_status?.story_status_name ===
                            "Published" && (
                            <Badge color="#00E676" placement="start" />
                          )}
                          {/* {story?.story_status?.story_status_name === 'Published' &&  <Badge color="#00E676" text={story?.story_status?.story_status_name} placement='start' />} */}
                        </div>
                      </div>
                      <div style={{ height: "9vh" }}>
                        <Text strong>
                          {story?.title.length < 55
                            ? story?.title
                            : story?.title.substring(0, 55) + " ..."}
                        </Text>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {moment(story.create_story_date_time).format(
                            "MMM DD"
                          )}
                        </div>
                        {story?.story_status?.story_status_name ===
                          "Published" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  color: "blue",
                                  fontSize: "13px",
                                  marginRight: "5px",
                                }}
                              >
                                <Tooltip title="Comming soon">                                 
                                {selectedInterfaceLanguage && (
                <TranslateWord
                key={"RELATED_STORY"}
                text={"RELATED_STORY"}
                lang={selectedInterfaceLanguage}

              />
               )}
                                </Tooltip>
                              </div>
                              <div style={{ marginRight: "5px" }}>
                                <img
                                  src={sharingIcon}
                                  width={18}
                                  height={18}
                                />
                              </div>
                              <div style={{ marginRight: "5px" }}>
                                <img
                                  src={commentsIcon}
                                  width={18}
                                  height={18}
                                />{" "}
                                <span
                                  style={{
                                    position: "relative",
                                    top: "-0.6vh",
                                  }}
                                >
                                  0
                                </span>
                              </div>
                              <div style={{ marginRight: "5px" }}>
                                <img
                                  src={viewIcon}
                                  width={18}
                                  height={18}
                                />{" "}
                                <span
                                  style={{
                                    position: "relative",
                                    top: "-0.6vh",
                                  }}
                                >
                                  0
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {story?.story_status?.story_status_name ===
                          "Draft" && (
                          <>
                            <div>
                              <img
                                src={editIcon}
                                onClick={() => openStoryModalPopup(story)}
                                width={18}
                                height={18}
                              />
                            </div>
                          </>
                        )}

                        {story?.story_status?.story_status_name ===
                          "Edit and Resend" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  color: "blue",
                                  fontSize: "13px",
                                  marginRight: "5px",
                                }}
                                onClick={() => {
                                  hangleRejectReason(
                                    story,
                                    "Reason for Return"
                                  );
                                }}
                              >
                                {selectedInterfaceLanguage && (
                <TranslateWord
                key={"REASON_FOR_RETURNED"}
                text={"REASON_FOR_RETURNED"}
                lang={selectedInterfaceLanguage}

              />
               )}
                              </div>
                              <div
                                style={{
                                  color: "blue",
                                  fontSize: "13px",
                                  marginRight: "5px",
                                }}
                              ></div>
                              <div style={{ marginRight: "5px" }}>
                                <img
                                  src={editIcon}
                                  width={18}
                                  height={18}
                                  onClick={() => openStoryModalPopup(story)}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {story?.story_status?.story_status_name ===
                          "Rejected" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  color: "blue",
                                  fontSize: "13px",
                                  marginRight: "5px",
                                }}
                                onClick={() => {
                                  hangleRejectReason(
                                    story,
                                    "Reason for Rejection"
                                  );
                                }}
                              >
                                      {selectedInterfaceLanguage && (
                <TranslateWord
                key={"REASON_FOR_REJECTED"}
                text={"REASON_FOR_REJECTED"}
                lang={selectedInterfaceLanguage}

              />
               )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>
              </>
              );
            })}
      </Row>
           
          </div>
          <div>
          <Pagination
                className="pull-right"
                current={currentPageForStories}
                pageSize={12}
                total={submissionStoriesData?.totalElements}
                showSizeChanger={false}
                onChange={handlePageChangeForStories}
              />
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord
                key={"PETITIONS"}
                text={"PETITIONS"}
                lang={selectedInterfaceLanguage}
              />
            )
          }
          key="PETITIONS"
        >
           <div style={{ marginBottom: "3vh" }}>
            {submissionPetitionsData?.data?.map((petition) => {
              return (
                <div
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "8px",
                    width: "65vw",
                    margin: "5px 0px",
                    padding:'10px'
                  }}
                >
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div><span>{ selectedInterfaceLanguage && (
              <TranslateWord
                key={"PETITION_ID"}
                text={"PETITION_ID"}
                lang={selectedInterfaceLanguage}
              />
            )
          } : </span>{petition.petetion_details_id}</div>
                    <div>{petition?.petition_status?.status_description}</div>
                  </div>
                  <div>{petition.title}</div>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div>{moment(petition.create_story_date_time).format("MMM DD")}</div>
                  {petition?.petition_status?.status_description === 'Posted for Signatures' && <>
                  <div style={{display:'flex',justifyContent:'space-around'}}>
                    <div style={{color:'blue',fontSize:'13px',marginRight:'5px'}}>{
                      <TranslateWord
                      key={`UPDATE`}
                        text={`UPDATE`}
                        lang={selectedInterfaceLanguage}                        
                      />
                    }</div>
                    <div style={{marginRight:'5px'}}><img src={sharingIcon} width={18} height={18}/></div>
                    <div style={{marginRight:'5px'}}><img src={commentsIcon} width={18} height={18}/> <span>{petition.totalComments}</span></div>
                    <div style={{marginRight:'5px'}}><img src={groupUsersIcon} width={18} height={18}/> <span>{petition.totalSignatures}</span></div>
                  </div>
                  </>} 
                  {petition?.petition_status?.status_description === 'Sent to Authority Concerned' && <>
                  <div style={{display:'flex',justifyContent:'space-around'}}>                    
                    <div style={{marginRight:'5px'}}><img src={sharingIcon} width={18} height={18}/></div>
                    <div style={{marginRight:'5px'}}><img src={commentsIcon} width={18} height={18}/> <span>{petition.totalComments}</span></div>
                    <div style={{marginRight:'5px'}}><img src={viewIcon} width={18} height={18}/> <span>{petition.totalSignatures}</span></div>
                  </div>
                  </>} 
                  {petition?.petition_status?.status_description === 'Draft'  && <>
                  <div><img src={editIcon} width={18} height={18}/></div>
                  </>} 

                  
                  {/* {petition?.petition_status?.status_description === 'Returned' && <>
                  <div style={{display:'flex',justifyContent:'space-around'}}>
                    <div style={{color:'blue',fontSize:'13px',marginRight:'5px'}}>Reason for Returned</div>                    
                    <div style={{marginRight:'5px'}}><img src={editIcon} width={18} height={18}/> <span>0</span></div>
                  </div>
                  </>}  */}
                

                  </div>                 
                </div>
              );
            })}
          </div>
          <div>
          <Pagination
                className="pull-right"
                current={currentPageForPetitions}
                pageSize={cardsPerPage}
                total={submissionPetitionsData?.totalElements}
                showSizeChanger={false}
                onChange={handlePageChangeForPetitions}
              />
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedInterfaceLanguage && (
              <TranslateWord
                key={"GRIEVANCES"}
                text={"GRIEVANCES"}
                lang={selectedInterfaceLanguage}
              />
            )
          }
          key="GRIEVANCES"
        >
         <div style={{ marginBottom: "3vh" }}>
            {submissionGrievancesData?.data?.map((grievance) => {
              return (
                <div
                  style={{
                    border: "1px solid black",
                    borderRadius: "8px",
                    width: "65vw",
                    margin: "5px 0px",
                    padding:'10px'
                  }}
                >
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div><span>{ selectedInterfaceLanguage && (
              <TranslateWord
                key={"GRIEVANCE_ID"}
                text={"GRIEVANCE_ID"}
                lang={selectedInterfaceLanguage}
              />
            )
          } : </span>{grievance.grievance_details_id}</div>
                    <div>{grievance?.grievance_status?.status_description}</div>
                  </div>
                  <div>{grievance.title}</div>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div>{moment(grievance.create_story_date_time).format("MMM DD")}</div>
                  {/* {grievance?.grievance_status?.status_description === 'Posted for Signatures' && <>
                  <div style={{display:'flex',justifyContent:'space-around'}}>
                    <div style={{color:'blue',fontSize:'13px',marginRight:'5px'}}>Update</div>
                    <div style={{marginRight:'5px'}}><img src={sharingIcon} width={18} height={18}/></div>
                    <div style={{marginRight:'5px'}}><img src={commentsIcon} width={18} height={18}/> <span>{grievance.totalComments}</span></div>
                    <div style={{marginRight:'5px'}}><img src={groupUsersIcon} width={18} height={18}/> <span>{grievance.totalSignatures}</span></div>
                  </div>
                  </>}  */}
                  {/* {grievance?.grievance_status?.status_description === 'Sent to Authority Concerned' && <>
                  <div style={{display:'flex',justifyContent:'space-around'}}>                    
                    <div style={{marginRight:'5px'}}><img src={sharingIcon} width={18} height={18}/></div>
                    <div style={{marginRight:'5px'}}><img src={commentsIcon} width={18} height={18}/> <span>{grievance.totalComments}</span></div>
                    <div style={{marginRight:'5px'}}><img src={groupUsersIcon} width={18} height={18}/> <span>{grievance.totalSignatures}</span></div>
                  </div>
                  </>}  */}
                  {grievance?.grievance_status?.status_description === 'Draft'  && <>
                  <div><img src={editIcon} width={18} height={18}/></div>
                  </>} 

                  
                  {/* {petition?.petition_status?.status_description === 'Returned' && <>
                  <div style={{display:'flex',justifyContent:'space-around'}}>
                    <div style={{color:'blue',fontSize:'13px',marginRight:'5px'}}>Reason for Returned</div>                    
                    <div style={{marginRight:'5px'}}><img src={editIcon} width={18} height={18}/> <span>0</span></div>
                  </div>
                  </>}  */}
                

                  </div>                 
                </div>
              );
            })}
          </div>
          <div>
          <Pagination
                className="pull-right"
                current={currentPageForGrievances}
                pageSize={cardsPerPage}
                total={submissionGrievancesData?.totalElements}
                showSizeChanger={false}
                onChange={handlePageChangeForGrievances}
              />
          </div>
        </TabPane>
      
      </Tabs>
      {rejectReasonFlag && <>
      <ReasonModel openFlag ={rejectReasonFlag} selectedStory={selectedStory} reasonTitle={reasonTitle} handleModelFlag={closeReasonModel}/>
      </>}
      {addNewStoryFlag && (
        <>
          <AddNewStory
            openFlag={addNewStoryFlag}
            story={storyData}
            closeModelFlag={closeModal}
          />
        </>
      )}
    </>
  );
};

export default SubmissionsTab;
