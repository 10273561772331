import React, { useEffect, useState } from 'react';
import BreakingNewSub from './BreakingNewSub';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import noImg from '../../../imgaes/noImg.svg'
import noImgJPG from '../../../imgaes/noImgJPG.jpg'


import { Skeleton } from 'antd';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { teluguFontStyleHeading } from '../../../Translation/Words';
import TranslateWord from '../../sharedComponents/TranslateWord';


function BreakingNewsMain() {   
    const {data:breakingNewsData,status:breakingLoading,error} =useSelector(state => state.breakingList )
    const {selectedInterfaceLanguage} =useSelector(state => state.interfaceLanguageList )

   
    if(breakingLoading === 'loading'){
        return <div> <Skeleton
        active
        title={false}
        paragraph={{ rows: 2, width: [1500, 1500] }}
      /></div>
   }
    const options = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        animatout: 'slidOutUp',
        nav: true,
        dots: false,
        margin: 0,
        autoplayHoverPause: true,
        responsive: {
            1100: {
                items: 1,
            },
            724: {
                items: 1,
            },
            500: {
                items: 1,
            },
            370: {
                items: 1,
                innerWidth: "100%",
                outerWidth: "100%",
            },
        }
    }

    // const breakingNewsArray = ['Breaking', 'Breaking News']
    // const newsData = breakingNewsData?.data?.filter(record =>
    //     breakingNewsArray.some(trend => record.tags.includes(trend))  
    // ).slice(0, 6);
   

    return (
        <>
         {breakingNewsData?.data?.length > 0 && <>
            <section className="section-space-bottom breaking_new">
                <div className="container">
                    <div className="heading headig_red">                        
                        <h1 style={selectedInterfaceLanguage?.name === 'తెలుగు' ? teluguFontStyleHeading :{}}>{selectedInterfaceLanguage && <TranslateWord key={'BREAKINGNEWS'} text={"BREAKINGNEWS"} headingStyle={true} lang={selectedInterfaceLanguage}/> }</h1>
                    
                    </div>
                  
                   <div className="row no-gutters margin_min_25">
                        <div className="col-md-6 col-lg-6">
                            <div className="no-gutters slider_all_p breaking_slider">
                                <OwlCarousel className='owl-theme' {...options}>
                                    {breakingNewsData?.data?.slice(0, 6)?.map((record) => {
                                        return <>
                                            <div className='item breaking_news_slider' style={{cursor:'pointer'}} >
                                                <div className="img-overlay-70 img-scale-animate">
                                                    <Link to={`/about?param1=${record?.id}&category=${'Breaking News'}`}>
                                                        {record?.image_urls?.[0] ? (
                                                            <img src={record?.image_urls?.[0]} alt="news" className="img-fluid width-100" />
                                                        ) : (
                                                            record?.video_url ?<> <ReactPlayer width='645px' height='401px' style={{position: 'relative',
                                                            zIndex: 9000,
                                                            width: '350px',
                                                            height: '401px !important',
                                                            backgroundColor:'black'
                                                            }}  muted={true} controls={true} url={record?.video_url} />
                                                            </> :
                                                             <>
                                                              <img
                                                             src={noImgJPG}
                                                              alt="No-Image"
                                                             />
                                                             </>
                                                        )}

                                                    </Link>
                                                </div>
                                                <div className="mask-content-lg">
                                                    <h2 className="title-medium-light size-lg">
                                                        <Link to={`/about?param1=${record?.id}&category=${'Breaking News'}`}> {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}  </Link>
                                                    </h2>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <BreakingNewSub breakingNewsData = {breakingNewsData?.data}/>
                        </div>
                    </div>
                    <hr></hr>
                 
                   
                </div>
            </section>
         </>}
          
        </>
    )
}

export default BreakingNewsMain
