import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'antd';
import { Link } from 'react-router-dom';
// import {
//     useGetAllActiveCategoryQuery,            
// } from '../../Services/CategoryAPI';


function Popup() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLinks, setSelectedLinks] = useState({});
    const [trendingData, setTrendingData] = useState([]);
    const [subcatData, setSubcatData] = useState({});
    const [selectedCounts, setSelectedCounts] = useState({});

    // const [saveTrendingAPI] = useGetAllActiveCategoryQuery();

    // const getTrendingDataFunc = async () => {
    //     const payload = {
    //         news_feed_lang_ids: [
    //             60, 70, 80
    //         ]
    //     }
    //     if (payload) {
    //         try {
    //             await saveTrendingAPI(payload)
    //                 .unwrap()
    //                 .then((payload) => {
    //                     setTrendingData(payload)
    //                     console.log("payload", payload);
    //                 })

    //         } catch (err) {
    //             console.log("Some thing went wrong");
    //         }
    //     }
    // }
    // useEffect(() => {
    //     getTrendingDataFunc();
    // }, []);

    const newsData = trendingData?.data?.filter((record) => record && record?.category_name);

    const showModal = (ip_category_id) => {
        setIsModalOpen(true);
        setSubcatData(ip_category_id);
    };

    const selectedCategory = trendingData?.data?.find((cat) => cat.category_id === subcatData);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleLinkClick = (categoryId, subCategoryId) => {
        setSelectedLinks((prevSelected) => {
            const categorySelected = prevSelected[categoryId] || {};
            const isSelected = categorySelected[subCategoryId];

            const updatedCategorySelected = {
                ...categorySelected,
                [subCategoryId]: !isSelected,
            };

            return {
                ...prevSelected,
                [categoryId]: updatedCategorySelected,
            };
        });
    };

    const handleSelectAll = (categoryId) => {
        const allSubcategoryIds = selectedCategory?.str_sub_categories_snos || [];
        const categorySelected = selectedLinks[categoryId] || {};
        const allSelected = Object.keys(categorySelected).length === allSubcategoryIds.length;

        const updatedCategorySelected = {};
        if (!allSelected) {
            allSubcategoryIds.forEach((id) => {
                updatedCategorySelected[id] = true;
            });
        }

        setSelectedLinks((prevSelected) => ({
            ...prevSelected,
            [categoryId]: updatedCategorySelected,
        }));
    };


    useEffect(() => {
        // Update selected counts whenever selected links change
        const updatedSelectedCounts = {};
        Object.keys(selectedLinks).forEach((categoryId) => {
            const categorySelected = selectedLinks[categoryId];
            const count = Object.values(categorySelected).filter(Boolean).length;
            updatedSelectedCounts[categoryId] = count;
        });
        setSelectedCounts(updatedSelectedCounts);
    }, [selectedLinks]);
 

    //console.log({ grievanceCategoryData })



    return (
        <>
            <section className="bg-body section-space-less30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30">
                            <div className="login_page">
                                <a href="/" className="img-fluid">
                                    <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                                </a>
                                <h1>What Interests You?</h1>


                                <div className="lang_btn what_interested">
                                    {newsData &&
                                        newsData.map((record) => (
                                            <Button
                                                key={record.category_id}
                                                type="primary"
                                                onClick={() => showModal(record.category_id)}
                                            >
                                                {record.category_name}{' '}
                                                {selectedCounts[record.category_id] > 0 && (
                                                    <span className="display_numb">
                                                        {selectedCounts[record.category_id]}
                                                    </span>
                                                )}
                                            </Button>
                                        ))}
                                </div>

                                <Modal
                                    className="sub_what_interested"
                                    title={selectedCategory?.category_name}
                                    visible={isModalOpen}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                >
                                    {subcatData &&
                                        selectedCategory?.sub_categories_names?.map((eachsub) => (
                                            eachsub && (
                                                <Link
                                                    key={eachsub.sub_category_id}
                                                    style={{
                                                        color: selectedLinks[selectedCategory.category_id]?.[eachsub.sub_category_id]
                                                            ? '#CC0001'
                                                            : 'inherit',
                                                    }}
                                                    onClick={() => handleLinkClick(selectedCategory.category_id, eachsub.sub_category_id)}
                                                >
                                                    {eachsub.sub_category_name}
                                                </Link>
                                            )
                                        ))}
                                    <Button className="select_deselect_btn" onClick={() => handleSelectAll(selectedCategory.category_id)}>
                                        Select All
                                    </Button>
                                </Modal>
                                <div className='prev_next_button mt-50'>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className='news_feed_btn_prv'>
                                            Previous
                                        </Button>
                                        <Link to='/ForYou'>
                                            <Button type="primary" htmlType="submit" className='news_feed_btn_next active'>
                                                Next
                                            </Button>
                                        </Link>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Popup;
