import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoForm from '../InnerPages/InfoForm';

function InvestorRelations() {
    return (
        <>          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Investor Relations</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/investorrelations.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default InvestorRelations
