import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  selectedfeedLanguage: [],
};

const feedLanguageSlice = createSlice({
  name: "interfaceLanguages",
  initialState,
  reducers: {
    selectedfeedAction: (state, action) => {
      state.selectedfeedLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {    
    builder.addCase(getAllFeedLanguages.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.status = "idle";
    });
  },
});

export const getAllFeedLanguages = createAsyncThunk(
  "feedLanguage/get",
  async () => {
    const data = await fetch(`${BaseUrl}/newsfeedlanguage/available`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);

export const { selectedfeedAction } = feedLanguageSlice.actions;
export default feedLanguageSlice.reducer;
