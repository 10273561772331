import React from 'react';
import { Link } from 'react-router-dom';
import MobileLogin from './MobileLogin';
import GmailLogin from './GmailLogin';
import { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';


function LoginLanding() {
  const clientId = '706815682566-r81qkqvjmdiegaqt05m32iticsgl02sk.apps.googleusercontent.com';
  const [showLoginButton, setShowLoginButton] = useState(true);
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const onLoginSuccess = (res) => {
    console.log('Login Success:', res.profileObj);
    setShowLoginButton(false);
    setShowLogoutButton(true);
  }
  const onFailureSuccess = (res) => {
    console.log('Login Failed:', res);
  }
  const onSignoutSuccess = (res) => {
    alert("You have been signed out successfully");
    setShowLoginButton(true);
    setShowLogoutButton(false);
  }
  const CustomGoogleLoginButton = ({ onClick }) => (
    <div onClick={onClick} className='google_btn'>
      <img
        src="img/imw_img/login_g.png"
        alt="google_icon"
        className="img-fluid"
        style={{ cursor: 'pointer', marginRight: '10px', width: '36px' }}
      />
    </div>
  );
  return (
    <>
      <section className="bg-body section-space-less30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-30">
              <div className="login_page">
                <a href="/" className="img-fluid">
                  <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                </a>
                <h2>currently logged in</h2>
                <h1>Sign in with</h1>
                <div className="lang_options">
                  <Link to='/MobileLogin'><img src="img/imw_img/login_m.png" alt="login_btn" className="img-fluid" /></Link>
                  <GoogleLogin

                    clientId={clientId}
                    onSuccess={onLoginSuccess}
                    onFailure={onFailureSuccess}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                      <CustomGoogleLoginButton onClick={renderProps.onClick} />
                    )}
                  />

                  <Link to=''><img src="img/imw_img/login_f.png" alt="login_btn" className="img-fluid" /></Link>
                  <Link to=''><img src="img/imw_img/login_ms.png" alt="login_btn" className="img-fluid" /></Link>
                </div>
                <h3>For Staff/Volunteer sign in <a href="">Click Here</a></h3>
                <p>By continuing you accept the <a href="">Terms of Use and Privacy Policy</a></p>
                <p><strong><Link to='/ForYou'>SKIP</Link></strong></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LoginLanding
