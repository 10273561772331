import React from 'react';
import InfoForm from '../InnerPages/InfoForm';

function Classifieds() {
    return (
        <>          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Classifieds</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/classifieds.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1 contact_us_page">
                                <p>Politikos Classifieds advertising will give you an opportunity to showcase your brand to readers across segments. Your featured classified advertisement will have extensive reach and attention from the target audiences from across various locations in the country.</p>
                                <p>Politikos classifieds are affordable and rates are designed to help people from all walks of life advertise and fulfil their requirements. Politikos allow you to reach a wide range of audience or readers across the nation. You can easily target your audience through Politikos classifieds based on gender, location, age group, profession, etc so that your classifieds campaigns receive the maximum views of your booking.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
        </>
    )
}

export default Classifieds
