import React from 'react';
import { Link } from 'react-router-dom';

function Policies() {
    return (
        <>         
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Policies</h1></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="news-details-layout1 sub_page_content side_menu">
                                <ol type="1">
                                    <li><Link to="/Policies">Code Of Ethics for Digital News Websites</Link></li>
                                    <li><Link to="/policies_link2">Politikos Editorial Ethics Handbook </Link></li>
                                    <li><Link to="/policies_link3">Copyright Policy Guidelines </Link></li>
                                    <li className='active'><Link to="/policies_link4">Privacy & Cookie Policy </Link></li>
                                </ol>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="col-lg-9 col-md-9 mb-30">
                            <div class="news-details-layout1 sub_page_content_policies link4 border_right">
                                <h1>Privacy & Cookie Policy</h1>
                                <h2>1. What's in this policy?</h2>
                                <p>This policy informs you:</p>
                                <ul class="sub_contenst_ul">
                                    <li>What kind of information we collect from you</li>
                                    <li>How we use the information we collected</li>
                                    <li>When we use your details to contact you</li>
                                    <li>What information of yours do we share with others</li>
                                    <li>Your choices about the personal information you provide to us</li>
                                </ul>
                                <h2>2.1	 What does this policy cover?</h2>
                                <p>This policy explains the services that the IMW offers. Sometimes this policy also covers services provided by parts of IMW that operate as separate firms/ companies.</p>
                                <h2>2.2	What is not covered in this policy?</h2>
                                <p>Our services sometimes link to services operated by other organization's services. Those companies/ firms have their privacy and cookie policies, so note that the information you provide them will follow their rules and not IMW.</p>
                                <p>Sometimes, you will access our services from other platforms, such as YouTube pages or the app on your connected device. Check their policy so you know how they will use your data.</p>
                                <h2>3.1 How do you protect my personal information?</h2>
                                <p>Our team is committed to keeping your information safe and secure. And we designed our services with your safety in mind. Our dedicated teams look after your data. However, no service can guarantee absolute security. If you have any worries regarding your IMW account or personal information at risk, for example, if anyone could have found your password, please contact IMW team immediately.</p>
                                <h2>3.2	Where do we store information?</h2>
                                <p>Some companies that provide services to us operate their services from outside India. We take utmost care and only permit that to happen if we are satisfied with their levels of security. Please note that when you give us personal details, it could be transferred, stored or processed in countries even outside India.</p>
                                <h2>3.2.1 What types of personal information does the IMW collect about me?</h2>
                                <p>We'll give you details about what sort of information and why we need your personal information before we begin processing it unless it's obvious.</p>
                                <h2>4.1. Information that we ask you.</h2>
                                <p>Depending on what you're doing, we may ask for your name, contact information, date of birth, and financial details. For instance, if you want to participate in a political program, we may ask you about your political views.</p>
                                <p>When you register for an IMW account or update your details, we may ask for some personal information, like your email address, age, etc.</p>
                                <h2>4.2. Device information</h2>
                                <p>Devices are lots of things like:</p>
                                <ul class="sub_contenst_ul">
                                    <li>your mobile</li>
                                    <li>your computer</li>
                                    <li>your tablet</li>
                                    <li>your TV</li>
                                    <li>your voice-enabled device</li>
                                </ul>
                                <p>We automatically gather some technical data from these devices and browsers even when you're not signed in to an IMW account. This might include:</p>
                                <ul class="sub_contenst_ul">
                                    <li>device ID</li>
                                    <li>IP (internet protocol) address</li>
                                    <li>app ID</li>
                                    <li>vendor ID</li>
                                    <li>advertising ID</li>
                                </ul>
                                <h2>4.3. Location Information</h2>
                                <p>When you use our services, we collect data about your location. This enables us to ascertain whether you're somewhere where are services are available, guides us to make sure we are fulfilling our obligations and provides extra features whilst using our services, such as forecasting the weather of your location. Your location can be decided at a town or city level by:</p>
                                <p>Regardless of where you are, using our services allows us to</p>
                                <ul class="sub_contenst_ul">
                                    <li>GPS</li>
                                    <li>IP address</li>
                                    <li>data about nearby cell towers and Wi-Fi access point information.</li>
                                </ul>
                                <p>What type of location information (for example, country, city or Town) is gathered depends on the service you are using, the gadget you're using (for example, Apple or Android) and your gadget settings (whether permissions are enabled or disabled). You can enable and disable your permissions anytime in your device's settings.</p>
                                <h2>4.4. Information on your activities outside the IMW when you talk about us</h2>
                                <p>Like if you've cited us in a Twitter post, we will collect your Twitter handle.</p>
                                <h2>4.5. Information on how you use IMW services.</h2>
                                <p>Like the articles you read and the shows you watch.</p>
                                <h2>5.	How long will the IMW keep my personal information?</h2>
                                <p>Like the articles you read and the shows you watch.</p>
                                <p><strong>5.1	We only keep </strong> your data for as long as we do the activities we informed you about or have a valid point to keep it.</p>
                                <p><strong>5.2	We think about </strong> what kind of information it is, the amount gathered, how sensitive it might be and any legal requirements</p>
                                <p><strong>5.3	We design our </strong> services so that we don't hold your data any longer than we have to</p>
                                <p><strong>5.4	We may close your </strong> IMW account if you last used it in the last one year. We'll send you an email or text message to inform you that we plan to do this before we remove anything, so please check the text message or email sent by us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >           
        </>
    )
}

export default Policies
