import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';

function Policies() {
    return (
        <>         
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Policies</h1></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="news-details-layout1 sub_page_content side_menu">
                                <ol type="1">
                                    <li><Link to="/Policies">Code Of Ethics for Digital News Websites</Link></li>
                                    <li className='active'><Link to="/policies_link2">Politikos Editorial Ethics Handbook </Link></li>
                                    <li><Link to="/policies_link3">Copyright Policy Guidelines </Link></li>
                                    <li><Link to="/policies_link4">Privacy & Cookie Policy </Link></li>
                                </ol>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="col-lg-9 col-md-9 mb-30">
                            <div class="news-details-layout1 sub_page_content_policies border_right">
                                <h1>Politikos Editorial Ethics Handbook</h1>
                                <h2>Introduction</h2>
                                <p>At Politikos, our mission is to provide accurate, fair, and unbiased news. This handbook reflects our dedication to editorial integrity and the high standards to which we hold ourselves accountable. It is a testament to our commitment to truth, transparency, and the public interest.</p>
                                <h2>Conflict of Interest</h2>
                                <p>Policy Overview:</p>
                                <ul class="sub_contenst_ul">
                                    <li>No gifts, free trips, or preferential treatment.</li>
                                    <li>No payments from political groups or controversial entities.</li>
                                    <li>No freelance assignments or honoraria that pose a conflict of interest.</li>
                                </ul>
                                <h2>Fairness in Reporting</h2>
                                <p>Key Principles:</p>
                                <ul class="sub_contenst_ul">
                                    <li>Completeness: Include all significant facts and crucial information.</li>
                                    <li>Relevance: Ensure all data is pertinent.</li>
                                    <li>Fact check: To avoid misleading content.</li>
                                    <li>Right to Reply: Offer the opportunity to address claims or assertions made.</li>
                                    <li>Prioritisation: What is beneficial for the public over what is popular or sensational?</li>
                                    <li>Decorum: Decency in acknowledging evolving societal norms.</li>
                                    <li>( Always provide warnings for potentially offensive content with news value).</li>
                                </ul>
                                <h2>Distinguishing News from Opinion</h2>
                                <p>Content Categories:  </p>
                                <ul class="sub_contenst_ul">
                                    <li>News: Fact-based reporting without personal viewpoints.</li>
                                    <li>Analysis: Interpretation of news with evidence and projection of future events.</li>
                                    <li>Perspective: Personal narratives provide context to news stories.</li>
                                    <li>Review: Critiques of artistic, literary, or other performing arts.</li>
                                    <li>Curated News: News tailored to serve users' sectoral interests and knowledge.</li>
                                </ul>
                                <h2>Upholding National and Community Interest</h2>
                                <p>Our Responsibility:  </p>
                                <ul class="sub_contenst_ul">
                                    <li>Harmonise national and local community interests.</li>
                                    <li>Disseminate information widely, serving the public good.</li>
                                </ul>
                                <h2>Implementing the Ethics Handbook</h2>
                                <p>To ensure adherence to these principles:</p>
                                <ul class="sub_contenst_ul">
                                    <li>Training: All staff will receive training on the ethics handbook.</li>
                                    <li>Monitoring: Regular reviews will be conducted to ensure compliance.</li>
                                    <li>Transparency: Any conflicts of interest will be disclosed publicly.</li>
                                    <li>Accountability: Violations of these policies will result in disciplinary action.</li>
                                </ul>
                                <h2>Conclusion</h2>
                                <p>Politikos is committed to being a beacon of journalistic integrity, fostering an informed and engaged public. Through our steadfast adherence to these ethical guidelines, we will continue to earn and maintain the trust of our readership.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section >           
        </>
    )
}

export default Policies
