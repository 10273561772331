import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Link } from 'react-router-dom';
import { Grid } from '@splidejs/splide-extension-grid';
import noImg from '../../../imgaes/noImg.svg'
import noImgJPG from '../../../imgaes/noImgJPG.jpg'
import videoPlayIcon from '../../../imgaes/videoPlayIcon.svg'
import ReactPlayer from 'react-player';



function BreakingNewSub({breakingNewsData}) {
    const [trendingData, setTrendingData] = useState();

 
    const imageStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        zIndex:10
      };
      // const newsData = trendingData?.data?.filter(record =>
    //     record.categories.some(category => category.name === 'Breaking News' || 'Politics' || 'Entertainment' || 'Sports' || 'Business' || 'Technology')
    // );
    // const newsData = trendingData?.data?.filter(record =>
    //     record?.tags.some(tags => tags[0])
    // );

    // const breakingNewsArray = ['Breaking', 'Breaking News']
    // const newsData = breakingNewsData?.filter(record =>
    //     breakingNewsArray.some(trend => record.tags.includes(trend))  
    // )
    // const newsData = breakingNewsData
    // const newsData = breakingNewsData?.filter(record =>
    //     record.tags.includes("Breaking")
    // );
   console.log('Braking',breakingNewsData)
    return (
        <>
            <div className="no-gutters slider_breakingnews">
                <div className="tile_slider">
                    <div className="row tab-space5">
                        <div className="slider_breaking_news">
                            <Splide
                                options={{
                                    type: 'loop',
                                    direction: 'ttb',
                                    height: '40rem',
                                    perPage: 4,
                                    perMove: 1,
                                    // grid: {
                                    //     // You can define rows/cols instead of dimensions.
                                    //     dimensions: [[4, 4], [4, 4], [4, 4], [4, 4], [4, 4], [4, 4]],
                                    //     gap: {
                                    //         row: '8px',
                                    //         col: '8px',
                                    //     },
                                    // },

                                    autoScroll: {
                                        pauseOnHover: true,
                                        pauseOnFocus: true,
                                        rewind: false,
                                        speed: 1,
                                    }
                                }}
                              //  extensions={{ AutoScroll, Grid }}
                            >
                                <>

                                    {breakingNewsData?.map((record) => {
                                        return (
                                          <SplideSlide className="breaking_news_sub">
                                            <div className="img-overlay-70 img-scale-animate br_10 breking_radiues">
                                              <div className="mask-content-sm br_10 ">
                                                <h3 className="title-medium-light">
                                                  <Link
                                                    to={`/about?param1=${
                                                      record?.id
                                                    }&category=${"Breaking News"}`}
                                                  >
                                                  {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                                  </Link>
                                                </h3>
                                              </div>
                                              {/* <img src={record?.image_urls?.[0]} alt="news"
                                                        className="img-fluid width-100 br_10 width-107" /> */}
                                              {
                                                record?.image_urls?.[0] ? (
                                                  <img
                                                    src={
                                                      record?.image_urls?.[0]
                                                    }
                                                    alt="news"
                                                    className="img-fluid width-100 breking_radiues"
                                                  />
                                                ) : record?.video_url ? (
                                                  <>
                                                    <Link
                                                      to={`/about?param1=${
                                                        record?.id
                                                      }&category=${"Breaking News"}`}
                                                    >
                                                      {" "}
                                                      <span>
                                                        <img
                                                          style={imageStyle}
                                                          src={videoPlayIcon}
                                                        />
                                                      </span>{" "}
                                                      <video
                                                        style={{
                                                          width: "310px",
                                                          height: "233px",
                                                          position: "relative",
                                                          top: "-29px",
                                                          zIndex: "2",
                                                          backgroundColor:
                                                            "black",
                                                        }}
                                                        preload="metadata"
                                                        src={`${record?.video_url}#t=0.1`}
                                                        muted
                                                      ></video>
                                                    </Link>
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src={noImgJPG}
                                                      alt="No-Image"
                                                    />
                                                  </>
                                                )
                                                // <img src={noImgJPG} alt="No-Image" className="img-fluid width-100 breking_radiues" />
                                              }
                                            </div>
                                          </SplideSlide>
                                        );
                                    })}

                                </>


                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BreakingNewSub
