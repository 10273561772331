import { Divider, Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import StoryDetailsTab from "./StoryDetailsTab";
import StoryOthersTab from "./StoryOthersTab";
import { useSelector } from "react-redux";
import TranslateWord from "../sharedComponents/TranslateWord";

// import storyDetailsIcon from '../../images/storyDetailsIcon.svg'
// import storyOthersIcon from '../../images/storyOthersIcon.svg'

const AddNewStory = ({ openFlag,story ,closeModelFlag}) => {
  const [isModalOpen, setIsModalOpen] = useState(openFlag);
  const [current, setCurrent] = useState(0);
  const [sendStoryId, setSendStoryId] = useState(null);
  const [disabledStepFlag, setDisabledStepFlag] = useState(false);

  const {      
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
 console.log('story ',story)
  const getStoryIdFromChild = (id) =>{
    console.log(id);
    setSendStoryId(id)
  }
  const handleSteps = (data) =>{
    if(data === 'Previous'){
      setDisabledStepFlag(false);    
      setCurrent(0) 
    }
  }
  const getStepFlagValue = (flag) =>{
    setDisabledStepFlag(flag);    
    setCurrent(1)   
  }
  useEffect(() =>{
    localStorage.removeItem('storyDetailsTabData')
    localStorage.removeItem('storyOthersTabData')
    localStorage.removeItem('storyMediaData')
    localStorage.removeItem('feedLang')
    setSendStoryId(null)
  },[])
  const handleCancelClick = () => {
    closeModelFlag(false); 
    localStorage.removeItem('storyDetailsTabData')
    localStorage.removeItem('storyOthersTabData')
    localStorage.removeItem('storyMediaData')
    localStorage.removeItem('feedLang')
    setSendStoryId(null)

  };
  return (
    <>
      <Modal
        // title={`New Story`}
        title={story?.data?.storyDetails?.id ? 'Edit story' :
          `New Story`}
        centered
        visible={isModalOpen}
        // onOk={handleCancelClick}
        onCancel={handleCancelClick}
        width={800}
        bodyStyle={{ maxHeight: "83vh", overflowY: "auto" }}
        footer={null}
        maskClosable={false} 
      >
        <Steps current={current} onChange={setCurrent}>
          <Steps.Step title={selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_DETAILS"}
                text={"STORY_DETAILS"}
                lang={selectedInterfaceLanguage}

              />
               )}disabled={disabledStepFlag}></Steps.Step>
          <Steps.Step title={selectedInterfaceLanguage && (
                <TranslateWord
                key={"OTHER_DETAILS"}
                text={"OTHER_DETAILS"}
                lang={selectedInterfaceLanguage}

              />
               )} disabled={!disabledStepFlag}></Steps.Step>
        </Steps>
        <Divider/>       
      {current === 0 && <StoryDetailsTab  sendStoryId={sendStoryId} sendStoryIdtoParent={getStoryIdFromChild} tabData={story?.data?.storyDetails} sendStepFlag={getStepFlagValue}/>} 
      {current === 1 && <StoryOthersTab  sendStoryId={sendStoryId} tabData={story?.data?.storyOtherDetails} language={story?.data?.storyDetails?.newsfeed_lang_name} closeModal={handleCancelClick} sendStepInfo = {handleSteps}/>} 
        
      </Modal>
    </>
  );
};

export default AddNewStory;
