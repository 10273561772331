import React from 'react';
import { Link } from 'react-router-dom';

function Policies() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Policies</h1></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="news-details-layout1 sub_page_content side_menu">
                                <ol type="1">
                                    <li><Link to="/Policies">Code Of Ethics for Digital News Websites</Link></li>
                                    <li><Link to="/policies_link2">Politikos Editorial Ethics Handbook </Link></li>
                                    <li className='active'><Link to="/policies_link3">Copyright Policy Guidelines </Link></li>
                                    <li><Link to="/policies_link4">Privacy & Cookie Policy </Link></li>
                                </ol>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="col-lg-9 col-md-9 mb-30">
                            <div class="news-details-layout1 sub_page_content_policies  border_right">
                                <h1>Copyright Policy Guidelines</h1>
                                <p>While conscious of our own copyrights/ Intellectual property (IP) rights, the IMW ("we," "us," "our") respects the IP rights of others and requires, as outlined in the Terms and Conditions of Use, that others who use the Internet Media World mobile App or any of our affiliated websites do the same. If you are the copyright holder and believe in good belief that your content has been reproduced on the IMW App/ website in a manner that makes up copyright infringement, please inform our designated copyright officer. Please email copyright@internetmediaworld.in or press the report button on each story.</p>
                                <h2>Copyright Infringement Notification</h2>
                                <p>To file a copyright infringement notification with IMW, you will need to send a mail that includes the following details substantially.</p>
                                <ol type="1">
                                    <li> A physical or digital signature of a person with authority to act on behalf of the owner of an exclusive right that is allegedly violated exclusive right.</li>
                                    <li> Identification of each alleged copyrighted work claimed to have been copyright violated. It is best to mail us the URL of web pages/App screen/ which has copyrighted content.</li>
                                    <li> Identification of the content or any other material that is claimed to be violated or to be the subject of copyright violation activity and that is to be deleted or access to which is to be disabled, and details sufficient to allow us to locate the material. List the URLs in an email to help us locate content quickly.</li>
                                    <li> Information sufficient to permit us to contact the complaining party, such as telephone number, address, and, if available, an email id of the complaining party for correspondence</li>
                                    <li> A detailed statement that the complaining party has a good belief that the use of the content in the way complained of is not authorised by the copyright owner, its agent, or the law.</li>
                                    <li> A detailed statement that the information in the notification is accurate, authentic, and under penalty of perjury, that the complaining party is authorised to speak for the copyright holder of an exclusive right allegedly infringed. </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
           
        </>
    )
}

export default Policies
