import React from 'react'

function Modal() {
  return (
    <>
      <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                     <button type="button" className="close" data-dismiss="modal">&times;</button>
                     <div className="title-login-form">Login</div>
                  </div>
                  <div className="modal-body">
                     <div className="login-form">
                        <form>
                           <label>Username or email address *</label>
                           <input type="text" placeholder="Name or E-mail" />
                           <label>Password *</label>
                           <input type="password" placeholder="Password" />
                           {/* <div className="checkbox checkbox-primary">
                              <input id="checkbox" type="checkbox" checked />
                              <label for="checkbox">Remember Me</label>
                           </div> */}
                           <button type="submit" value="Login">Login</button>
                           <button className="form-cancel" type="submit" value="">Cancel</button>
                           <label className="lost-password">
                           <a href="/">Lost your password?</a>
                           </label>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
    </>
  )
}

export default Modal
