import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';

function Disclaimer() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Disclaimer</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/disclaimer.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>The purpose of the Politikos Apps/websites (the “Website”) is to enhance public access to and use of content (news, photos, videos and all kinds of data) collected and compiled by the Politikos to interested parties on a not-for-profit basis.</p>
                                <p>Users are encouraged to use and share the Data with third parties. Permission to reproduce, republish or translate the content for dissemination or teaching purposes is granted, provided the content is not distributed for profit or commercial advantage.</p>
                                <p>Whilst users may translate the Website in whole or in part, any translations of the Website must include the following text: “The Politikos is not liable or responsible for any errors, omissions or ambiguities in the translated version of the Data portal website”.</p>
                                <p>Any commercial use will require the prior written permission of the Politikos. Politikos reserves the right and, from time to time, to modify, discontinue, temporarily or permanently, the content and any means of accessing or utilising it at its sole discretion.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default Disclaimer
