import React from 'react';

import { Link } from 'react-router-dom';

function Policies() {
    return (
        <>
          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Policies</h1></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 mb-30">
                            <div class="news-details-layout1 sub_page_content side_menu">
                                <ol type="1">
                                    <li className='active'><Link to="/Policies">Code Of Ethics for Digital News Websites</Link></li>
                                    <li><Link to="/policies_link2">Politikos Editorial Ethics Handbook </Link></li>
                                    <li><Link to="/policies_link3">Copyright Policy Guidelines </Link></li>
                                    <li><Link to="/policies_link4">Privacy & Cookie Policy </Link></li>
                                </ol>
                            </div>

                        </div>
                        <hr></hr>
                        <div class="col-lg-9 col-md-9 mb-30">

                            <div class="news-details-layout1 sub_page_content_policies border_right">
                                <h1>Code Of Ethics for Digital News Websites</h1>
                                <p>The Digital News Publishers Association has voluntarily drawn up a Code of Ethics for its members as outlined below, which demonstrates their commitment to responsible digital publishing --even as it, in order to protect our 19 (1) (a) and other Constitutionally mandated freedoms, keeps under review and scrutinizes any developments likely to restrict the gathering and dissemination of news and current affairs or any other content.</p>
                                <p>The object of this Code is to outline high standards, ethics and practices in digital news publishing, and does not constitute any attempt to involve itself in the day to day operations of the publishers --who have complete editorial and content independence.</p>
                                <p>The basic precepts of the Code of Ethics are to maintain the standards of digital publishing as well as protect and maintain the independence of journalists, content entities and publishers.</p>
                                <ol type="1">
                                    <li>Digital news websites follow the laws of the land including the Constitution of India, the over 30 laws relating to the media, relevant provisions of IPC, CrPC as well as the Information Technology Act, 2000, where applicable. </li>
                                    <li>They also diligently adhere to accepted norms of journalistic ethics and practices and maintain the highest standards of professional conduct. There are several layers of these self-regulatory ethics and codes - including as outlined by specific entities as well as rigorous processes in newsrooms at the level of journalists and editors. </li>
                                    <li>Accuracy, Transparency & Fairness Members should eschew publication of inaccurate, baseless or distorted material. Pre-publication verification should be mandatory. Defamation should be avoided. Adherence to applicable laws and rules is necessary. </li>
                                    <li>Right of reply
                                        <ul className='sub_contenst_ul'>
                                            <li>News reports and articles should incorporate comments or version of person or party in respect of whom allegations are carried. If not carried, the person or party's response, if received later, to be incorporated.</li>
                                            <li>If there are developments in the news, and the concerned person or party requests an update, the same must be carried appropriately. Date of update should also appear in the news item published.</li>
                                        </ul>
                                    </li>
                                    <li>Take down, delete or editIf a news report or article is found to contain false or inaccurate information, then on approach by the concerned person or party, providing correct information, identifying himself or herself, providing required documents or material, the portion of the news report or article should be edited or deleted.If an entire news report is found to contain false, inaccurate information, the entire article should be deleted.</li>
                                    <li>Respect Intellectual Property Rights
                                        <ul className='sub_contenst_ul'>
                                            <li>Copyright in text, photographs, plans, diagrams, cartoons, etc must be respected. If copyrighted material is used, then prior permission should be taken and publication must acknowledge moral and ownership rights.</li>
                                            <li>If permission requires payment of fee or royalty, the same must be paid.</li>
                                            <li>TradeMarks and Service Marks of third parties not to be used except with prior permission or if such use constitutes fair use.</li>
                                            <li>In case of infringement of intellectual property - upon receiving any request and after getting the necessary documents, the concerned content should be edited, deleted or take down if necessary.</li>
                                        </ul>
                                    </li>
                                    <li> Care to be taken for reporting sensational matters and crime. Presumption of innocence must be preserved. Comments and speculation on evidence, witness and witness conduct, accused and victim and their respective conduct to be avoided. Such reporting should be based on facts and unbiased.</li>
                                    <li>Special care to be taken while reporting on sexual harassment in workplace, child abuse, rape, where accused or victims are minors, matrimonial, riots and communal disputes / clashes, divorce and custody cases, adoption matters, etc.
                                        <ul className='sub_contenst_ul'>
                                            <li>Care to be taken, to follow Sections 67, 67A and 67B of the Information Technology Act, 2000 where applicable -- which provide for penalties for publishing or transmitting obscene material, sexually explicit material and also material depicting children in sexually explicit acts, in electronic form.</li>
                                        </ul>
                                    </li>
                                    <li>Grievance Redressal MechanismMembers when intermediaries as defined under the Information Technology Act, 2000— follow the grievance redressal mechanism as outlined therein and are cognizant of the liabilities and safe harbor protections under Section 79 of the IT Act 2000. Hence, as relevant, they follow the Information Technology (Intermediary Guidelines) Rules, 2011 including appointing a grievance officer whose contact details are displayed on the website and who acts within 36 hours of receipt of complaint by affected person and redresses the complaint within one month from its receipt.</li>
                                    <li>Training and Awareness ProgramsConduct periodic training and awareness programs with editorial staff about existing laws including Constitution of India, the over 30 laws relating to the media like The Indecent Representation of Women (Prohibition) Act, Copyright Act, Right to Information Act, relevant provisions of Indian Penal Code and CrPC, civil and criminal defamation, IPR, Juvenile justice, POCSO, relevant provisions relating to reporting on rape and molestation, harassment in the work place, caste or gender related crime, domestic violence, etc.
                                        <ul className='sub_contenst_ul'>
                                            <li>Names of victims and details leading to identification of victims or perpetrator, if juvenile or in the workplace, to be strictly avoided.</li>
                                            <li>Photographs of victims, their residence, workplace, etc to be avoided.</li>
                                            <li>Special care and caution must be exercised at all times while reporting matters related to communal or religious disputes/clashes. Such news items shall be published only after a proper verification of facts and should be presented with due caution and restraint, ensuring an environment that is conducive to promoting communal harmony, amity and peace.</li>
                                            <li>Special care in reporting on Courts and judicial matters. Create awareness among editorial staff about legislative privileges and correct reporting of Court hearings, judicial matters, etc. To ensure that versions of victim and accused are covered, without comments thereon.</li>
                                            <li>Respect for privacy especially persons not in public life.</li>
                                        </ul>
                                    </li>

                                </ol>
                            </div>
                            <div class="news-details-layout1 sub_page_content_policies  border_right">
                                <h1>Politikos Editorial Ethics Handbook</h1>
                                <h2>Introduction</h2>
                                <p>At Politikos, our mission is to provide accurate, fair, and unbiased news. This handbook reflects our dedication to editorial integrity and the high standards to which we hold ourselves accountable. It is a testament to our commitment to truth, transparency, and the public interest.</p>
                                <h2>Conflict of Interest</h2>
                                <p>Policy Overview:</p>
                                <ul class="sub_contenst_ul">
                                    <li>No gifts, free trips, or preferential treatment.</li>
                                    <li>No payments from political groups or controversial entities.</li>
                                    <li>No freelance assignments or honoraria that pose a conflict of interest.</li>
                                </ul>
                                <h2>Fairness in Reporting</h2>
                                <p>Key Principles:</p>
                                <ul class="sub_contenst_ul">
                                    <li>Completeness: Include all significant facts and crucial information.</li>
                                    <li>Relevance: Ensure all data is pertinent.</li>
                                    <li>Fact check: To avoid misleading content.</li>
                                    <li>Right to Reply: Offer the opportunity to address claims or assertions made.</li>
                                    <li>Prioritisation: What is beneficial for the public over what is popular or sensational?</li>
                                    <li>Decorum: Decency in acknowledging evolving societal norms.</li>
                                    <li>( Always provide warnings for potentially offensive content with news value).</li>
                                </ul>
                                <h2>Distinguishing News from Opinion</h2>
                                <p>Content Categories:  </p>
                                <ul class="sub_contenst_ul">
                                    <li>News: Fact-based reporting without personal viewpoints.</li>
                                    <li>Analysis: Interpretation of news with evidence and projection of future events.</li>
                                    <li>Perspective: Personal narratives provide context to news stories.</li>
                                    <li>Review: Critiques of artistic, literary, or other performing arts.</li>
                                    <li>Curated News: News tailored to serve users' sectoral interests and knowledge.</li>
                                </ul>
                                <h2>Upholding National and Community Interest</h2>
                                <p>Our Responsibility:  </p>
                                <ul class="sub_contenst_ul">
                                    <li>Harmonise national and local community interests.</li>
                                    <li>Disseminate information widely, serving the public good.</li>
                                </ul>
                                <h2>Implementing the Ethics Handbook</h2>
                                <p>To ensure adherence to these principles:</p>
                                <ul class="sub_contenst_ul">
                                    <li>Training: All staff will receive training on the ethics handbook.</li>
                                    <li>Monitoring: Regular reviews will be conducted to ensure compliance.</li>
                                    <li>Transparency: Any conflicts of interest will be disclosed publicly.</li>
                                    <li>Accountability: Violations of these policies will result in disciplinary action.</li>
                                </ul>
                                <h2>Conclusion</h2>
                                <p>Politikos is committed to being a beacon of journalistic integrity, fostering an informed and engaged public. Through our steadfast adherence to these ethical guidelines, we will continue to earn and maintain the trust of our readership.</p>

                            </div>
                            <div class="news-details-layout1 sub_page_content_policies border_right">
                                <h1>Copyright Policy Guidelines</h1>
                                <p>While conscious of our own copyrights/ Intellectual property (IP) rights, the IMW ("we," "us," "our") respects the IP rights of others and requires, as outlined in the Terms and Conditions of Use, that others who use the Internet Media World mobile App or any of our affiliated websites do the same. If you are the copyright holder and believe in good belief that your content has been reproduced on the IMW App/ website in a manner that makes up copyright infringement, please inform our designated copyright officer. Please email copyright@internetmediaworld.in or press the report button on each story.</p>
                                <h2>Copyright Infringement Notification</h2>
                                <p>To file a copyright infringement notification with IMW, you will need to send a mail that includes the following details substantially.</p>
                                <ol type="1">
                                    <li> A physical or digital signature of a person with authority to act on behalf of the owner of an exclusive right that is allegedly violated exclusive right.</li>
                                    <li> Identification of each alleged copyrighted work claimed to have been copyright violated. It is best to mail us the URL of web pages/App screen/ which has copyrighted content.</li>
                                    <li> Identification of the content or any other material that is claimed to be violated or to be the subject of copyright violation activity and that is to be deleted or access to which is to be disabled, and details sufficient to allow us to locate the material. List the URLs in an email to help us locate content quickly.</li>
                                    <li> Information sufficient to permit us to contact the complaining party, such as telephone number, address, and, if available, an email id of the complaining party for correspondence</li>
                                    <li> A detailed statement that the complaining party has a good belief that the use of the content in the way complained of is not authorised by the copyright owner, its agent, or the law.</li>
                                    <li> A detailed statement that the information in the notification is accurate, authentic, and under penalty of perjury, that the complaining party is authorised to speak for the copyright holder of an exclusive right allegedly infringed. </li>
                                </ol>
                            </div>
                            <div class="news-details-layout1 sub_page_content_policies  link4 border_right">
                                <h1>Privacy & Cookie Policy</h1>
                                <h2>1. What's in this policy?</h2>
                                <p>This policy informs you:</p>
                                <ul class="sub_contenst_ul">
                                    <li>What kind of information we collect from you</li>
                                    <li>How we use the information we collected</li>
                                    <li>When we use your details to contact you</li>
                                    <li>What information of yours do we share with others</li>
                                    <li>Your choices about the personal information you provide to us</li>
                                </ul>
                                <h2>2.1	 What does this policy cover?</h2>
                                <p>This policy explains the services that the IMW offers. Sometimes this policy also covers services provided by parts of IMW that operate as separate firms/ companies.</p>
                                <h2>2.2	What is not covered in this policy?</h2>
                                <p>Our services sometimes link to services operated by other organization's services. Those companies/ firms have their privacy and cookie policies, so note that the information you provide them will follow their rules and not IMW.</p>
                                <p>Sometimes, you will access our services from other platforms, such as YouTube pages or the app on your connected device. Check their policy so you know how they will use your data.</p>
                                <h2>3.1 How do you protect my personal information?</h2>
                                <p>Our team is committed to keeping your information safe and secure. And we designed our services with your safety in mind. Our dedicated teams look after your data. However, no service can guarantee absolute security. If you have any worries regarding your IMW account or personal information at risk, for example, if anyone could have found your password, please contact IMW team immediately.</p>
                                <h2>3.2	Where do we store information?</h2>
                                <p>Some companies that provide services to us operate their services from outside India. We take utmost care and only permit that to happen if we are satisfied with their levels of security. Please note that when you give us personal details, it could be transferred, stored or processed in countries even outside India.</p>
                                <h2>3.2.1 What types of personal information does the IMW collect about me?</h2>
                                <p>We'll give you details about what sort of information and why we need your personal information before we begin processing it unless it's obvious.</p>
                                <h2>4.1. Information that we ask you.</h2>
                                <p>Depending on what you're doing, we may ask for your name, contact information, date of birth, and financial details. For instance, if you want to participate in a political program, we may ask you about your political views.</p>
                                <p>When you register for an IMW account or update your details, we may ask for some personal information, like your email address, age, etc.</p>
                                <h2>4.2. Device information</h2>
                                <p>Devices are lots of things like:</p>
                                <ul class="sub_contenst_ul">
                                    <li>your mobile</li>
                                    <li>your computer</li>
                                    <li>your tablet</li>
                                    <li>your TV</li>
                                    <li>your voice-enabled device</li>
                                </ul>
                                <p>We automatically gather some technical data from these devices and browsers even when you're not signed in to an IMW account. This might include:</p>
                                <ul class="sub_contenst_ul">
                                    <li>device ID</li>
                                    <li>IP (internet protocol) address</li>
                                    <li>app ID</li>
                                    <li>vendor ID</li>
                                    <li>advertising ID</li>
                                </ul>
                                <h2>4.3. Location Information</h2>
                                <p>When you use our services, we collect data about your location. This enables us to ascertain whether you're somewhere where are services are available, guides us to make sure we are fulfilling our obligations and provides extra features whilst using our services, such as forecasting the weather of your location. Your location can be decided at a town or city level by:</p>
                                <p>Regardless of where you are, using our services allows us to</p>
                                <ul class="sub_contenst_ul">
                                    <li>GPS</li>
                                    <li>IP address</li>
                                    <li>data about nearby cell towers and Wi-Fi access point information.</li>
                                </ul>
                                <p>What type of location information (for example, country, city or Town) is gathered depends on the service you are using, the gadget you're using (for example, Apple or Android) and your gadget settings (whether permissions are enabled or disabled). You can enable and disable your permissions anytime in your device's settings.</p>
                                <h2>4.4. Information on your activities outside the IMW when you talk about us</h2>
                                <p>Like if you've cited us in a Twitter post, we will collect your Twitter handle.</p>
                                <h2>4.5. Information on how you use IMW services.</h2>
                                <p>Like the articles you read and the shows you watch.</p>
                                <h2>5.	How long will the IMW keep my personal information?</h2>
                                <p>Like the articles you read and the shows you watch.</p>
                                <p><strong>5.1	We only keep </strong> your data for as long as we do the activities we informed you about or have a valid point to keep it.</p>
                                <p><strong>5.2	We think about </strong> what kind of information it is, the amount gathered, how sensitive it might be and any legal requirements</p>
                                <p><strong>5.3	We design our </strong> services so that we don't hold your data any longer than we have to</p>
                                <p><strong>5.4	We may close your </strong> IMW account if you last used it in the last one year. We'll send you an email or text message to inform you that we plan to do this before we remove anything, so please check the text message or email sent by us.</p>
                            </div>

                        </div>


                    </div>
                </div>
            </section >
           
        </>
    )
}

export default Policies
