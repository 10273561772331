import React, { useState } from 'react';
import { Popover, Tooltip } from 'antd';

function EmojiSelector() {
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const selectSmileyEmoji = () => {
    setChosenEmoji("😊");
  };

  const selectHappyEmoji = () => {
    setChosenEmoji("😃");
  };

  const selectAngryEmoji = () => {
    setChosenEmoji("😠");
  };

  const selectAngryRedEmoji = () => {
    setChosenEmoji("😡");
  };

  const selectLoveEmoji = () => {
    setChosenEmoji("❤️");
  };

  const selectThumbsEmoji = () => {
    setChosenEmoji("👍");
  };

  const content = (
    <div>
      <span role="img" aria-label="Happy Emoji" onClick={selectSmileyEmoji} style={{ fontSize: '24px', cursor: 'pointer' }}>
        😊
      </span>
      <span role="img" aria-label="Happy Emoji" onClick={selectHappyEmoji} style={{ fontSize: '24px', cursor: 'pointer' }}>
        😃
      </span>
      <span role="img" aria-label="Angry Emoji" onClick={selectAngryEmoji} style={{ fontSize: '24px', cursor: 'pointer' }}>
        😠
      </span>
      <span role="img" aria-label="Angry Emoji" onClick={selectAngryRedEmoji} style={{ fontSize: '24px', cursor: 'pointer' }}>
        😡
      </span>
      <span role="img" aria-label="Angry Emoji" onClick={selectLoveEmoji} style={{ fontSize: '24px', cursor: 'pointer' }}>
        ❤️
      </span>
      <span role="img" aria-label="Angry Emoji" onClick={selectThumbsEmoji} style={{ fontSize: '24px', cursor: 'pointer' }}>
        👍
      </span>
    </div>
  );

  return (
    <>
      {/* <Popover content={content} trigger="click"> */}
      <Popover >
        <span className='emoji_data'>
          {chosenEmoji ? (
            <span> {chosenEmoji}</span>
          ) : (
            <span>
              {/* <span role="img" aria-label="Happy Emoji" onClick={selectSmileyEmoji} style={{ cursor: 'pointer' }} title='Coming soon'>
                😊
              </span> */}
               <Tooltip title="Coming soon" color="gray">
              <span role="img" aria-label="Happy Emoji" style={{ cursor: 'pointer' }}>
                😊
              </span>
              </Tooltip>
            </span>
          )}
        </span>
      </Popover>
    </>
  );
}

export default EmojiSelector;
