import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle" 
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {   
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.status = "idle";
    });
  },
});

export const getAllCategories = createAsyncThunk(
  "allcategorires/get",
  async () => {
    const data = await fetch(`${BaseUrl}/category/available`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);

export default categoriesSlice.reducer;
