import { Button, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import settingsIcon from "../../imgaes/settingsIcon.svg";
import FAQsIcon from "../../imgaes/FAQsIcon.svg";
import SettingsModel from "./SettingsModel";
import ProfileAboutYourSelf from "./ProfileAboutYourSelf";
import { useDispatch, useSelector } from "react-redux";
import { getEducationalQualificationData, getUserProfileData, getWorkExperienceData } from "../../Store/ProfileSettingsSlice";
import { getGenderData } from "../../Store/GenderSlice";
import { getMaritalStatusData } from "../../Store/MaritalStatusSlice";
import { useNavigate } from "react-router-dom";
import SubmissionsTab from "./SubmissionsTab";
import TranslateWord from "../sharedComponents/TranslateWord";
import { getJoinVolunteerInfoData } from "../../Store/JoinVolunteerSlice";

const ProfileDB = () => {
  const navigate = useNavigate();
  const [settingsFlag, setSettingsFlag] = useState(false);
  const [type, setType] = useState("About your self");
  const dispatch = useDispatch();
  let user = JSON.parse(localStorage.getItem('LoggedInUserDetails'))
  const { userProfileData } = useSelector((state) => state.profileSettingsList);
  const { selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const getWEandEQ = (interface_lang_id)=>{
    dispatch(getWorkExperienceData(interface_lang_id))
    dispatch(getEducationalQualificationData(interface_lang_id))
  }
  useEffect(() =>{
    dispatch(getUserProfileData(user?.user_id)).then((action) => {
      if (getUserProfileData.fulfilled.match(action)) {
      console.log('getUserProfileData',action)
      getWEandEQ(action?.payload?.data?.selected_interface_language?.interface_lang_id)
      }
    });     
    dispatch(getGenderData())
    dispatch(getMaritalStatusData())
  },[])
  const handleSettingsModel = () => {
    setSettingsFlag(true);
  };
  const navigateToFAQs = () =>{
    navigate("/faqs");
  }
  const handleJoinVolunteer = () =>{
    navigate("/JoinVolunteer");
    let user_id = 239; 
    dispatch(getJoinVolunteerInfoData(user_id)) 
  }
  
  const handleModel = () => {
    setSettingsFlag(false);
  };
  const handleDashboardData = (type) => {
    setType(type);   
  };
  return (
    <>
      <Row justify="space-evenly">
        <Col span={12}>
          <Row justify="space-evenly">
            <Col span={6}>
            {userProfileData?.profile_url ? <img src={userProfileData?.profile_url}  style={{borderRadius:'50%',height:'150px',width:'150px'}}/> :<img src={userProfilePic} />} 
            </Col>
            <Col span={18} style={{position:'relative',top:'3vh'}}>
              <h1 style={{fontWeight:'bold'}}>{userProfileData?.user_profile?.firstname}</h1>
              <h2>{`User (ID#${userProfileData?.user_id})`}</h2>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <h2> {`${userProfileData?.stories} Posts`} </h2>
                <h2> {`${userProfileData?.followers_count} Followers`} </h2>
                <h2> {`${userProfileData?.following_count} Following`} </h2>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={4} >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div onClick={handleSettingsModel}>
              <img src={settingsIcon} />
              Settings
            </div>
            <div onClick={navigateToFAQs}>
              <img src={FAQsIcon} />
              FAQ's
            </div>
          </div>

          {/* <div>
            Volunteers <Button onClick={handleJoinVolunteer}>Join</Button>
          </div> */}
        </Col>
      </Row>
      <div style={{minHeight:'500px'}}>
      <Row justify="space-evenly" style={{marginBottom:'40px'}}>
      <Col span={14}>
          <span style={{ marginRight: "5px" }}>
           
              <Button
                onClick={() => handleDashboardData("About your self")}
                style={{
                  position: "relative",
                  top: "20px",
                  cursor: "pointer",
                  color:type === "About your self" ? "white" : "#616161",
                  backgroundColor:type === "About your self" ? "#01579B" : "white",
                  border: type === "About your self" ? "1px solid white" : "white",
                  borderRadius:'5px'       
                      
                }}
                ghost
              >
                {/* { selectedInterfaceLanguage && (
              <TranslateWord
                key={"STORY_ID"}
                text={"STORY_ID"}
                lang={selectedInterfaceLanguage}
              />
            )
          } */}
                About your self
              </Button>
            
          </span>
          <span style={{ marginRight: "5px" }}>
           
              <Button
                onClick={() => handleDashboardData("Submissions")}
                style={{
                  position: "relative",
                  top: "20px",
                  cursor: "pointer",
                  color:type === "Submissions" ? "white" : "#616161",
                  backgroundColor:type === "Submissions" ? "#01579B" : "white",
                  border: type === "Submissions" ? "1px solid white" : "white",
                  borderRadius:'5px'
                }}
                ghost
              >
                Submissions
              </Button>
            
          </span>
          <span style={{ marginRight: "5px" }}>           
              <Button
                onClick={() => handleDashboardData("Wallet")}
                style={{
                  position: "relative",
                  top: "20px",
                  cursor: "pointer",
                  color:type === "Wallet" ? "white" : "#616161",
                  backgroundColor:type === "Wallet" ? "#01579B" : "white",
                  border: type === "Wallet" ? "1px solid white" : "white",
                  borderRadius:'5px'
                 
                }}
                ghost
              >
                Wallet
              </Button>
            
          </span>
         
         
        
        </Col>
      </Row>
      <Divider />
      <Row justify="space-evenly">
        <Col span={20} style={{position:'relative',top:'-26px'}}>{type === 'About your self' && <ProfileAboutYourSelf/>}</Col>
        <Col span={20} style={{position:'relative',top:'-24px'}}>{type === 'Submissions' && <SubmissionsTab/>}</Col>
      </Row>
      </div>
       
      {settingsFlag && (
        <SettingsModel openFlag={settingsFlag} handleCloseModel={handleModel} />
      )}
    </>
  );
};

export default ProfileDB;
