import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoForm from '../InnerPages/InfoFormLocation';
import { useSelector } from 'react-redux';
import TranslateWord from '../../sharedComponents/TranslateWord';

function AboutUs() {
    const {      
        selectedInterfaceLanguage,
      } = useSelector((state) => state.interfaceLanguageList);
    return (
        <>           
            <section className="bg-body section-space-less30 innerpages">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30">
                            <div className="news-details-layout1">
                                <div className="innerpages_heading"><h1 >
                                    {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'45px',fontWeight:'700'}}>మా గురించి</span>:'About Us'}
                                {/* {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ABOUTUS"}
                               text={"ABOUTUS"}
                               headingStyle={true}
                               lang={selectedInterfaceLanguage}
                             />
                              )} */}
                                    </h1></div>
                                <div className="position-relative mb-30 post_img_content"><img src="img/imw_img/about_us.png" alt="news" className="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30">
                            <div className="news-details-layout1">
                                <p> {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ABOUTUSP1"}
                               text={"ABOUTUSP1"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</p>
                                <p> {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"ABOUTUSP2"}
                               text={"ABOUTUSP2"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</p>
                               
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30 about_bg">
                            <div className="news-details-layout1">
                                <p className='video_title about_video'>Watch our video:</p>
                                <video width="640" height="360" controls>
                                    <source src='../img/imw_img/Politikos_Overview_V006.mp4' type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                            </div>
                        </div>
                    </div> */}
                </div>
            </section>           
        </>
    )
}

export default AboutUs
