import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import  BaseUrl  from "../utils/config";

export const TrendingAPI = createApi({
  reducerPath: "TrendingAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BaseUrl}`,
  }),
  tagTypes: ["SelectTrendingAPI"],
  endpoints: (builder) => ({
    getAllTrending: builder.mutation({
      query: (payload) => ({
        url: `story/listing`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SelectTrendingAPI"],
    }),
    selectEmoji: builder.mutation({
      query: (payload) => ({
        url: `storyemojicomments`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SelectTrendingAPI"],
    }),
    addBookmark: builder.mutation({
      query: (payload) => ({
        url: `bookmarkstory`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SelectTrendingAPI"],
    }),
    updateUserFollowUP: builder.mutation({
      query: (payload) => ({
        url: `user/follow`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SelectTrendingAPI"],
    }),
    getBookmarksList: builder.query({
      query: ({ userId }) => ({
        url: `bookmarkstory?user_id=${userId}`,
        method: "GET",
      }),
      providesTags: ["SelectTrendingAPI"],
    }),
    removeBookmark: builder.mutation({
      query: ({ userId, storyId }) => ({
        url: `bookmarkstory?log_in_user_id=${userId}&story_id=${storyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SelectTrendingAPI"],
    }),

    gotoSourceUserDetials: builder.query({
      query: ({ userId ,sourceUserId}) => ({
        url: `user/gotosource?login_user_id=${userId}&source_user_id=${sourceUserId}`,
        method: "GET",
      }),
      providesTags: ["SelectTrendingAPI"],
    }),
    getAllFeedLanguages: builder.query({
      query: () => ({
        url: `newsfeedlanguage/available`,
        method: "GET",
      }),
      providesTags: ["SelectTrendingAPI"],
    }),
   
  }),
});

export const {
  useGetAllTrendingMutation,
  useSelectEmojiMutation,
  useAddBookmarkMutation,
  useGetBookmarksListQuery,
  useRemoveBookmarkMutation,
  useGotoSourceUserDetialsQuery,
  useUpdateUserFollowUPMutation,
  useGetAllFeedLanguagesQuery
} = TrendingAPI;
