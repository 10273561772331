import React, { useEffect, useState } from 'react'
import {TranslateTeluguWords,TranslateEnglishWords,teluguFontStyle, teluguFontStyleHeading} from '../../Translation/Words'
import { useSelector } from 'react-redux'
let translatedWord = null

let langId = []

const TranslateWord = ({text,headingStyle,lang}) => {
  // console.log('translate',text,lang)
const [translateWord,setTranslateWord] = useState(null);
const {data:interfaceLanguagesData,selectedInterfaceLanguage} = useSelector(state => state.interfaceLanguageList)
// console.log('translate',selectedInterfaceLanguage)
useEffect(() =>{
  if(lang?.name=== 'తెలుగు' || lang?.name === 'Telugu' ){
    translatedWord = TranslateTeluguWords?.filter((word)=>{
      if(word?.value ===  text){
        return word
      }   
    })
    setTranslateWord(translatedWord)
  }
  if(lang?.name === 'English'){
    translatedWord = TranslateEnglishWords?.filter((word)=>{
      if(word?.value ===  text){
        return word
      }   
    })
    setTranslateWord(translatedWord)
  }
},[text,lang])
// useEffect(()=>{  
//    langId = interfaceLanguagesData?.filter((lang) => {  
//     if (lang?.interface_lang_id == lang?.interface_lang_id) {
//       return lang;
//     }
//   });
// if(lang?.name=== 'తెలుగు' || lang?.name === 'Telugu' ){
//   translatedWord = TranslateTeluguWords?.filter((word)=>{
//     if(word?.value ===  text){
//       return word
//     }   
//   })
// }
// if(lang?.name === 'English'){
//   translatedWord = TranslateEnglishWords?.filter((word)=>{
//     if(word?.value ===  text){
//       return word
//     }   
//   })
// }
// setTranslateWord(translatedWord)
// },[])
// useEffect(()=>{  
//    langId = interfaceLanguagesData?.filter((lang) => {
//     if (lang?.interface_lang_id == +JSON.parse(localStorage.getItem("LanguageId"))) {
//       return lang;
//     }
//   });
// if(lang?.name === 'తెలుగు' || lang?.name === 'Telugu' ){
//   translatedWord = TranslateTeluguWords?.filter((word)=>{
//     if(word?.value ===  text){
//       return word
//     }   
//   })
// }
// if(lang?.name === 'English'){
//   translatedWord = TranslateEnglishWords?.filter((word)=>{
//     if(word?.value ===  text){
//       return word
//     }   
//   })
// }
// setTranslateWord(translatedWord)
// },[lang?.name])


  return <>
          <span style= {lang?.name === 'తెలుగు' ? headingStyle ? teluguFontStyleHeading : teluguFontStyle :{}} dangerouslySetInnerHTML={{ __html: translateWord?.[0]?.lable }}>
            {/* {translateWord?.[0]?.lable} */}
      
          </span>       
        </>
}
export default TranslateWord