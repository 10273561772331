import { Modal } from 'antd'
import React, { useState } from 'react';
import closeCircleIcon from '../../imgaes/closeCircleIcon.svg'

const ReasonModel = ({openFlag,selectedStory,reasonTitle,handleModelFlag}) => {
    const [isModalOpen, setIsModalOpen] = useState(openFlag);
    console.log('selectedStory',selectedStory)

    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        handleModelFlag()
      };
  return (
    <div>
          <Modal
      
        width={500}        
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
       
      >
        <div style={{marginTop:'5vh'}}>
            <div style={{textAlign:'center'}}><img src={closeCircleIcon} width={80} height={80}/></div>
            <div style={{textAlign:'center'}}> {reasonTitle}</div>
            <div><span>Story Id : </span> {selectedStory?.story_id}</div>
            <div> {selectedStory?.title}</div>
            <div> Reason :</div>
            <div> {selectedStory?.reason}</div>
        </div>
      </Modal>
    </div>
  )
}

export default ReasonModel