import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  joinVolunteerData: [],
  involvedData:[], 
  status: "idle",
  error: "", 
  volunteerInfo:[] ,
  volunteerActivitesData:[],
  volunteerAreaOfExpertiseData:[]
};

const JoinVolunteerSlice = createSlice({
  name: "joinVolunteer",
  initialState,
  reducers: {   
  },
  extraReducers: (builder) => {
       builder.addCase(
      getJoinVolunteerData.pending,
      (state, action) => {
        state.joinVolunteerData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getJoinVolunteerData.fulfilled,
      (state, action) => {
        state.joinVolunteerData = action?.payload?.data;
        state.status = "idle";
      }
    );
    builder.addCase(
      getJoinVolunteerData.rejected,
      (state, action) => {
        state.joinVolunteerData = [];
        state.status = "Something went wrong";
      }
    );    
    builder.addCase(
        getInvolvedData.pending,
        (state, action) => {
          state.involvedData = [];
          state.status = "loading";
        }
      );
      builder.addCase(
        getInvolvedData.fulfilled,
        (state, action) => {
          state.involvedData = action?.payload?.data;
          state.status = "idle";
        }
      );
      builder.addCase(
        getInvolvedData.rejected,
        (state, action) => {
          state.involvedData = [];
          state.status = "Something went wrong";
        }
      );
      builder.addCase(
        updateVolunteerSignup.pending,
        (state, action) => {         
          state.status = "loading";
        }
      );
      builder.addCase(
        updateVolunteerSignup.fulfilled,
        (state, action) => {          
          state.status = "idle";
        }
      );
      builder.addCase(
        updateVolunteerSignup.rejected,
        (state, action) => {         
          state.status = "Something went wrong";
        }
      );

      builder.addCase(
        updateVolunteerAdditionalInfo.pending,
        (state, action) => {         
          state.status = "loading";
        }
      );
      builder.addCase(
        updateVolunteerAdditionalInfo.fulfilled,
        (state, action) => {          
          state.status = "idle";
        }
      );
      builder.addCase(
        updateVolunteerAdditionalInfo.rejected,
        (state, action) => {         
          state.status = "Something went wrong";
        }
      );

      builder.addCase(
        updateVolunteerKYC.pending,
        (state, action) => {         
          state.status = "loading";
        }
      );
      builder.addCase(
        updateVolunteerKYC.fulfilled,
        (state, action) => {          
          state.status = "idle";
        }
      );
      builder.addCase(
        updateVolunteerKYC.rejected,
        (state, action) => {         
          state.status = "Something went wrong";
        }
      );

      builder.addCase(
        getJoinVolunteerInfoData.pending,
        (state, action) => {         
          state.volunteerInfo = [];
          state.status = "loading";
        }
      );
      builder.addCase(
        getJoinVolunteerInfoData.fulfilled,
        (state, action) => { 
          state.volunteerInfo = action?.payload?.data;         
          state.status = "idle";
        }
      );
      builder.addCase(
        getJoinVolunteerInfoData.rejected,
        (state, action) => { 
          state.volunteerInfo = [];        
          state.status = "Something went wrong";
        }
      );

      builder.addCase(
        getVolunteerActivites.pending,
        (state, action) => {         
          state.volunteerActivitesData = [];
          state.status = "loading";
        }
      );
      builder.addCase(
        getVolunteerActivites.fulfilled,
        (state, action) => { 
          state.volunteerActivitesData = action?.payload?.data;         
          state.status = "idle";
        }
      );
      builder.addCase(
        getVolunteerActivites.rejected,
        (state, action) => { 
          state.volunteerActivitesData = [];        
          state.status = "Something went wrong";
        }
      );

      builder.addCase(
        updateVolunteerActivities.pending,
        (state, action) => {   
        state.status = "loading";
        }
      );
      builder.addCase(
        updateVolunteerActivities.fulfilled,
        (state, action) => {            
          state.status = "idle";
        }
      );
      builder.addCase(
        updateVolunteerActivities.rejected,
        (state, action) => {              
          state.status = "Something went wrong";
        }
      );

      builder.addCase(
        getAreaOfExpertise.pending,
        (state, action) => {   
        state.status = "loading";
        state.volunteerAreaOfExpertiseData = []
        }
      );
      builder.addCase(
        getAreaOfExpertise.fulfilled,
        (state, action) => {            
          state.status = "idle";
          state.volunteerAreaOfExpertiseData = action?.payload?.data;
        }
      );
      builder.addCase(
        getAreaOfExpertise.rejected,
        (state, action) => {              
          state.status = "Something went wrong";
          state.volunteerAreaOfExpertiseData = []
        }
      );
  },
});



export const getJoinVolunteerData = createAsyncThunk(
  "joinVolunteer/get",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/profile/${payload}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);
export const getInvolvedData  = createAsyncThunk(
    "involved/get",
    async (interfaceLangId) => {
      const data = await fetch(`${BaseUrl}/volunteerinvolvementtype/active/${interfaceLangId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await data.json();
      return result;
    }
  );
  export const getAreaOfExpertise  = createAsyncThunk(
    "areaOfExpertise/get",
    async (interfaceLangId) => {
      const data = await fetch(`${BaseUrl}/VolunteerWorks/active/${interfaceLangId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await data.json();
      return result;
    }
  );
  export const getJoinVolunteerInfoData  = createAsyncThunk(
    "joinVolunteerInfoData/get",
    async (user_id) => {
      const data = await fetch(`${BaseUrl}/volunteer/volinfo/${user_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await data.json();
      return result;
    }
  );
  export const updateVolunteerSignup = createAsyncThunk(
    "volunteer_signup/put",
    async (payload) => {
      const data = await fetch(`${BaseUrl}/volunteer/signup`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await data.json();
      return result;
    }
  );
  export const updateVolunteerAdditionalInfo = createAsyncThunk(
    "volunteer_additionalInfo/post",
    async (payload) => {
      const data = await fetch(`${BaseUrl}/volunteer/additionalinformation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await data.json();
      return result;
    }
  );
  export const updateVolunteerKYC = createAsyncThunk(
    "volunteer_KYC/post",
    async (payload) => {
      const data = await fetch(`${BaseUrl}/volunteer/kycimages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await data.json();
      return result;
    }
  );

  export const getVolunteerActivites  = createAsyncThunk(
    "volunteerActivites/get",
    async (interfaceLangId) => {
      const data = await fetch(`${BaseUrl}/VolunteerActivites/active/${interfaceLangId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await data.json();
      return result;
    }
  );

  export const updateVolunteerActivities = createAsyncThunk(
    "volunteer_activities/put",
    async (payload) => {
      const data = await fetch(`${BaseUrl}/volunteer/intrestedactivites`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await data.json();
      return result;
    }
  );
  export const updateDetailsOfSelf = createAsyncThunk(
    "detailsofself/put",
    async (payload) => {
      const data = await fetch(`${BaseUrl}/volunteer/detailsofself`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await data.json();
      return result;
    }
  );
export default JoinVolunteerSlice.reducer;
