import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import  BaseUrl  from '../utils/config';


export const ChattingAPI = createApi({
  reducerPath: "ChattingAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BaseUrl}`,
  }),
  tagTypes: ["SelectChattingAPI"],
  endpoints: (builder) => ({
    createChat: builder.mutation({
      query: (payload) => ({
        url: `/AppStoryComment`,
        method: "POST",
        body: payload,
        // headers: {
        //   Authorization: `Bearer ${String(localStorage.getItem("loginToken"))}`,
        // },
      }),
      invalidatesTags: ["SelectChattingAPI"],
    }),
    getChatData: builder.query({
      query: ({ storyId }) => ({
        url: `/AppStoryComment?storyId=${storyId}`,
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${String(localStorage.getItem("loginToken"))}`,
        // },
      }),
      providesTags: ["SelectChattingAPI"],
    }),
 
    replyChat: builder.mutation({
      query: (payload) => ({
        url: `AppStoryComment/reply`,
        method: "POST",
        body: payload,
        // headers: {
        //   Authorization: `Bearer ${String(localStorage.getItem("loginToken"))}`,
        // },
      }),
      invalidatesTags: ["SelectChattingAPI"],
    }),      
  }),
});

export const {
  useCreateChatMutation,
  useGetChatDataQuery,
  useReplyChatMutation    
} = ChattingAPI;
