import React, { useState, useRef } from "react";
import spinner from "../../imgaes/spinner.gif";
import whatsappIcon from "../../imgaes/whatsappIcon.svg";
const LoadingSpinner = () => {
  return (
    <>
      <section class="bg-body">
        <div class="container">
          {/* <img alt="rotate" src={whatsappIcon} className="rotateLogo"/>    */}
          <div
            class="row"
            style={{
              width: "6%",
              margin: "auto",
              position: "relative",
              top: "200px",
            }}
          >
            <img src={spinner} />
            {/* <div class="content">
                      <h6>Loading...</h6>
                      <h6>Loading...</h6>
                  </div> */}
            <h2 class="loader">
              {/* <section>
        <h1 data-text="Geekboots...">Geekboots...</h1>
    </section> */}
              {/* <span>L</span>
		<span>o</span>
		<span>a</span>
		<span>d</span>
		<span>i</span>
		<span>n</span>
		<span>g</span> */}
              {/* <span>.</span>
    <span>.</span>
    <span>.</span> */}
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};
export default LoadingSpinner;
