
import React, { useState } from 'react';
import { Form, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
    useGetAllActiveNewsFeedQuery,
    useGetAllNewsFeedSelectMutation,
} from '../../Services/LanguageAPI';

function NewsFeedLanguage() {
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const { data: grievanceCategoryData } = useGetAllActiveNewsFeedQuery();
    const [saveNewsFeedSelectAPI] = useGetAllNewsFeedSelectMutation();
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            user_id: localStorage.getItem('user_id'),
            user_newsfeed_languages: selectedLanguages.map((langId) => ({
                newsfeed_lang_id: langId,
            })),
        };

        if (payload.user_newsfeed_languages.length > 0) {
            try {
                const response = await saveNewsFeedSelectAPI(payload);
                // Handle response as needed
                // console.log('API response:', response);

                navigate('/WhatInterestsYou', { state: { selectedLanguages } });
            } catch (err) {
                console.error('Error:', err);
            }
        }
    };

    // const handleLanguageClick = (data) => {
    //     debugger
    //     // Toggle the selection of the language
    //     setSelectedLanguages((prevSelectedLanguages) => {
    //         if (prevSelectedLanguages.includes(data.newsfeed_lang_id)) {
    //             return prevSelectedLanguages.filter((id) => id !== data.newsfeed_lang_id);
    //         } else {
    //             return [...prevSelectedLanguages, data.newsfeed_lang_id];
    //         }
    //     });
    // };
    const handleLanguageClick = (data) => {
        setSelectedLanguages((prevSelectedLanguages) => {
            if (data.code === 'All') {
                // If 'ALL' is selected, store only the newsfeed_lang_id of 'ALL', removing others
                return [data.newsfeed_lang_id];
            } else if (prevSelectedLanguages.includes(data.newsfeed_lang_id)) {
                // If 'ALL' is already selected, remove it and add the newsfeed_lang_id of the clicked language
                return [data.newsfeed_lang_id];
            } else {
                // Toggle the selection of the language
                if (prevSelectedLanguages.includes(data.newsfeed_lang_id)) {
                    // If the language is already selected, deselect it
                    return prevSelectedLanguages.filter((id) => id !== data.newsfeed_lang_id);
                } else {
                    // Add the language to the existing selection
                    return [...prevSelectedLanguages, data.newsfeed_lang_id];
                }
            }
        });
    };


    const langData =
        grievanceCategoryData?.data?.filter((record) => record.strStatus === 'Active') || [];
    //console.log("langData::", langData)
    return (
        <>
            <section className="bg-body section-space-less30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30">
                            <div className="login_page">
                                <Link to="/" className="img-fluid">
                                    <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                                </Link>
                                <h1>Select News Feed Language</h1>
                                <br />
                                <h2>Select more than one language</h2>

                                <form>
                                    <div className="news_feed_lang_btn">
                                        {langData.map((record) => (
                                            <span
                                                key={record.newsfeed_lang_id}
                                                onClick={() => handleLanguageClick(record)}
                                                className={selectedLanguages.includes(record.newsfeed_lang_id) ? 'selected-language' : ''}
                                            >
                                                {record.name}
                                            </span>
                                        ))}
                                    </div>
                                    <div className='lang_btn prev_next_button mt-50'>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className='news_feed_btn_prv'>
                                                <Link to='/OtpGenerate'> Previous </Link>
                                            </Button>
                                            <Button onClick={handleSubmit} type="primary" htmlType="submit" className='news_feed_btn_next active'>
                                                <Link to='/WhatInterestsYou'> Next </Link>
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewsFeedLanguage;
