import React from 'react';
import InfoForm from '../InnerPages/InfoForm';

function PolitikosVolunteers() {
    return (
        <>
          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Politikos Volunteers</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/politikosvolunteers.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="news-details-layout1">
                                <p>Politikos volunteers are passionate individuals who work towards community transformation and social change. They engage in various activities such as writing, editing, event organising, and advocating for justice, aiming to address key issues, improve infrastructure, and strengthen community bonds. Their journey offers personal growth, leadership development, and the opportunity to leave a lasting legacy.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpage_list people_center">
                                    <ul>
                                        <li><strong>Make a Difference :</strong> Address key issues and shape your village/ community future.</li>
                                        <li><strong>Become a Leader : </strong>Develop leadership skills and gain respect.</li>
                                        <li><strong>Connect with Like-minded Individuals : </strong>Join a passionate community of change-makers.</li>
                                        <li><strong>Work for society : </strong>Create a responsible community for common good through your service.</li>
                                        <li><strong>Leave a Legacy : </strong>Your contributions will benefit future generations too.</li>
                                        <li><strong>Earn while you work : </strong>Every single activity of yours will earn you money.</li>
                                    </ul>
                                    <p>Politikos offers a wide range of activities and initiatives, so that everyone contributes, grows, and creates a lasting impact.</p>
                                    <p>You can choose activities that align with your interests and skills. The possibilities are endless when you become a Politikos Volunteer. Join us today and unleash your potential !</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
        </>
    )
}

export default PolitikosVolunteers
