import { Button, Col, Collapse, Divider, Dropdown, Row } from "antd";
import React, { useState } from "react";
import TranslateWord from "../sharedComponents/TranslateWord";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backIcon from '../../imgaes/backIcon.svg'

const FAQs = () => {
  const [type, setType] = useState("Story");
  const navigate = useNavigate();  
  const {      
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const items = [
    {
      key: "1",
      label: <a>Story</a>,
      onClick: () => {
        setType("Story");
      },
    },
    {
      key: "2",
      label: <a>Petitions</a>,
      onClick: () => {
        setType("Petitions");
      },
    },
    {
      key: "3",
      label: <a>Grievances</a>,
      onClick: () => {
        setType("Grievances");
      },
    },
  ];

const backToProfile = () =>{
  navigate(`/profile`)
}
  return (
    <>
      <div style={{minHeight: "500px" ,marginBottom:'20px'}}>
        <Row justify="space-around" style={{margin:'20px 0px'}}>
          <Col> <span onClick={backToProfile} style={{cursor:'pointer'}}><img src={backIcon} height={25} width={25}/>&nbsp; back to Profile</span> </Col>
          <Col>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <Button style={{width:'10vw'}}>{type}</Button>
            </Dropdown>
          </Col>
        </Row>
      
        <Row justify="space-evenly">
          <Col span={20} style={{ backgroundColor: "lightgray" }}>  
        
            {type == "Story" && 
            (
              <div>
              <Collapse accordion>
                {Array.from({ length: 14 }).map((_, index) => (
                  <Collapse.Panel
                    key={index}
                    header={
                      <TranslateWord
                      key={`STORY_FAQ_TITLE_${index + 1}`}
                        text={`STORY_FAQ_TITLE_${index + 1}`}
                        lang={selectedInterfaceLanguage}
                        faqsText = {true}
                      />
                    }
                    // Ensure only one direct child within Collapse.Panel
                  >
                    <TranslateWord
                     key={`STORY_FAQ_DESCRIPTION_${index + 1}`}
                      text={`STORY_FAQ_DESCRIPTION_${index + 1}`}
                      lang={selectedInterfaceLanguage}
                      faqsText = {true}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>

         
            )}
            {type == "Grievances" && 
            (
              <div>
              <Collapse accordion>
                {Array.from({ length: 14 }).map((_, index) => (
                  <Collapse.Panel
                    key={index}
                    header={
                      <TranslateWord
                      key={`GRIEVANCE_FAQ_TITLE_${index + 1}`}
                        text={`GRIEVANCE_FAQ_TITLE_${index + 1}`}
                        lang={selectedInterfaceLanguage}
                        faqsText = {true}
                      />
                    }
                    
                  >
                    <TranslateWord
                     key={`GRIEVANCE_FAQ_DESCRIPTION_${index + 1}`}
                      text={`GRIEVANCE_FAQ_DESCRIPTION_${index + 1}`}
                      lang={selectedInterfaceLanguage}
                      faqsText = {true}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>

         
            )}
             {type == "Petitions" && 
            (
              <div>
              <Collapse accordion>
                {Array.from({ length: 14 }).map((_, index) => (
                  <Collapse.Panel
                    key={index}
                    header={
                      <TranslateWord
                      key={`PETITION_FAQ_TITLE_${index + 1}`}
                        text={`PETITION_FAQ_TITLE_${index + 1}`}
                        lang={selectedInterfaceLanguage}
                        faqsText = {true}
                      />
                    }
                    
                  >
                    <TranslateWord
                     key={`PETITION_FAQ_DESCRIPTION_${index + 1}`}
                      text={`PETITION_FAQ_DESCRIPTION_${index + 1}`}
                      lang={selectedInterfaceLanguage}
                      faqsText = {true}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>

         
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FAQs;
