import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error: "",
  loginOTPDetails:{},
  gmailData:[]  
};

const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLogeedInUsers.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getLogeedInUsers.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.status = "idle";
    });
    builder.addCase(sendOTPtoMail.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(sendOTPtoMail.fulfilled, (state, action) => {
      state.gmailData = action?.payload?.data;
      state.status = "idle";
    });
    builder.addCase(getOTPData.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getOTPData.fulfilled, (state, action) => {
      state.loginOTPDetails = action?.payload?.data;
      state.status = "idle";
    });
    builder.addCase(validateUserOTP.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(validateUserOTP.fulfilled, (state, action) => {      
      state.status = "idle";
    });
    builder.addCase(validateUserOTP.rejected, (state, action) => {      
      state.error = "check otp";
    });
    builder.addCase(validateUserGmailOTP.fulfilled, (state, action) => {      
      state.status = "idle";
    });
    builder.addCase(validateUserGmailOTP.rejected, (state, action) => {      
      state.error = "check otp";
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {      
      state.status = "idle";
    });
    builder.addCase(userLogout.rejected, (state, action) => {      
      state.error = "failed to logout";
    });
    
  },
});

export const getLogeedInUsers = createAsyncThunk("Login/get", async () => {
  const data = await fetch(`${BaseUrl}/user/loggedin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});
export const sendOTPtoMail = createAsyncThunk("gmailOTP/get", async ({gmail,user_id}) => {
  const data = await fetch(`${BaseUrl}/user/otp/forgmail/verfication?gmail=${gmail}&user_id=${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});

export const getOTPData = createAsyncThunk("otpData/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/user/generate/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const validateUserOTP = createAsyncThunk("validateOTP/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/user/validate/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const validateUserGmailOTP = createAsyncThunk("validateGmailOTP/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/user/validate/gmail/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const userLogout = createAsyncThunk("logout/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/user/logout/${payload}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export default LoginSlice.reducer;
