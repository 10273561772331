import { Button, Checkbox, Col, Form, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import BaseUrl from "../../utils/config";
import TranslateWord from "../sharedComponents/TranslateWord";
import { useSelector } from "react-redux";

const { Option } = Select;
const StoryOthersTab = ({sendStoryId,tabData,language,closeModal,sendStepInfo}) => {
  console.log('language ',language)
  const [storyOtherDetailsForm] = Form.useForm();

  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allCategoriesIds, setAllCategoriesIds] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategoriesIds, setAllSubCategoriesIds] = useState([]);
  const [allPrimaryTagsIds, setAllPrimaryTagsIds] = useState([]);
  const [allPrimaryTags, setAllPrimaryTags] = useState([]);
  const [defaultValuesForTags, setDefaultValuesForTags] = useState([]);
  const [isAnonymously, setIsAnonymously] = useState(false);
  const [termsComdition1, setTermsComdition1] = useState(false);
  const [termsComdition2, setTermsComdition2] = useState(false);
  const [commentsVisibility, setCommentsVisibility] = useState(true);
  const [clickedButton, setClickedButton] = useState('');

  const {      
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);

  // const [getSubCategoryValidationsAPI] =
  //   useGetSubCategorirsValidationsMutation();  


  const getDropdownsData = async () =>{  
let lang = JSON.parse(localStorage.getItem('feedLang'))
if(!lang){
  lang = language
}
const response = await fetch(`${BaseUrl}/category/language/${lang}`, {
  method: "GET",    
  headers: {
    "Content-Type": "application/json",
  },    
});
const payload = await response.json();
console.log("daaa", payload.data);
if (payload.is_success === true) {
  console.log("daaa", payload.data);
  setAllCategories(payload?.data);
} else {
  message.error({
    content: "Something went wrong",
    duration: 5,
    style: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  });
  
}

const primary_response = await fetch(`${BaseUrl}/Tags/primary/languages/${lang}`, {
  method: "GET",    
  headers: {
    "Content-Type": "application/json",
  },    
});
const primary_res = await primary_response.json();
console.log("daaa", primary_res.data);
if (primary_res.is_success === true) {
  console.log("daaa", primary_res.data);
  setAllPrimaryTags(primary_res?.data);
} else {
  message.error({
    content: "Something went wrong",
    duration: 5,
    style: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  });
}   
    
  }
  useEffect(() =>{
    getDropdownsData(); 
  },[language])

  const getStoryDetailsTabData = async (id) =>{
    const data = await fetch(`${BaseUrl}/story/storytabwisedetails/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      
    });
    const result = await data.json();
    console.log('result othertab',result)
    if(result?.data){  
      getDropdownsData(result?.data?.storyDetails?.newsfeed_lang_name);    
      const categoriesListCopy = result?.data?.storyOtherDetails?.story_categories?.map((val) => {
        return val.category_id;
      });
      setAllCategoriesIds(categoriesListCopy);
      const subCategoriesListCopy = result?.data?.storyOtherDetails?.story_sub_categories?.map((val) => {
        return val.sub_category_id;
      });
      setAllSubCategoriesIds(subCategoriesListCopy);   
      const primaryTagsCopy = result?.data?.storyOtherDetails?.story_primary_tags?.map((val) => {
              return val.tag_name_id;
            });
            console.log("primaryTagsCopy", primaryTagsCopy);
            setAllPrimaryTagsIds(primaryTagsCopy);
            getSubCategoriesList(categoriesListCopy)

setCommentsVisibility(result?.data?.storyOtherDetails?.show_story_comments);
setTermsComdition1(result?.data?.storyOtherDetails?.is_copy_right_checked); 
setTermsComdition2(result?.data?.storyOtherDetails?.is_copy_right_checked);
setIsAnonymously(result?.data?.storyOtherDetails?.post_as_anonymous) 
storyOtherDetailsForm.setFieldsValue({
 
  categories:categoriesListCopy,
  sub_categories:subCategoriesListCopy,
  primaryTags:primaryTagsCopy,
  otherTags:result?.data?.storyOtherDetails?.otherTags ? result?.data?.storyOtherDetails?.otherTags :[],
})
    }
    console.log('result',result)
  }
  useEffect(() =>{
    let lang = JSON.parse(localStorage.getItem('feedLang'))
    getDropdownsData(lang);    
    if(tabData?.id || sendStoryId){
      // getting story details tab data from db
      let Sid = null
         if(tabData?.id){
          Sid = tabData?.id 
         }else{
          if(sendStoryId){
            Sid = sendStoryId 
          }
         }
      getStoryDetailsTabData(Sid);    
    }
  },[tabData?.id])

  const changeCategory = async (value) => {
    if (value?.length > 3) {
      setErrorMsg(" up to 3 options");
    } else {
      getSubCategoriesList(value);
      setErrorMsg("");
    }
  };

  const onChangeAnonymously = (e) => {
    setIsAnonymously(e.target.checked);
  };

  const onChangeTermsCondition1 = (e) => {
    setTermsComdition1(e.target.checked);
  };
  const onChangeTermsCondition2 = (e) => {
    setTermsComdition2(e.target.checked);
  };
  const onChangeCommentsVisibility = (e) => {
    setCommentsVisibility(e.target.checked);
  };

  const getSubCategoriesList = async (value) => {
    if (value?.[0]?.label) {
      const idsList = value.map((val) => {
        return val.value;
      });
      value = idsList;
    }
    if (value?.length === 0) {
      setAllSubCategories([]);
    }
    if (value?.length) {
      const data = await fetch(`${BaseUrl}/category/catIds/${value}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await data.json();
      if (result.is_success === true) {
        console.log("daaa", result.data);      
        setAllSubCategories(result?.data);
      } else {
        console.error("Something went wrong");
      }
    }
  };

  const validateCategoryLimit = (rule, value, callback) => {
    if (value?.length > 3) {
      callback("Please select up to 3 options");
    } else {
      getSubCategoriesList(value)
        .then(() => {
          callback(); // Call callback when there's no error
        })
        .catch((error) => {
          callback("Error occurred while fetching subcategories"); // Handle error case if needed
        });
    }
  };
  const validateCategoryLimitValidations = async (rule, value, callback) => {
    if (value?.[0]?.label) {
      const idsList = value.map((val) => {
        return val.value;
      });
      value = idsList;
    }   
  };

  const validateTagLength = (_, value, callback) => {
    // if (
    //   selectedLanguage === "English" &&
    //   value &&
    //   value.some((tag) => tag.length > 80)
    // ) {
    //   return Promise.reject("Enter up to 80 characters only");
    // }
    // return Promise.resolve();
  };

  const validateTagCount = (_, value) => {
    if (value && value.length > 5) {
      return Promise.reject("Select up to 5 tags only");
    }
    return Promise.resolve();
  };
  const validatePrimaryCount = (_, value) => {
    if (value && value.length > 3) {
      return Promise.reject("Select up to 3 Primary tags only");
    }
    return Promise.resolve();
  };

  const saveStoryOtherTabData = async (payload) => {
    if (payload) {

      if (payload) {
        const response = await fetch(`${BaseUrl}/story/storyotherdetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),         
        });
        const res = await response.json();
        console.log("daaa", res.data);
        if (res.is_success === true) {
          if(res?.message){           
            message.success({
              content: res?.message,
              duration: 5,
              style: {
                marginTop: "20px",
                marginBottom: "20px",
              },
            });
             setTimeout(() => {
              closeModal()
             }, 1000);
          }else{
            message.success({
              content: "Successfully Saved",
              duration: 5,
              style: {
                marginTop: "20px",
                marginBottom: "20px",
              },
            });
            
          }                          
        } else {
          message.error({
            content:"Something went wrong",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
                     
        }        
      }



      // try {
        // await saveStoryOthersTabAPI(payload)
        //   .unwrap()
        //   .then((res) => {
        //     console.log("payload", res);
        //     if (res.is_success === true) {
        //       if(res?.message){
        //         toast.success(res?.message)
        //          setTimeout(() => {
        //           closeModal()
        //          }, 1000);
        //       }else{
        //         toast.success("Successfully Saved");
        //       }                          
        //     } else {
        //       toast.error("Something went wrong");              
        //     }
        //   })
          // .catch((error) => {
          //   toast.error("Something went wrong");
          // });
      // }
      //  catch (err) {       
      //    toast.error("Some thing went wrong");
      // }
    }
  };
  const submitingFormData = (values) => {
 
    const payload = {
      id: sendStoryId,
      is_copy_right_checked: termsComdition1,
      is_final_submission: clickedButton === 'submit',
      is_previously_story_posted: true,
      otherTags: values?.otherTags,
      post_as_anonymous: isAnonymously,
      show_story_comments: commentsVisibility,
      story_categories_ids: values?.categories,
      story_primary_tags_ids:values?.primaryTags,
      story_sub_categories_ids:values?.sub_categories,
      version: 0,
      // volunteer_id: 0,
    };
    localStorage.setItem('storyOthersTabData',JSON.stringify(payload)) 
    saveStoryOtherTabData(payload)
  };


  const getOtherDetailsTabData = () =>{
    const values = storyOtherDetailsForm.getFieldsValue();
    console.log('secondtab',values);
    const payload = {
      id: sendStoryId,
      is_copy_right_checked: termsComdition1,
      is_final_submission: clickedButton === 'submit',
      is_previously_story_posted: true,
      otherTags: values?.otherTags,
      post_as_anonymous: isAnonymously,
      show_story_comments: commentsVisibility,
      story_categories_ids: values?.categories,
      story_primary_tags_ids:values?.primaryTags,
      story_sub_categories_ids:values?.sub_categories,
      version: 0,
      // volunteer_id: 0,
    };
    localStorage.setItem('storyOthersTabData',JSON.stringify(payload));
    sendStepInfo('Previous') 
  }
  return (
    <>
      <Form
        layout="vertical"
        form={storyOtherDetailsForm}
        className="userFormclass"
        onFinish={submitingFormData}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="categories"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"CATEGORIES"}
                text={"CATEGORIES"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                {
                  required: true,
                  message: "Select Categories",
                },

                { validator: validateCategoryLimit },
              ]}
            >
              <Select
                mode="multiple"
                maxTagCount={3}
                defaultValue={allCategoriesIds}
                onChange={changeCategory}
              >
                {allCategories
                  ? allCategories?.map((c) => (
                      <Option key={c.category_id} value={c.category_id}>
                        {c.category_name}
                      </Option>
                    ))
                  : "No data available"}
              </Select>
              {/* {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>} */}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="sub_categories"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"SUB_CATEGORIES"}
                text={"SUB_CATEGORIES"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[
                // {
                //   required: true,
                //   message: "Select Sub categories",
                // },

                { validator: validateCategoryLimitValidations },
              ]}
            >
              <Select
                mode="multiple"
                defaultValue={allSubCategoriesIds}
                // onChange={handleSelectChange}
              >
                {allSubCategories
                  ? allSubCategories?.map((c) => (
                      <Option key={c.sub_category_id} value={c.sub_category_id}>
                        {c.category_name}
                      </Option>
                    ))
                  : "No data available"}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="primaryTags"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"PRIMARY_TAGS"}
                text={"PRIMARY_TAGS"}
                lang={selectedInterfaceLanguage}

              />
               )}
              rules={[{ validator: validatePrimaryCount }]}
            >
              <Select
                mode="multiple"
                // maxTagPlaceholder={renderMaxTagPrimaryPlaceholder}
                defaultValue={allPrimaryTagsIds}
                maxTagCount={4}
              >
                {allPrimaryTags
                  ? allPrimaryTags?.map((c) => (
                      <Option key={c.tag_name_id} value={c.tag_name_id}>
                        {c.tag_name}
                      </Option>
                    ))
                  : "No data available"}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="otherTags"
              label={selectedInterfaceLanguage && (
                <TranslateWord
                key={"OTHER_TAGS"}
                text={"OTHER_TAGS"}
                lang={selectedInterfaceLanguage}

              />
               )}
              // rules={[
              //   { validator: validateTagLength },
              //   { validator: validateTagCount },
              // ]}
            >
              <Select
                mode="tags"
                defaultValue={defaultValuesForTags}
                //   onChange={handleChangeTags}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col>
            <Checkbox
              style={{ marginLeft: 0 }}
              name="post_as_anonymous"
              checked={isAnonymously}
              onChange={onChangeAnonymously}
            >
              {" "}
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"ANONYMOUSLY"}
                text={"ANONYMOUSLY"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              style={{ marginLeft: 0 }}
              name="show_story_comments"
              checked={commentsVisibility}
              onChange={onChangeCommentsVisibility}
            >
              {" "}
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"COMMETS_VISIBILITY"}
                text={"COMMETS_VISIBILITY"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Checkbox>
          </Col>
        </Row>
        <Row style={{ margin: "20px 0px" }}>
          <Col>
            <Checkbox
              style={{ marginLeft: 0 }}
              name="post_as_anonymous"
              checked={termsComdition1}
              onChange={onChangeTermsCondition1}
            >
              {" "}
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_COPYRIGHTS1"}
                text={"STORY_COPYRIGHTS1"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              style={{ marginLeft: 0 }}
              name="post_as_anonymous"
              checked={termsComdition2}
              onChange={onChangeTermsCondition2}
            >
              {" "}
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"STORY_COPYRIGHTS2"}
                text={"STORY_COPYRIGHTS2"}
                lang={selectedInterfaceLanguage}

              />
               )}
            </Checkbox>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: "80px" }}>
          <Col span={4}></Col>
          <Col span={4}>
          <Form.Item>
              <Button type="primary"  onClick={() => getOtherDetailsTabData()}>
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"SAVE"}
                text={"SAVE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit"  onClick={() => setClickedButton('save')}>
              {selectedInterfaceLanguage && (
                <TranslateWord
                key={"SAVE"}
                text={"SAVE"}
                lang={selectedInterfaceLanguage}

              />
               )}
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!(termsComdition1 && termsComdition2)} onClick={() => setClickedButton('submit')}>
                {"Submit"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
       
      </Form>
      <ToastContainer />
    </>
  );
};

export default StoryOthersTab;
