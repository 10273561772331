import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoForm from '../InnerPages/InfoForm';
import { useSelector } from 'react-redux';
import TranslateWord from '../../sharedComponents/TranslateWord';

function Partnerwithus() {
    const {      
        selectedInterfaceLanguage,
      } = useSelector((state) => state.interfaceLanguageList);
    return (
        <>            
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>
                                {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'45px',fontWeight:'700'}}>భాగస్వామ్యం కోసం</span>:'Partner with us'}
                                   </h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/partnerwithus.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>
                                {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',fontWeight:'700'}}>పొలిటికోస్‌తో భాగస్వామ్య అవకాశాలు</span>:' Partnering Opportunities with Politikos'}
                                   </h2>
                                <p>
                                    {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"PARTNERWITHUS_P1"}
                               text={"PARTNERWITHUS_P1"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}</p>
                                <p>
                                {selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"PARTNERWITHUS_P2"}
                               text={"PARTNERWITHUS_P2"}
                               lang={selectedInterfaceLanguage}
                             />
                              )}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1 pa_inner">
                                <h2>
                                {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',fontWeight:'700'}}>పొలిటికోస్‌తో భాగస్వామ్యం అనేక ప్రయోజనాలను అందిస్తుంది</span>:'Partnering with Politikos offers a multitude of benefits:'}
                                    </h2>
                                <div class="innerpage_list people_center">
                                    <ul>
                                        <li><strong>Vast Network :</strong> 
                                      {  selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"VASTNETWORK"}
                               text={"VASTNETWORK"}
                               lang={selectedInterfaceLanguage}                          />
                              )}
                                       </li>
                                        <li><strong>Innovative Solutions : </strong>
                                        {  selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"INNOVATIVE_SOLUTION"}
                               text={"INNOVATIVE_SOLUTION"}
                               lang={selectedInterfaceLanguage}                          />
                              )}
                                        </li>
                                        <li><strong>Parallel ecosystem : </strong>
                                        {  selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"PARALLEL_ECOSYSTEM"}
                               text={"PARALLEL_ECOSYSTEM"}
                               lang={selectedInterfaceLanguage}                          />
                              )}
                                       </li>
                                    </ul>
                                </div>
                                <div class="news-details-layout1 pa_inner">
                                    <h2>
                                    {selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'25px',fontWeight:'700'}}>పొలిటికోస్ మీ లక్ష్యాలకు అనుగుణంగా కస్టమైజ్డ్‌ భాగస్వామ్య అవకాశాలను అందిస్తుంది </span>:'Politikos offers partnership opportunities customized to your goals::'}
                                      </h2>
                                    <div class="innerpage_list people_center">
                                        <ul>
                                            <li><strong>Content Collaboration :</strong>
                                            {  selectedInterfaceLanguage && (
                               <TranslateWord
                               key={"CONTENT_COLLABORATION"}
                               text={"CONTENT_COLLABORATION"}
                               lang={selectedInterfaceLanguage}                          />
                              )}
                                             </li>
                                            <li><strong>Strategic Advertising : </strong>Harnessing the power of our vast online and offline platform is a strategic approach to ensuring your message reaches the right audience and drives conversions through our data-driven insights and strategic target ensuring that your advertising efforts are optimized for maximum impact.</li>
                                            <li><strong>Community Engagement : </strong> Collaborating with Politikos People Centers engages local communities to make a lasting impact and align with a positive social impact and community-focused entity. It's an opportunity to create lasting connections and make a meaningful difference in the lives of societies.</li>
                                            <li><strong>Unlock a Virtual Marketplace :</strong>  Politikos isn't just a media platform; it's a gateway to a virtual marketplace filled with innovation and convenience. Explore curated products that enhance your life, redefine your shopping experience, and bring you closer to a world of possibilities.</li>
                                        </ul>
                                    </div>
                                    <p>Politikos can benefit your brand by offering a multifaceted approach that extends beyond traditional media platforms. The platform's diverse features and extensive network create a unique environment for your brand to create a lasting impact and contribute to your success.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
        </>
    )
}

export default Partnerwithus
