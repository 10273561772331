import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Career() {
    const {      
        selectedInterfaceLanguage,
      } = useSelector((state) => state.interfaceLanguageList);
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>{selectedInterfaceLanguage?.name === "తెలుగు" ?<span style={{ fontFamily: 'Mallanna',fontSize:'45px',fontWeight:'700'}}>ఉద్యోగ అవకాశాలు  (కెరీర్)</span>:'Career'}</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/career_subpage.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>Join our team and be part of an exciting journey. At Politikos, we're always looking for talented individuals who are passionate about work. If you're driven, creative, and eager to make an impact, we invite you to explore our current job openings and consider sending your resume to <a href="mailto: contact@politikos.in">contact@politikos.in</a> to express your interest in joining our dedicated team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default Career
