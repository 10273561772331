import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoForm from '../InnerPages/InfoForm';

function PAffiliates() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Politikos Affiliates</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/politikosaffiliates.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>From primary news feed to infrastructure development and operational technology support to periodical training, Politikos will strengthen the hands of franchise/affiliates to stand in the market as No 1. We have four kinds of affiliates.</p>
                                <div class="border_list people_center">
                                    <p><strong>HUB Plus </strong> - is a state-level affiliate</p>
                                    <p><strong>HUB </strong>is a district-level affiliate</p>
                                    <p><strong>SPOKE </strong>is a divisional-level or small district-level affiliate</p>
                                    <p><strong>MINI </strong>is a Mandal level / major village level affiliate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1 pa_inner">
                                <h2>Deliverables of Politikos to Affiliates</h2>
                                <div class="innerpage_list">
                                    <ul>
                                        <li>Local, State and National level news feed</li>
                                        <li>Marketing Communication material</li>
                                        <li>Technical support in studio design</li>
                                        <li>Support in Affiliate level infrastructure development</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1 pa_inner">
                                <h2>Responsibilities of affiliates</h2>
                                <div class="innerpage_list">
                                    <ul>
                                        <li>Share fact-checked news feed</li>
                                        <li>Upload local content (Edited videos)</li>
                                        <li>Generate advertising revenue in the assigned district</li>
                                        <li>Identification of local content producers</li>
                                        <li>Networking with local government officials, political leaders & Civil society members <br></br>(teachers, doctors, lawyers, retired government employees, NGO professionals)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
        </>
    )
}

export default PAffiliates
