import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import  BaseUrl  from '../utils/config';

export const LanguageAPI = createApi({
    reducerPath: 'LanguageAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BaseUrl}`,
    }),
    tagTypes: [''],
    endpoints: (builder) => ({       
        getAllActiveLanguage: builder.query({
            query: () => ({
                url: '/interfacelanguage/available',
                method: 'GET',
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            providesTags: ['SelectLanguageAPI']
        }),
        getAllLanguage: builder.mutation({
            query: (payload) => ({
                url: `/interfacelanguage/available`,
                method: 'POST',
                body: payload,
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            invalidatesTags: ['SelectLanguageAPI']
        }),
        getAllActiveNewsFeed: builder.query({
            query: () => ({
                url: '/newsfeedlanguage/available',
                method: 'GET',
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            providesTags: ['SelectLanguageAPI']
        }),
        getAllNewsFeed: builder.mutation({
            query: (payload) => ({
                url: `/newsfeedlanguage/available`,
                method: 'POST',
                body: payload,
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            invalidatesTags: ['SelectLanguageAPI']
        }),

        getAllNewsFeedSelect: builder.mutation({
            query: (selectLang) => ({
                url: `/user/newsfeed/lan/select`,
                method: 'POST',
                body: selectLang,
                // headers: {
                //     'Authorization': `Bearer ${String(localStorage.getItem('loginToken'))}`,
                // },

            }),
            invalidatesTags: ['SelectLanguageAPI']
        }),

    })
})
export const {
    useGetAllActiveLanguageQuery,
    useGetAllLanguageMutation,
    useGetAllActiveNewsFeedQuery,
    useGetAllNewsFeedMutation,
    useGetAllNewsFeedSelectMutation
} = LanguageAPI