import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoForm from '../InnerPages/InfoForm';

function AdvertiseWithUs() {
    return (
        <>          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Advertise With Us</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/advertisewithus.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1 contact_us_page">
                                <p>We're thrilled that you're interested in partnering with us to reach a wider audience through our platform. Advertising with Politikos gives you the opportunity to connect with our engaged readers and benefit from our extensive reach. For detailed pricing and package information, please contact our advertising team by filling out the below form or by writing to <a href="mailto: contact@politikos.in">contact@politikos.in</a></p>
                                <h3>Contact Us:</h3>
                                <p>To Inquire about advertising opportunities or request a customised proposal, please fill out the form below.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>           
        </>
    )
}

export default AdvertiseWithUs
