import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetAllActiveLanguageQuery,
  useGetAllLanguageMutation
} from '../../Services/LanguageAPI';


<link
  href="https://fonts.googleapis.com/css2?family=Noto+Sans+Telugu:wght@400;700&display=swap"
  rel="stylesheet"
/>

function InterfaceLanguage() {
  const {
    data: grievanceCategoryData,
    isLoading: loading,
  } = useGetAllActiveLanguageQuery();

  console.log({ grievanceCategoryData })
  // Check if LanguageData is not null or undefined before filtering
  const langData = grievanceCategoryData?.data?.filter((record) => record.strStatus === "Active");

  console.log('langData fff', langData);



  const teluguText = "తెలుగు భాషలో కంపోనెంట్ డిస్ప్లే";
  return (
    <>
      <section className="bg-body section-space-less30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-30">
              <div className="login_page">
                <a href="/" className="img-fluid">
                  <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                </a>
                <h1>Select Interface Language</h1>
                <h1>యాప్ భాషను ఎంచుకోండి</h1>
                <br />
                <h2>Select only one language</h2>
                <h2>ఒక భాషను మాత్రమే ఎంచుకోండి</h2>
                <div className="lang_btn">

                  {langData && langData.map((record) => (
                    <Link to='/LoginLanding' key={record.interface_lang_id}>{record.name}  </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InterfaceLanguage
