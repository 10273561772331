import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Skeleton,
  Tooltip,
} from "antd";
import "antd/dist/antd.css";
import MorEellipsisIcon from "../Sections/Common/MorEellipsisIcon";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import noImg from "../../imgaes/noImg.svg";
import noImgJPG from "../../imgaes/noImgJPG.jpg";
import smileGif from "../../imgaes/smileGif.gif";
import cryIcon from "../../imgaes/cryIcon.gif";
import heartIcon from "../../imgaes/heartIcon.gif";
import angryIcon from "../../imgaes/angryIcon.gif";
import likeIcon from "../../imgaes/likeIcon.gif";
import wowIcon from "../../imgaes/wowIcon.gif";
import headphoneIcon from "../../imgaes/headphoneIcon.svg";
import messageIcon from "../../imgaes/messageIcon.svg";
import whatsappIcon from "../../imgaes/whatsappIcon.svg";
import Modal from "../Modal";
import Emojis from "../sharedComponents/Emojis";
import CommentsModal from "../sharedComponents/CommentsModal";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGotoSourceUserDetails,
  getAllTabWiseDataForGotoSourcePage,
  updateUserFollowUP,
} from "../../Store/GotoSourceSlice";
import TranslateWord from "../sharedComponents/TranslateWord";
let tabData = ["Latest", "Popular", "Most Discussed"];
function GotoSource() {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const { TabPane } = Tabs;
  const source_user_id = queryParams.get("param1");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 4;
  const navigate = useNavigate();
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  let selectedStory = null;
  const {
    data: gotoSourceUserStories,
    tabsData: currentTabData,
    status: gotoSourceStatus,
    gotoSourceUserstatus,
  } = useSelector((state) => state.gotoSourceList);
  const { selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const [activeKey, setActiveKey] = useState(
    selectedInterfaceLanguage?.name === "తెలుగు" ? "తాజావి" : "Latest"
  );
  useEffect(() => {
    tabData =
      selectedInterfaceLanguage?.name === "తెలుగు"
        ? ["తాజావి", "ప్రజాదరణ పొందినవి", "ఎక్కువగా చర్చించినవి"]
        : ["Latest", "Popular", "Most Discussed"];
  }, []);
  useEffect(() => {
    getAllGotoSourceUserDetailsFunc();
  }, [source_user_id]);
  const gettingDataBasedOnSelectedTab = (pageNo) => {
    let user = JSON.parse(localStorage.getItem('LoggedInUserDetails'))
    if (activeKey === "Latest" || activeKey === "తాజావి") {
     
      const payload = {
        login_user_id: user?.user_id,
        source_user_id: source_user_id ?? 0,
        page: pageNo ?? 0,
        pageSize: 4,
        currentTab: "latest",
      };
      dispatch(getAllTabWiseDataForGotoSourcePage(payload));
    } else if (activeKey === "Popular" || activeKey === "ప్రజాదరణ పొందినవి") {
      const payload = {
        login_user_id: user?.user_id,
        source_user_id: source_user_id ?? 0,
        page: pageNo ?? 0,
        pageSize: 4,
        currentTab: "popular",
      };
      dispatch(getAllTabWiseDataForGotoSourcePage(payload));
    } else if (
      activeKey === "Most Discussed" ||
      activeKey === "ఎక్కువగా చర్చించినవి"
    ) {
      const payload = {
        login_user_id: user?.user_id,
        source_user_id: source_user_id ?? 0,
        page: pageNo ?? 0,
        pageSize: 4,
        currentTab: "mostdiscussed",
      };
      dispatch(getAllTabWiseDataForGotoSourcePage(payload));
    }
  };
  useEffect(() => {
    gettingDataBasedOnSelectedTab();
  }, [activeKey]);
  const handlePageChange = (page, pageSize) => {
    gettingDataBasedOnSelectedTab(page - 1);
    setCurrentPage(page);
  };
  const handleTabs = (key) => {
    setActiveKey(key);
    setCurrentPage(1);
  };
  const getLatestData = () => {
    gettingDataBasedOnSelectedTab(currentPage-1);
  };
  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };
  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  const storeStory = (story) => {
    selectedStory = story;
  };
  const getAllGotoSourceUserDetailsFunc = () => {
    const payload = {
      userId: 16,
      sourceUserId: source_user_id ?? 0,
    };
    dispatch(getAllGotoSourceUserDetails(payload));
  };
  const hangleFollowUp = async () => {
    let user = JSON.parse(localStorage.getItem('LoggedInUserDetails'))
    const payload = {
      follow_user: !gotoSourceUserStories?.is_loginuser_follwing_sourceuser,
      follow_user_id: gotoSourceUserStories?.source_login_user_id,
      login_user_id: user?.user_id,
    };
    dispatch(updateUserFollowUP(payload)).then((action) => {
      if (updateUserFollowUP.fulfilled.match(action)) {
        getAllGotoSourceUserDetailsFunc();
      }
    });
  };
  if(gotoSourceStatus === 'loading'){
    return <div> <Skeleton
    active
    title={false}
    paragraph={{ rows: 6, width: [800, 800] }}
  /></div>
}
const goToSourceWitAuthorId = (input_user_id) => {
  navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
};
  return (
    <>
      {gotoSourceUserstatus === "loading" ? (
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 6, width: [1000, 1000] }}
          style={{ position: "relative", left: "15vw" }}
        />
      ) : (
        <>
          <section className="bg-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="politics foryou_page goto_source">
                    <div className="heading_page ">
                      <h1>{gotoSourceUserStories?.firstname}</h1>
                      <button className="follow_goto" onClick={hangleFollowUp}>
                        {gotoSourceUserStories?.is_loginuser_follwing_sourceuser ? (
                          <TranslateWord
                            key={"UNFOLLOW"}
                            text={"UNFOLLOW"}
                            headingStyle={false}
                            lang={selectedInterfaceLanguage}
                          />
                        ) : (
                          <TranslateWord
                            key={"FOLLOW"}
                            text={"FOLLOW"}
                            headingStyle={false}
                            lang={selectedInterfaceLanguage}
                          />
                        )}
                      </button>
                      <p>{gotoSourceUserStories?.breif_about_self} </p>
                    </div>
                    <div className="breadcrumbs-area goto_source">
                      <div className="breadcrumbs-content">
                        <ul>
                          <li>
                            <a>
                              <span>{gotoSourceUserStories?.post_count}</span>
                              &nbsp;
                              <TranslateWord
                                key={"POST"}
                                text={"POST"}
                                headingStyle={true}
                                lang={selectedInterfaceLanguage}
                              />
                            </a>
                          </li>
                          <li>
                            <a>
                              <span>
                                {gotoSourceUserStories?.followers_count}
                              </span>
                              &nbsp;
                              <TranslateWord
                                key={"FOLLOWERS"}
                                text={"FOLLOWERS"}
                                headingStyle={true}
                                lang={selectedInterfaceLanguage}
                              />
                            </a>
                          </li>
                          <li>
                            <a>
                              <span>
                                {gotoSourceUserStories?.following_count}
                              </span>
                              &nbsp;
                              <TranslateWord
                                key={"FOLLOWING"}
                                text={"FOLLOWING"}
                                headingStyle={true}
                                lang={selectedInterfaceLanguage}
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>
            </div>
          </section>
        </>
      )}
      <section className="bg-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mt-20">
              {/* <div className="row"> */}
              <div className="isotope-classes-tab inner_tabs">
                <Tabs activeKey={activeKey} onChange={handleTabs}>
                  {tabData?.map((tabName) => {
                    return (
                      <>
                        <TabPane tab={tabName} key={tabName}>
                          <div className="election all featuredContainer politics_container mt-50">
                            <div className="row">
                              {currentTabData?.data?.length !== 0 ? (
                                currentTabData?.data?.map((record) => {
                                  const originalDate = new Date(
                                    record?.pubDate
                                  );
                                  const currentDate = new Date();
                                  const timeDifference =
                                    currentDate - originalDate;
                                  const hours = Math.floor(
                                    timeDifference / (1000 * 60 * 60)
                                  );
                                  const minutes = Math.floor(
                                    (timeDifference % (1000 * 60 * 60)) /
                                      (1000 * 60)
                                  );
                                  let displayTime;
                                  if (timeDifference < 24 * 60 * 60 * 1000) {
                                    // Less than 24 hours
                                    displayTime =
                                      hours > 0
                                        ? `${hours}hrs ago`
                                        : minutes > 0
                                        ? `${minutes}min ago`
                                        : "Less than a minute ago";
                                  } else {
                                    // More than 24 hours
                                    displayTime =
                                      originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                                  }
                                  return (
                                    <>
                                      <div className="col-sm-3 col-12">
                                        <Card>
                                          <div className="position-relative mb-30 forpage_content">
                                            <Link
                                              to={`/about?param1=${record?.id}`}
                                              className="img-opacity-hover"
                                            >
                                              {record?.image_urls?.[0] ? (
                                                <img
                                                  src={record?.image_urls?.[0]}
                                                  alt="news"
                                                  className="img-fluid width-100 mb-15 title_img"
                                                />
                                              ) : record?.video_url ? (
                                                <>
                                                  {" "}
                                                  <video
                                                    style={{
                                                      width: "298px",
                                                      height: "180px",
                                                      position: "relative",
                                                      top: "-11px",
                                                      zIndex: "2",
                                                    }}
                                                    preload="metadata"
                                                    src={`${record?.video_url}#t=0.1`}
                                                    controls
                                                    muted
                                                  ></video>
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    src={noImgJPG}
                                                    alt="No-Image"
                                                  />
                                                </>
                                              )}
                                            </Link>
                                            <Link
                                              to={`/about?param1=${record?.id}`}
                                              className="title-medium-dark size-lg mb-none"
                                            >
                                              {/* {record?.title.length > 100
                                                ? record?.title.substring(
                                                    0,
                                                    100
                                                  ) + "..."
                                                : record?.title} */}
                                               {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                                 {/* <span dangerouslySetInnerHTML={{ __html: record?.title }} />   */}
                                            </Link>
                                            <Row>
                                              <Col
                                                span={16}
                                                style={{
                                                  marginLeft: "8px",
                                                }}
                                              >
                                                <span>
                                                  <i
                                                    className="fa fa-user"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>{" "}
                                                &nbsp;
                                                {/* {record?.author.length > 5 ? record?.author.substring(0, 5) + "..." : record?.author} */}
                                                <span   onClick={() => {
                                            goToSourceWitAuthorId(
                                              record?.user_id
                                            );
                                          }}>{record?.author}</span>
                                              </Col>
                                              <Col span={7}>
                                                <p
                                                  className="duration_slider"
                                                  style={{
                                                    marginTop: "3px",
                                                  }}
                                                >
                                                  {displayTime}
                                                </p>
                                              </Col>
                                            </Row>
                                            <Row
                                              style={{
                                                marginBottom: "10px",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <Col
                                                span={11}
                                                style={{
                                                  marginTop: "-8px",
                                                  marginLeft: "5px",
                                                }}
                                              >
                                                {record?.emoji_comments?.map(
                                                  (emj) => {
                                                    return (
                                                      <>
                                                        <Badge
                                                          count={emj?.count}
                                                          size="small"
                                                          placement="start"
                                                        >
                                                          {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                                          {emj?.emoji_name ===
                                                            "angry" && (
                                                            <Avatar
                                                              size="small"
                                                              src={
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                    height:
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src={
                                                                    angryIcon
                                                                  }
                                                                />
                                                              }
                                                            />
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "love" && (
                                                            <Avatar
                                                              size="small"
                                                              src={
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                    height:
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src={
                                                                    heartIcon
                                                                  }
                                                                />
                                                              }
                                                            />
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "like" && (
                                                            <Avatar
                                                              size="small"
                                                              src={
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                    height:
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src={likeIcon}
                                                                />
                                                              }
                                                            />
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "laugh" && (
                                                            <Avatar
                                                              size="small"
                                                              src={
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                    height:
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src={smileGif}
                                                                />
                                                              }
                                                            />
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "sad" && (
                                                            <Avatar
                                                              size="small"
                                                              src={
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                    height:
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src={cryIcon}
                                                                />
                                                              }
                                                            />
                                                          )}
                                                          {emj?.emoji_name ===
                                                            "wow" && (
                                                            <Avatar
                                                              size="small"
                                                              src={
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                    height:
                                                                      "18px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src={wowIcon}
                                                                />
                                                              }
                                                            />
                                                          )}
                                                        </Badge>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </Col>
                                              <Col
                                                span={3}
                                                style={{
                                                  marginTop: "-6px",
                                                }}
                                              >
                                                <ul>
                                                  <li className="float-right">
                                                    <span className="social_icons">
                                                      <span
                                                        onClick={() => {
                                                          storeStory(record);
                                                        }}
                                                      >
                                                        <Emojis
                                                          record={record}
                                                          sendLatestData={
                                                            getLatestData
                                                          }
                                                        />
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                              </Col>
                                              <Col
                                                span={9}
                                                style={{
                                                  marginTop: "-6px",
                                                }}
                                              >
                                                <ul>
                                                  <li className="float-right">
                                                    <span className="social_icons">
                                                      {/* <Tooltip
                                                    title="Coming soon"
                                                    color="gray"
                                                  >
                                                    <img
                                                      src={smileIcon}
                                                      alt="icon"
                                                      className="icons_img"
                                                    />
                                                  </Tooltip> */}
                                                      <Tooltip
                                                        title="Coming soon"
                                                        color="gray"
                                                      >
                                                        <img
                                                          src={headphoneIcon}
                                                          className="icons_img"
                                                        />
                                                      </Tooltip>
                                                      <Tooltip
                                                        title="Coming soon"
                                                        color="gray"
                                                      >
                                                      <img
                                                        src={messageIcon}
                                                        alt="icon"
                                                        className="icons_img"
                                                        style={{
                                                          position: "relative",
                                                          top: "2px",
                                                        }}
                                                        // onClick={() => {
                                                        //   hangleCommentsDrawer(
                                                        //     record
                                                        //   );
                                                        // }}
                                                      />
                                                      </Tooltip>
                                                      <WhatsappShareButton
                                                        url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                                        className="Demo__some-network__share-button"
                                                      >
                                                        <img
                                                          src={whatsappIcon}
                                                          alt="icon"
                                                          className="icons_img"
                                                        />
                                                      </WhatsappShareButton>
                                                      <MorEellipsisIcon
                                                        story={record}
                                                        sendLatestData={
                                                          getLatestData
                                                        }
                                                        bookMarkStoryId={
                                                          record?.id
                                                        }
                                                        page='GotoSourcePage'
                                                      />
                                                    </span>
                                                  </li>
                                                </ul>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    textAlign: "center",
                                    marginTop: "100px",
                                    marginBottom: "70px",
                                  }}
                                >
                                  <Empty />
                                </div>
                              )}
                            </div>
                          </div>
                        </TabPane>
                      </>
                    );
                  })}
                </Tabs>
              </div>
              {/* </div> */}
              <Pagination
                className="pull-right"
                showSizeChanger={false}
                current={currentPage}
                pageSize={cardsPerPage}
                total={currentTabData?.totalElements}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>
      {showCommentsDrawer && (
        <CommentsModal
          showDrawer={showCommentsDrawer}
          closeDrawer={handleCloseDrawer}
          story={storyRecord}
        />
      )}
    </>
  );
}
export default GotoSource;
