import React, { Suspense, lazy } from 'react';
import Header from './Header';
//import Brands from './Brands';
// import Trending from './Trending';
import Footer from './Footer';
import Modal from './Modal';
import { Skeleton } from 'antd';
// import PoliticsDashboard from './Sections/Politics/PoliticsDashboard';
// import BreakingNewsMain from './Sections/Breaking News/BreakingNewsMain';
//import TradingSlider from './Sections/Trading/TradingSlider';
//import LiveTvDashboard from './Sections/LiveTV/LiveTvDashboard';
//import PolitikosNewsRoom from './Sections/PolitikosNewsRoom/PolitikosNewsRoom';
//import VideosDashboard from './Sections/Videos/VideosDashboard';
//import LensDashboard from './Sections/Lens/LensDashboard';
// import BusinessDashboard from './Sections/Business/BusinessDashboard';
//import Cities from './Sections/Cities/Cities';
//import ElectionHubDashboard from './Sections/ElectionHub/ElectionHubDashboard';
//import OneEstateDashboard from './Sections/OneEstate/OneEstateDashboard';
//import EducationAdvisorDashboard from './Sections/EducationAdvisor/EducationAdvisorDashboard';
//import HealthcareAdvisorDashboard from './Sections/HealthcareAdvisor/HealthcareAdvisorDashboard';
//import ReviewsRatingsRankingsDashboard from './Sections/ReviewsRatingsRankings/ReviewsRatingsRankingsDashboard';
//import Polls from './Sections/Polls/PollsDashboard';
//import CartoonsDashboard from './Sections/Cartoons/CartoonsDashboard';
//import SurveysDashboard from './Sections/Surveys/SurveysDashboard';
//import PeopleCenterDashboard from './Sections/PeopleCenter/PeopleCenterDashboard';
//import PolitikosAffiliatesDashboard from './Sections/PolitikosAffiliates/PolitikosAffiliatesDashboard';
//import PolitikosVCADashboard from './Sections/PolitikosVolunteersContributorsAssociates/PolitikosVCADashboard';
//import PolitikosFoundationDashboard from './Sections/PolitikosFoundation/PolitikosFoundationDashboard';
//import NewspapersDashboard from './Sections/Newspapers/NewspapersDashboard';
//import MagazinesDashboard from './Sections/Magazines/MagazinesDashboard';
//import GamesDashboard from './Sections/Games/GamesDashboard';
// import TechnologyDashboard from './Sections/Technology/TechnologyDashboard';
const LazyBreakingNewsMain =  lazy(() => import('./Sections/Breaking News/BreakingNewsMain'));
const LazyTrending =  lazy(() => import('./Trending'));
const LazyPoliticsDashboard =  lazy(() => import('./Sections/Politics/PoliticsDashboard'));
const LazyBusinessDashboard =  lazy(() => import('./Sections/Business/BusinessDashboard'));
const LazyTechnologyDashboard =  lazy(() => import('./Sections/Technology/TechnologyDashboard'));

function Home() {
    return (
        <>
            <div id="wrapper">
                <section>
                    {/* <div className='header_bg'> */}
                        {/* <Header /> */}
                        {/* <Brands/> */}
                        
                    {/* </div> */}
                        
                        <Suspense fallback={<div><Skeleton  active title={false} paragraph={{rows:2,width:[1500,1500]}} /></div>}>
                        <LazyTrending />
                        </Suspense>
                        <Suspense fallback={<div><Skeleton active title={false} paragraph={{rows:2,width:[1500,1500]}} /></div>}>
                        <LazyBreakingNewsMain />
                        </Suspense>
                        {/* <BreakingNewsMain /> */}
                    {/* <TradingSlider /> */}
                    {/* <LiveTvDashboard /> */}
                    <Suspense fallback={<div><Skeleton active title={false} paragraph={{rows:2,width:[1500,1500]}} /></div>}>
                        <LazyPoliticsDashboard />
                        </Suspense>
                   
                    {/* <PolitikosNewsRoom /> */}
                    {/* <VideosDashboard /> */}
                    {/* <LensDashboard /> */}
                    <Suspense fallback={<div><Skeleton active title={false} paragraph={{rows:2,width:[1500,1500]}} /></div>}>
                    <LazyBusinessDashboard />
                        </Suspense>
                   
                    {/* <Cities /> */}
                    {/* <ElectionHubDashboard /> */}
                    {/* <OneEstateDashboard />
            <EducationAdvisorDashboard /> 
            <HealthcareAdvisorDashboard /> */}
             <Suspense fallback={<div><Skeleton active title={false} paragraph={{rows:2,width:[1500,1500]}}/></div>}>
             <LazyTechnologyDashboard />
                        </Suspense>
                   
                    {/* <PeopleCenterDashboard />
                    <PolitikosVCADashboard />
                    <PolitikosAffiliatesDashboard />
                    <NewspapersDashboard />
                    <MagazinesDashboard /> */}

                    {/* <ReviewsRatingsRankingsDashboard/>
            <Polls />
            <CartoonsDashboard />
            <SurveysDashboard />
            <PolitikosFoundationDashboard />
            <GamesDashboard /> */}
                    <Footer />
                    <Modal />
                </section>
            </div>
        </>
    )
}

export default Home
