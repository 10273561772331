import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { GoogleLogin, GoogleLogout } from 'react-google-login'; 

function GmailLogin() {
const clientId = '706815682566-r81qkqvjmdiegaqt05m32iticsgl02sk.apps.googleusercontent.com';
const [showLoginButton, setShowLoginButton] = useState(true);
const [showLogoutButton, setShowLogoutButton] = useState(false)

const onLoginSuccess = (res) => {
  console.log('Login Success:', res.profileObj);
  setShowLoginButton(false);
  setShowLogoutButton(true);
}
const onFailureSuccess = (res) => {
  console.log('Login Failed:', res);
}
const onSignoutSuccess = (res) => {
  alert("You have been singed out successfully");
  setShowLoginButton(true);
  setShowLogoutButton(false);
}
  return (
    <>
      <section className="bg-body section-space-less30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-30">
              <div className="login_page">
                <a href="/" className="img-fluid">
                  <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                </a>
                <h2>currently logged in</h2>
                <h1>Sign in with Gmail</h1>
                {showLoginButton ?
                  <GoogleLogin
                    clientId={clientId}
                    buttonText="Login"
                    onSuccess={onLoginSuccess}
                    onFailure={onFailureSuccess}
                    cookiePolicy={'single_host_origin'}
                  /> : null 
                }
                 {showLogoutButton ?
                    <GoogleLogout
                        clientId={clientId}
                        buttonText="Logout"
                        onLogoutSuccess={onSignoutSuccess}
                      ></GoogleLogout>
                  : null
                   }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default GmailLogin
