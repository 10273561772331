import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
    data:[],
   status:'idle',
   error:''
  }

const breakingNewsSlice = createSlice({
  name: "breakingNews",
  initialState ,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBreakingNewsData.pending, (state,action) => {
        state. data = action?.payload
          state.status = "loading";
      });
    builder.addCase(getAllBreakingNewsData.fulfilled, (state,action) => {    
      state. data = action?.payload
      state.status = "idle";
    });
   
    
 }
});


export const getAllBreakingNewsData = createAsyncThunk("breaking/get", async (payload) => {
    const data = await fetch(`${BaseUrl}/story/breakingnews`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  });

export default breakingNewsSlice.reducer;
