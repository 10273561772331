import React from "react";
import "./MoreOptions.css";
import { Link } from "react-router-dom";
import dotsMenu from '../../../imgaes/9dotsMenu.svg';

function MoreOptions({ moreOptionsData }) {
  console.log("moreOptionsData", moreOptionsData);
  return (
    <>
      <div class="cm-e-menu" >
        <ul>
          <li class="topmenu">
            {/* <a>
              {" "}
              <i className="fa fa-th" aria-hidden="true"></i>
            </a> */}
            <img
                                 src={dotsMenu}
                                 alt="playstore"
                                 style={{cursor:'pointer'}}
                              />
             
            <ul class="submenu">           
              {moreOptionsData?.map((item) => {
                return (
                  <>
                    <li>
                      <Link  to={item.path}>{item?.title}</Link>
                      {item?.subCategoryDropdown?.length > 0 && (
                        <>
                          <ul class="submenu" >
                        <div style={{maxHeight:'200px',overflowY:'auto',overflowX:'hidden'}}>
                            {item?.subCategoryDropdown?.map((subItem) => {
                              return (
                                <>
                                  <li>
                                    <Link  to={subItem.path}>  {subItem?.title.length > 10 ? subItem?.title.substring(0, 10) + "..." : subItem?.title}</Link>
                                  </li>
                                </>
                              );
                            })}
                            </div>
                          </ul>
                        </>
                      )}
                    </li>
                  </>
                );
              })}

            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}

export default MoreOptions;
