import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
};

const MaritalStatusSlice = createSlice({
  name: "maritalStatus",
  initialState,
  reducers: {    
  },
  extraReducers: (builder) => {   
    builder.addCase(getMaritalStatusData.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.status = "idle";
    });
    builder.addCase(getMaritalStatusData.rejected, (state, action) => {
        state.data = [];
        state.status = "Failed to Getting Marital Status data";
      });
  },
});

export const getMaritalStatusData = createAsyncThunk(
  "maritalStatus/get",
  async () => {
    const data = await fetch(`${BaseUrl}/MaritalStatus/available`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);


export default MaritalStatusSlice.reducer;
