import { Drawer } from "antd";
import React, { useState } from "react";
import ChatComponent from "../ChatComponent/ChatComponent";

const CommentsModal = ({ showDrawer, closeDrawer, story }) => {
  const [open, setOpen] = useState(showDrawer); 
  localStorage.setItem('avatarUrl',story?.author_profile_url)
  const onClose = () => {
    localStorage.setItem('avatarUrl',null)
    setOpen(false);
    closeDrawer();
  };
  return (
    <>
      <Drawer       
        title= {story?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
        width={450}
        placement="right"
        onClose={onClose}
        open={open}
      >
        {/* <div style={{maxHeight:'500px',overflow:'auto'}}> */}
          <ChatComponent storyId={story?.id} />
        {/* </div> */}
      </Drawer>
    </>
  );
};

export default CommentsModal;
