import React from 'react';

function Services() {
    return (
        <>          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Services</h1></div>
                                <p>Our versatile services transform the lives of people through a holistic approach. As part of our services, we conduct a wide range of activities, such as village engagement activities, competitions and cultural activities. Studio and co-working spaces, video editing and re-recording facilities provide an opportunity for video content producers and business organisations.</p>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <section class="bg-body">
                                    <div class="container menu-list-wrapper">
                                        <div class="row">
                                        <div class="row zoom-gallery menu-list">
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services1.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">People Center</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services2.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Politikos Protects</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services3.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Media Center</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services4.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Book Talent</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services5.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Partner with us</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services6.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Community Connect</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services7.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Studio & Co-working Space</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services8.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Affiliates</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 col-12 menu-item">
                                                <div class="gallery-layout-1 mb-40 border-bottom">
                                                    <div class="popup-icon-hover img-overlay-hover mb-30">
                                                        <a class="" href="">
                                                            <img src="img/imw_img/innerpage_services9.png" alt="news" class="width-100 img-fluid" />
                                                        </a>
                                                    </div>
                                                    <div class="topic-box-top-xs">
                                                        <div class="topic-box-sm color-cod-gray mb-20">Politikos Impact Events</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>          
        </>
    )
}

export default Services
