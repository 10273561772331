import React from 'react';
import { Form, Input, Button } from 'antd';
import {
  useGetAllOtpMutation
} from '../../Services/OtpAPI.js';
import { useNavigate } from 'react-router-dom';

function MobileLogin() {
  const [saveCategoryAPI] = useGetAllOtpMutation();
  //this is also used to navigate pages
  const navigate = useNavigate();
  const OnFinish = async (values) => {
    localStorage.setItem("f_name", values.name);
    localStorage.setItem("f_num", values.mobile);
    const payload = {
      "first_name": values.name,
      "mobile_number": values.mobile
    }
    if (payload) {
      try {
        await saveCategoryAPI(payload)
          .unwrap()
          .then((payload) => {
            localStorage.removeItem("user_id");
            if (payload.is_success === true && payload.data.is_otp_generated === true) {
              localStorage.setItem("user_id", payload.data.user_id);

             
              navigate("/OtpGenerate")
            }
          })

      } catch (err) {
        console.log("Some thing went wrong");
      }
    }
  };
  return (
    <>
      <section className="bg-body section-space-less30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-30">
              <div className="login_page">
                <a href="/" className="img-fluid">
                  <img src="img/imw_img/logo.png" alt="logo" className="img-fluid" />
                </a>
                <h1>Enter your Name & Mobile Number</h1>
                <h2>we will send you a OTP number</h2>
                <Form
                  name="login-form"
                  initialValues={{ remember: true }}
                  onFinish={OnFinish}
                >
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter your name!' }]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>

                  <Form.Item
                    name="mobile"
                    rules={[{ required: true, message: 'Please enter your mobile number!' }]}
                  >
                    <Input placeholder="Mobile Number" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" className='login_mobile'>
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MobileLogin
