import React from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import InfoFormLocation from '../InnerPages/InfoFormLocation';

function CallforPilots() {
    return (
        <>           
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Call for Pilots</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/callforpilots.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>Step into the Spotlight with Politikos</h2>
                                <h2>Call for Pilots : Ignite Your Creative Fire!</h2>
                                <p>Are you an aspiring Writer, Director, or Storyteller? Look no further! Politikos Call for Pilots is here to launch your talent into the stratosphere. Get ready to unleash your imagination and be part of an extraordinary program that will take your ideas from concept to production!</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>Why choose Politikos Call for Pilots as your gateway to success:</h2>
                                <div class="innerpage_list people_center callforpilots">
                                    <h3>Ignite Your Creativity:</h3>
                                    <p>Gain access to our extensive network of affiliates and People Centers, allowing you to reach a diverse and engaged audience across various regions.</p>
                                    <h3>Opportunity Knocks:</h3>
                                    <p>Are you sitting on a brilliant script just waiting to be discovered? Politikos Call for Pilots is your golden opportunity. Submit your exceptional script, and if it's shortlisted, you'll have the chance to see your vision transformed into a professionally produced content masterpiece. It's time to make your mark in the entertainment industry!</p>
                                    <h3>Collaborate and Conquer:</h3>
                                    <p>We believe in the power of collaboration. If your script gets selected, you'll join forces with a team of industry experts, passionate directors, skilled actors, and talented crew members. Together, you'll breathe life into your story, combining your vision with our resources to create an unforgettable viewing experience.</p>
                                    <h3>Unleash Your Potential:</h3>
                                    <p>Politikos Call for Pilots is more than just a program; it's a launchpad for your career. By participating, you gain exposure to industry professionals, networking opportunities, and the chance to showcase your talent to a global audience. Prepare for your creative journey to soar to new heights!</p>
                                    <h3>Simple Submission Process:</h3>
                                    <p>We've streamlined the submission process to make it as easy as possible. Visit our website or app, fill out a simple form, and upload your script. Our dedicated team of experts will carefully review each submission, ensuring that every gem receives the attention it deserves. Get ready to stand out from the crowd!</p>
                                    <h2>Join the Revolutionary program of Politikos Call for Pilots Today!</h2>
                                    <p>Politikos Call for Pilots is your ticket to turning your dreams into reality. Submit your script, unleash your creativity, and be part of the revolution that celebrates innovation, talent, and originality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoFormLocation />
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </>
    )
}

export default CallforPilots
