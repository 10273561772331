import React, { useEffect, useState } from "react";
import { Button, Popover } from "antd";
import smileIcon from '../../imgaes/smileIcon.svg';
import cryIcon from '../../imgaes/cryIcon.gif';
import likeIcon from '../../imgaes/likeIcon.gif';
import dislikeIcon from '../../imgaes/dislikeIcon.gif';
import heartIcon from '../../imgaes/heartIcon.gif';
import wowIcon from '../../imgaes/wowIcon.gif';
import angryIcon from '../../imgaes/angryIcon.gif';
import smileGif from '../../imgaes/smileGif.gif';
import { useGetAllTrendingMutation, useSelectEmojiMutation } from "../../Services/TrendingAPI";
import { useDispatch } from "react-redux";
import { updateEmojisData } from "../../Store/EmojiSlice";

const Emojis = ({record,sendLatestData}) => {
    const [updateEmojiAPI] = useSelectEmojiMutation();
    const dispatch = useDispatch();
    // const [getAllStoriesAPI] = useGetAllTrendingMutation();
    const [selectedEmoji, setSelectedEmoji] = useState('');
        const emojisIconsList = [{"icon":likeIcon,'emoji_name':'like'},
         {"icon":smileGif,'emoji_name':'laugh'}, 
         {"icon":heartIcon,'emoji_name':'love'},  
         {"icon":wowIcon,'emoji_name':'wow'},
         {"icon":cryIcon,'emoji_name':'sad'},
         {"icon":angryIcon,'emoji_name':'angry'}]

    const hangleEmojiSelection = (emojiText) =>{
        setSelectedEmoji(emojiText) 
        updateEmojiInfo(emojiText) 
      } 
  const content = (
    <div>
        {emojisIconsList?.map((emoji) =>{
            return  <span><img style={{width:'25px',height:'25px',cursor:'pointer',marginRight:'5px'}} src={emoji.icon} onClick={()=>hangleEmojiSelection(emoji.emoji_name)}/></span>
        })}
      
    </div>
  );

  // const gettingAllStories = async () => {
  //   const payload = {
  //     news_feed_lang_ids: [60, 70, 80],
  //     page: 0,
  //     pageSize: 500,
  //     login_user_id: 16,
  //   };
  //   if (payload) {
  //     try {
  //       await getAllStoriesAPI(payload)
  //         .unwrap()
  //         .then((payload) => {           
  //           const allStoryListRes = payload?.data ?? []
  //           localStorage.setItem('allStoriesList',JSON.stringify(allStoryListRes))
  //           sendLatestData(payload?.data)
  //         });
  //     } catch (err) {
  //       console.log("Some thing went wrong");
  //     }
  //   }
  // };
  const updateEmojiInfo = async (emojiText) => {    
    const payload = {
        emoji_comment_id: 0,
        emoji_name:emojiText,
        login_user_id: 14,
        story_id: record?.id
    };
    if (payload) {
      dispatch(updateEmojisData(payload)).then((action) => {
        if (updateEmojisData.fulfilled.match(action)) {
          sendLatestData()
        }
      });
    
    }
  };
 

 
  return (
    <>
      <Popover content={content}>
       <span style={{cursor:'pointer',position:'relative',top:'1px',left:'32px',zIndex:'899'}}><img src={smileIcon} className="icons_img"/></span>
      </Popover>
    </>
  );
};

export default Emojis;

