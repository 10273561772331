import React, { useState } from "react";
// import { serviceDropdown } from "./NavItems";
import { Link } from "react-router-dom";
 import "./Dropdown.css";
import { teluguFontStyle } from "../../../Translation/Words";

function Dropdown({servicesData,interfaceLang}) {
  const [dropdown, setDropdown] = useState(true);
  const [serviceDropdown, setServiceDropdown] = useState(servicesData);

  return (
    <>
      <ul
        className={dropdown ? "services-submenu clicked" : "services-submenu"}        
        onClick={() => setDropdown(!dropdown)}
      >
        {serviceDropdown.map((item) => {
          return (
            <li key={item.id} style={{textAlign:'center'}}>
              <Link
                to={item.path}
                className={item.cName}
                onClick={() => setDropdown(false)}
              >
               <span style={interfaceLang === 'తెలుగు' ? teluguFontStyle :{}}>{item.title}</span> 
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
