import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import {
  Avatar,
  Badge,
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Skeleton,
  Tabs,
  Tooltip,
} from "antd";

import whatsappIcon from "../../imgaes/whatsappIcon.svg";
import messageIcon from "../../imgaes/messageIcon.svg";
import smileIcon from "../../imgaes/smileIcon.svg";
import headphoneIcon from "../../imgaes/headphoneIcon.svg";
import adv1 from "../../imgaes/adv1.svg";
import adv2 from "../../imgaes/adv2.svg";
import AdvtHereImg from "../../imgaes/AdvtHereImg.png";
import noImg from "../../imgaes/noImg.svg";
import noImgJPG from "../../imgaes/noImgJPG.jpg";
import smileGif from "../../imgaes/smileGif.gif";
import cryIcon from "../../imgaes/cryIcon.gif";
import heartIcon from "../../imgaes/heartIcon.gif";
import angryIcon from "../../imgaes/angryIcon.gif";
import likeIcon from "../../imgaes/likeIcon.gif";
import wowIcon from "../../imgaes/wowIcon.gif";
import Modal from "../Modal";
import Emojis from "./Emojis";
import MorEellipsisIcon from "../Sections/Common/MorEellipsisIcon";
import CommentsModal from "./CommentsModal";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../utils/config";
import TranslateWord from "./TranslateWord";
import {
  teluguFontStyle,
  teluguFontStyleHeading,
} from "../../Translation/Words";
import { useDispatch, useSelector } from "react-redux";
import { getAllPoliticsData } from "../../Store/PoliticsSlice";
import { getAllEntertainmentData } from "../../Store/EntertainmentSlice";
import { getAllSportsData } from "../../Store/SportsSlice";
import { getAllBusinessData } from "../../Store/BusinessSlice";
import { getAllCategoryData } from "../../Store/categoryWiseSlice";
import { getAllTechnologyData } from "../../Store/TechnologySlice";

let totalCards = 0;
let filterCategoryData = [];
let pageUpdate = 1;
let selectedIngerface = [];
let feedLangData = [];
let ALLWORD = 'All'
const CategoryNews = (props) => {
  const { TabPane } = Tabs;
  let { category, subcategory } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();    
  let activeTab = ''
  let selectedLang = {}
  const [selectedFeedLang,setSelectedFeedLang] = useState({name:'English'}) 
  const [sLang,setSLang] = useState([]) 
  const [categoryData,setCategoryData] = useState([]) 
  let loggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserDetails'))
  const {userSelectedNewsFeedData} = useSelector(state => state.profileSettingsList)
  const {
    data: allPoliticsData,
    status:politicsStatus,
    error:politicsError,
    politicsSelectedLang,
  } = useSelector((state) => state.politicsList);
  const {
    data: allEntertainmentData,
    status:entertainmentStatus,
    error:entertainementError,
    entertainmentSelectedLang,
  } = useSelector((state) => state.entertainmentList);
  const {
    data: allSportsData,
    status:sportsStatus,
    error:sportsError,
    sportsSelectedLang,
  } = useSelector((state) => state.sportsList);
  const {
    data: allBusinessData,
    status:businessStatus,
    error:businessError,
    businessSelectedLang,
  } = useSelector((state) => state.businessList);
  const {
    data: allTechnologyData,
    status:technologyStatus,
    error:technologyError,
    technologySelectedLang,
  } = useSelector((state) => state.technologyList);
  const {
    data: allCategoryWiseData,
    status:categoryWiseStatus,
    error:categoryWiseError,    
  } = useSelector((state) => state.categoryWiseList);
  const {    
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const { data: feedLanguageData } = useSelector(
    (state) => state.feedLanguageList
  );
  const { data: allCategoriesList } = useSelector(
    (state) => state.categoriesList
  );
 useEffect(()=>{
  if(category === 'రాజకీయాలు' || category === 'Politics'){
    selectedLang = politicsSelectedLang
    let langObj = {name:politicsSelectedLang?.name}
    setSelectedFeedLang(langObj) 
    setCategoryData(allPoliticsData);
    ALLWORD = politicsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    activeTab = subcategory ?? translateWordFunc();
    
  }else if(category === 'వినోదం' || category === 'Entertainment'){
    selectedLang = entertainmentSelectedLang
    let langObj = {name:entertainmentSelectedLang?.name}
    setSelectedFeedLang(langObj)    
    setCategoryData(allEntertainmentData)
    ALLWORD = entertainmentSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
    activeTab = subcategory ?? translateWordFunc();
  
  }else if(category === 'క్రీడలు' || category === 'Sports'){
  selectedLang = sportsSelectedLang
  let langObj = {name:sportsSelectedLang?.name}
  setSelectedFeedLang(langObj)    
  setCategoryData(allSportsData)
  ALLWORD = sportsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  activeTab = subcategory ?? translateWordFunc();

}else if(category === 'వాణిజ్యం' || category === 'Business'){
  selectedLang = businessSelectedLang
  let langObj = {name:businessSelectedLang?.name}
  setSelectedFeedLang(langObj)    
  setCategoryData(allBusinessData)
  ALLWORD = businessSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  activeTab = subcategory ?? translateWordFunc();

}else if(category === 'సాంకేతికం' || category === 'Technology'){
  selectedLang = technologySelectedLang
  let langObj = {name:technologySelectedLang?.name}
  setSelectedFeedLang(langObj)    
  setCategoryData(allTechnologyData)
  ALLWORD = technologySelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  activeTab = subcategory ?? translateWordFunc();

}
else{
  selectedLang = selectedInterfaceLanguage
  let langObj = {name:selectedInterfaceLanguage?.name}
  setSelectedFeedLang(langObj)    
  setCategoryData(allCategoryWiseData)
  ALLWORD = selectedInterfaceLanguage?.name === "తెలుగు" ? "అన్ని" : "All";
  activeTab = subcategory ?? translateWordFunc();
}
 },[allPoliticsData,allEntertainmentData,allSportsData,allBusinessData,allCategoryWiseData])
 
 const translateWordFunc = () => {
  if(category === 'రాజకీయాలు' || category === 'Politics'){
    return politicsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  }else if(category === 'వినోదం' || category === 'Entertainment'){
    return entertainmentSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  } else if(category === 'క్రీడలు' || category === 'Sports'){
    return sportsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  }else if(category === 'వాణిజ్యం' || category === 'Business'){
    return businessSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  }else if(category === 'సాంకేతికం' || category === 'Technology'){
    return technologySelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  }
  else{
    return selectedInterfaceLanguage?.name === "తెలుగు" ? "అన్ని" : "All";
  }
};
 
 

  // window.scrollTo(0, 0);
  // let storiesData = JSON.parse(localStorage.getItem("allStoriesList"));
  // const allCategoriesList = JSON.parse(
  //   localStorage.getItem("allCategoriesList")
  // );

  const [categoriesNewsData, setCategoriesNewsData] = useState([]);
  const [filteredStories, setFilteredStories] = useState([]);

  const [oldSubcategory, setOldSubcategory] = useState(subcategory);
  const [currentPage, setCurrentPage] = useState(1);
  feedLangData = JSON.parse(localStorage.getItem("feedLanguagesData")) ?? [];
  selectedIngerface = feedLangData?.filter((cat) => {
    if (
      cat?.newsfeed_lang_id == JSON.parse(localStorage.getItem("LanguageId"))
    ) {
      return cat;
    }
  });
 
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);
  const [activeTabColor, setActiveTabColor] = useState(
    subcategory ?? translateWordFunc()
  );
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);

  const urlPath = decodeURIComponent(window.location.pathname);


  const gettingData = (category, activeTab) => {
    let lang = {}

    if(category === 'రాజకీయాలు' || category === 'Politics'){
      lang = politicsSelectedLang      
    }else if(category === 'వినోదం' || category === 'Entertainment'){
      lang = entertainmentSelectedLang      
    }else if(category === 'క్రీడలు' || category === 'Sports'){
      lang = sportsSelectedLang      
    }else if(category === 'వాణిజ్యం' || category === 'Business'){
      lang = businessSelectedLang      
    }else if(category === 'సాంకేతికం' || category === 'Technology'){
      lang = technologySelectedLang      
    }
    else{
      lang = selectedInterfaceLanguage 
    }
    
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lang?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);

    let feedLangPayload = []
    if(loggedInUserData?.user_id){
      feedLangPayload = userSelectedNewsFeedData?.filter(item => item.selected)?.map(item => item.newsfeed_lang_id);
    }else{
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id
      })
    }  
    setSLang(feedLangPayload)
    const payload = {
      category_name: category,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 9,
      sub_category_name: activeTab !== "" ? activeTab : activeTabColor,
      login_user_id: loggedInUserData?.user_id,
    };
    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    if (activeTab === "All" || activeTab === "అన్ని" || activeTabColor === "All" || activeTabColor === "అన్ని") {
      delete payload.sub_category_name;
    }
    if (payload?.news_feed_lang_ids?.length && (category === 'రాజకీయాలు' || category === 'Politics')){
      dispatch(getAllPoliticsData(payload));
    }else if (payload?.news_feed_lang_ids?.length && (category === 'వినోదం' || category === 'Entertainment')){
        dispatch(getAllEntertainmentData(payload));
      }else if (payload?.news_feed_lang_ids?.length && (category === 'క్రీడలు' || category === 'Sports')){
        dispatch(getAllSportsData(payload));
      }else if (payload?.news_feed_lang_ids?.length && (category === 'వాణిజ్యం' || category === 'Business')){
        dispatch(getAllBusinessData(payload));
      }else if (payload?.news_feed_lang_ids?.length && (category === 'సాంకేతికం' || category === 'Technology')){
        dispatch(getAllTechnologyData(payload));
      }
      else{
        dispatch(getAllCategoryData(payload));
      }
     
  };
  useEffect(() => {
    let urlPath = window.location.pathname.split("/");
    console.log("Current URL:", urlPath[2]);
    urlPath[2] = decodeURIComponent(urlPath?.[2]?.replace(/%20/g, " "));
    if (oldSubcategory !== urlPath[2]) {
      setOldSubcategory(urlPath[2]);
      // setActiveTab(urlPath[2]);
      setActiveTabColor(urlPath[2]);
      activeTab = urlPath[2];
    }
    category = decodeURIComponent(urlPath[1]);
    // if (urlPath[2] == undefined) setTimeout(()=>{setActiveTab("All")},10);
    if (urlPath[2] == undefined || urlPath[2] == "undefined") {
      activeTab = translateWordFunc();
      setActiveTabColor(translateWordFunc());
    }

    // API calling for getting data from server
    gettingData(category, activeTab);
    const subCategories = allCategoriesList?.find(
      (item) => item.category_name === category
    )?.str_sub_categories_names;

   if(subCategories?.length > 0) setAllSubCategoriesList([ALLWORD, ...subCategories]);
  }, [window.location.pathname]);

  let cardsPerPage = 9;
  let indexOfLastCard = 6;
  let indexOfFirstCard = 0;
  // let displayedData = [];
  // const gettingStoriesData = (subcategory) => {
  //   if (storiesData?.length > 0) {
  //     if (
  //       (oldSubcategory == undefined || subcategory === activeTab) &&
  //       subcategory === translateWordFunc()
  //     ) {
  //       filterCategoryData = storiesData?.filter((entry) => {
  //         const categories = entry.categories || [];
  //         return categories.some((cat) => cat.name === category);
  //       });
  //     } else {
  //       filterCategoryData = storiesData?.filter((record) => {
  //         return (
  //           record?.categories &&
  //           record?.categories?.some((cat) =>
  //             cat?.sub_categories?.includes(subcategory)
  //           )
  //         );
  //       });
  //     }
  //   }

  //   totalCards =
  //     filterCategoryData?.length > 0 ? filterCategoryData?.length : 0;
  //   indexOfLastCard = currentPage * cardsPerPage;
  //   indexOfFirstCard = indexOfLastCard - cardsPerPage;
  //   const displayedData =
  //     filterCategoryData?.length > 0
  //       ? filterCategoryData?.slice(indexOfFirstCard, indexOfLastCard)
  //       : [];
  //   setCategoriesNewsData(displayedData);
  // };

  useEffect(() => {
    // gettingStoriesData(activeTab);
    // setCurrentPage(1);
  }, [category, activeTab]);
  const handlePageChange = (page, pageSize) => {
    // let pagNo = page-1;
      page && setCurrentPage(page)
    const payload = {
      category_name: category,
      news_feed_lang_ids: sLang,
      page: page - 1,
      pageSize: 9,
      sub_category_name: activeTab,
      login_user_id:loggedInUserData?.user_id
    };
    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    if (activeTab === "All" || activeTab === "అన్ని" || activeTab === '') {
      delete payload.sub_category_name;
    }
    if (payload?.news_feed_lang_ids?.length && (category === 'రాజకీయాలు' || category === 'Politics')){
      dispatch(getAllPoliticsData(payload));
    }else if (payload?.news_feed_lang_ids?.length && (category === 'వినోదం' || category === 'Entertainment')){
        dispatch(getAllEntertainmentData(payload));
      }else if (payload?.news_feed_lang_ids?.length && (category === 'క్రీడలు' || category === 'Sports')){
        dispatch(getAllSportsData(payload));
      }else if (payload?.news_feed_lang_ids?.length && (category === 'వాణిజ్యం' || category === 'Business')){
        dispatch(getAllBusinessData(payload));
      }else if (payload?.news_feed_lang_ids?.length && (category === 'సాంకేతికం' || category === 'Technology')){
        dispatch(getAllTechnologyData(payload));
      }
      else{
        dispatch(getAllCategoryData(payload));
      }
// if(payload?.news_feed_lang_ids?.length)  dispatch(getAllCategoryData(payload))
//   .then((action) => {
//   if (getAllCategoryData.fulfilled.match(action)) {
//     console.log('action',action)
//     setCategoryData(action?.payload?.data)
//   }
// });;
   

    // indexOfLastCard = page * cardsPerPage;
    // indexOfFirstCard = indexOfLastCard - cardsPerPage;
    // totalCards =
    //   filterCategoryData?.length > 0 ? filterCategoryData?.length : 0;

    // const displayedData =
    //   filterCategoryData?.length > 0
    //     ? filterCategoryData?.slice(indexOfFirstCard, indexOfLastCard)
    //     : [];
    // setCategoriesNewsData(displayedData);
    // setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [pageUpdate]);
  const hangleSelectingTab = (subcategory) => {
    setCurrentPage(1);
    pageUpdate = 1;
    setActiveTabColor(subcategory);
    activeTab = subcategory;
    gettingData(category, subcategory);
    // if (oldSubcategory != undefined && subcategory === translateWordFunc())
    //   setOldSubcategory(undefined);
    // setActiveTabColor(subcategory);
    // activeTab = subcategory;
  };

  const getLatestData = (data) => {
    gettingData(category, activeTab);
    // const displayedData = data ? data.slice(indexOfFirstCard, indexOfLastCard) : [];
    // storiesData = data;
    // if (data?.length !== 0) gettingStoriesData(activeTabColor);
    // setTimeout(() => {
    //   setAllStoriesDataList(data);
    // }, 2);
    // dataRefresh = true;
    // setTimeout(() => {
    //   gettingStoriesData(activeTabColor);
    // }, 5);
    // console.log('latestData',data);
    //     let url = window.location.pathname
    //     navigate(`/loading`)
    //     if(activeTab === "All"){
    //       displayedData = data?.filter(entry => {
    //         const categories = entry.categories || [];
    //         return categories.some(cat => cat.name === category);
    //       });
    //       const urlPath = url?.split("/")
    //       if (oldSubcategory !== urlPath?.[2]) {
    //         url = `/${urlPath?.[1]}`
    //       }
    // }else{
    //   displayedData = data?.filter(record => {
    //         return (
    //             record?.categories &&
    //             record?.categories?.some(category => category?.sub_categories?.includes(subcategory ??activeTab))
    //         );
    //     });
    //     const urlPath = url?.split("/")
    //     if (oldSubcategory === urlPath?.[2]) {
    //       url = `/${urlPath?.[1]}`
    //     }
    //      if(activeTab !== "All") url = `${url}/${activeTab}`
    // }
    // if(displayedData?.length > 0)  setTimeout(()=>{ navigate(`${url}`)},10);
  };
  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };

  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  if(politicsStatus === 'loading'){
    return <div> <Skeleton
    active
    title={false}
    paragraph={{ rows: 3, width: [800, 800] }}
  /></div>
  }
  if(entertainmentStatus === 'loading'){
    return <div> <Skeleton
    active
    title={false}
    paragraph={{ rows: 3, width: [800, 800] }}
  /></div>
  }
  if(sportsStatus === 'loading'){
    return <div> <Skeleton
    active
    title={false}
    paragraph={{ rows: 3, width: [800, 800] }}
  /></div>
  }
  
  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  // const subCategoryData = allStoriesDataList?.find(item => item?.category_name === "Business")?.map(val => val.str_sub_categories_names )
  return (
    <>
      <section class="bg-body">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="politics foryou_page">
                <div class="heading_page">
                  <h1
                    style={
                      selectedIngerface?.[0]?.name === "తెలుగు"
                        ? teluguFontStyleHeading
                        : {}
                    }
                  >
                    {category}
                  </h1>
                </div>
                <div class="breadcrumbs-area">
                  <div class="breadcrumbs-content">
                    <ul>
                      <li>
                        <Link to="/">
                          {selectedFeedLang && (
                            <TranslateWord
                              key={"home"}
                              text={"HOME"}
                              lang={selectedFeedLang}
                            />
                          )}
                        </Link>
                        /
                      </li>
                      <li
                        style={
                          selectedIngerface?.[0]?.name === "తెలుగు"
                            ? teluguFontStyle
                            : {}
                        }
                      >                       
                        {category}
                      </li>
                      {activeTabColor && (
                        <li
                          style={
                            selectedIngerface?.[0]?.name === "తెలుగు"
                              ? teluguFontStyle
                              : {}
                          }
                        >                         
                          / {activeTabColor}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div class="mb-20-r ne-isotope">
                  <div class="color-cinnabar ">
                    <div
                      class="isotope-classes-tab isotop-btn"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginLeft: "3px",
                      }}
                    >
                      {allSubCategoriesList?.map((type) => {
                        return (
                          <span
                            onClick={() => hangleSelectingTab(type)}
                            style={{
                              marginRight: "20px",
                              fontSize: "16px",
                              cursor: "pointer",
                              color: activeTabColor === type ? "red" : "black",
                            }}
                          >
                            <span
                              style={
                                selectedIngerface?.[0]?.name === "తెలుగు"
                                  ? teluguFontStyle
                                  : {}
                              }
                            >
                              {type}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                    <div class="featuredContainer politics_container">
                      <div class="row allforyou slider_setting">
                        <div class="col-lg-9 col-md-9 mt-10">
                          <div class="row">
                            {categoryData?.data?.length > 0 ? (
                              categoryData?.data?.map((record) => {
                                const originalDate = new Date(record?.pubDate);
                                const currentDate = new Date();
                                const timeDifference =
                                  currentDate - originalDate;
                                const hours = Math.floor(
                                  timeDifference / (1000 * 60 * 60)
                                );
                                const minutes = Math.floor(
                                  (timeDifference % (1000 * 60 * 60)) /
                                    (1000 * 60)
                                );

                                let displayTime;
                                if (timeDifference < 24 * 60 * 60 * 1000) {
                                  // Less than 24 hours
                                  displayTime =
                                    hours > 0
                                      ? `${hours} hrs ago`
                                      : minutes > 0
                                      ? `${minutes} min ago`
                                      : "Less than a minute ago";
                                } else {
                                  // More than 24 hours
                                  displayTime =
                                    originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                                }
                                return (
                                  <>
                                    <div class="col-sm-4 col-12">
                                      <div class="position-relative mt-10 mt-10 forpage_content">
                                        <Link
                                          to={`/about?param1=${record?.id}&category=${category}&subcategory=${activeTab}`}
                                          class="img-opacity-hover"
                                        >
                                          {/* <img src={record?.image_urls?.[0]} alt="news" class="img-fluid width-100 mb-15 title_img" /> */}
                                          {record?.image_urls?.[0] ? (
                                            <img
                                              src={record?.image_urls?.[0]}
                                              alt="news"
                                              className="img-fluid width-100 mb-15 title_img"
                                            />
                                          ) : record?.video_url ? (
                                            <>
                                             
                                              <video
                                                style={{
                                                  width: "298px",
                                                  height: "180px",
                                                  position: "relative",
                                                  top: "-1px",
                                                  zIndex: "2",
                                                }}
                                                preload="metadata"
                                                src={`${record?.video_url}#t=0.1`}
                                                controls
                                                muted
                                              ></video>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={noImgJPG}
                                                alt="No-Image"
                                              />
                                            </>
                                          )}
                                        </Link>
                                        <Link
                                          to={`/about?param1=${record?.id}&category=${category}&subcategory=${activeTab}`}
                                          class="title-medium-dark size-lg mb-none"
                                        >
                                          {/* {record?.title.length > 60
                                            ? record?.title.substring(0, 60) +
                                              "..."
                                            : record?.title} */}
                                            {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                        </Link>
                                        <Row>
                                          <Col
                                            span={16}
                                            style={{
                                              marginLeft: "8px",
                                            }}
                                          >
                                            <span>
                                              <i
                                                className="fa fa-user"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            &nbsp;
                                            {/* {record?.author.length > 5 ? record?.author.substring(0, 5) + "..." : record?.author} */}
                                           <span   onClick={() => {
                                            goToSourceWitAuthorId(
                                              record?.user_id
                                            );
                                          }}>{record?.author}</span>
                                            
                                          </Col>
                                          <Col span={7}>
                                            <p
                                              className="duration_slider"
                                              style={{
                                                marginTop: "3px",
                                              }}
                                            >
                                              {displayTime}
                                            </p>
                                          </Col>
                                        </Row>
                                        <Row
                                          style={{
                                            marginBottom: "10px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          <Col
                                            span={11}
                                            style={{
                                              marginTop: "-8px",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {record?.emoji_comments?.map(
                                              (emj) => {
                                                return (
                                                  <>
                                                    <Badge
                                                      count={emj?.count}
                                                      size="small"
                                                      placement="start"
                                                    >
                                                      {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                                      {emj?.emoji_name ===
                                                        "angry" && (
                                                        <Avatar
                                                          size="small"
                                                          src={
                                                            <img
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={angryIcon}
                                                            />
                                                          }
                                                        />
                                                      )}
                                                      {emj?.emoji_name ===
                                                        "love" && (
                                                        <Avatar
                                                          size="small"
                                                          src={
                                                            <img
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={heartIcon}
                                                            />
                                                          }
                                                        />
                                                      )}
                                                      {emj?.emoji_name ===
                                                        "like" && (
                                                        <Avatar
                                                          size="small"
                                                          src={
                                                            <img
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={likeIcon}
                                                            />
                                                          }
                                                        />
                                                      )}
                                                      {emj?.emoji_name ===
                                                        "laugh" && (
                                                        <Avatar
                                                          size="small"
                                                          src={
                                                            <img
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={smileGif}
                                                            />
                                                          }
                                                        />
                                                      )}
                                                      {emj?.emoji_name ===
                                                        "sad" && (
                                                        <Avatar
                                                          size="small"
                                                          src={
                                                            <img
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={cryIcon}
                                                            />
                                                          }
                                                        />
                                                      )}
                                                      {emj?.emoji_name ===
                                                        "wow" && (
                                                        <Avatar
                                                          size="small"
                                                          src={
                                                            <img
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={wowIcon}
                                                            />
                                                          }
                                                        />
                                                      )}
                                                    </Badge>
                                                  </>
                                                );
                                              }
                                            )}
                                          </Col>
                                          <Col
                                            span={3}
                                            style={{
                                              marginTop: "-6px",
                                            }}
                                          >
                                            <ul>
                                              <li className="float-right">
                                                <span className="social_icons">
                                                  <Emojis
                                                    record={record}
                                                    sendLatestData={
                                                      getLatestData
                                                    }
                                                  />
                                                </span>
                                              </li>
                                            </ul>
                                          </Col>
                                          <Col
                                            span={9}
                                            style={{
                                              marginTop: "-6px",
                                            }}
                                          >
                                            <ul>
                                              <li className="float-right">
                                                <span className="social_icons">
                                                  {/* <Tooltip
                                                    title="Coming soon"
                                                    color="gray"
                                                  >
                                                    <img
                                                      src={smileIcon}
                                                      alt="icon"
                                                      className="icons_img"
                                                    />
                                                  </Tooltip> */}

                                                  <Tooltip
                                                    title="Coming soon"
                                                    color="gray"
                                                  >
                                                    <img
                                                      src={headphoneIcon}
                                                      className="icons_img"
                                                    />
                                                  </Tooltip>
                                                  <Tooltip
                                                    title="Coming soon"
                                                    color="gray"
                                                  >
                                                  <img
                                                    src={messageIcon}
                                                    alt="icon"
                                                    className="icons_img"
                                                    style={{
                                                      position: "relative",
                                                      top: "2px",
                                                    }}
                                                    // onClick={() => {
                                                    //   hangleCommentsDrawer(
                                                    //     record
                                                    //   );
                                                    // }}
                                                  />
                                                     </Tooltip>
                                                  <WhatsappShareButton
                                                    url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                                    className="Demo__some-network__share-button"
                                                  >
                                                    <img
                                                      src={whatsappIcon}
                                                      alt="icon"
                                                      className="icons_img"
                                                    />
                                                  </WhatsappShareButton>

                                                  <MorEellipsisIcon
                                                    story={record}
                                                    sendLatestData={
                                                      getLatestData
                                                    }
                                                    bookMarkStoryId={record?.id}
                                                    page='CategoryNewsPage'
                                                  />
                                                </span>
                                              </li>
                                            </ul>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  textAlign: "center",
                                  marginTop: "100px",
                                }}
                              >
                                <Empty />
                              </div>
                            )}
                          </div>                        
                          {categoryData?.data?.length !== 0 && (
                            <Pagination
                              className="pull-right"
                              current={currentPage}
                              pageSize={cardsPerPage}
                              total={categoryData?.totalElements}
                              onChange={handlePageChange}
                            />
                          )}
                        </div>
                        <div class="ne-sidebar sidebar-break-md col-lg-3 col-md-12">
                          <div class="sidebar-box">
                            <div class="ne-banner-layout1 text-center">
                              <a href="/">
                                <img
                                  src={AdvtHereImg}
                                  alt="adv"
                                  class="img-fluid"
                                  style={{ marginBottom: "5px" }}
                                />
                              </a>
                              <a href="/">
                                <img src={AdvtHereImg} alt="adv" class="img-fluid" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
      </section>
      {showCommentsDrawer && (
        <CommentsModal
          showDrawer={showCommentsDrawer}
          closeDrawer={handleCloseDrawer}
          story={storyRecord}
        />
      )}
    </>
  );
};

export default CategoryNews;
