import React from 'react';
import InfoForm from '../InnerPages/InfoForm';

function BookTalent() {
    return (
        <>          
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Book Talent</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/book_talent.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <p>If you are looking for talents for your private, corporate, or institutional events or fundraising events, please register with us. Politikos is in the process of creating a talent pool to meet your needs.</p>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-30">
                            <div class="news-details-layout1">
                                <InfoForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </>
    )
}

export default BookTalent
