import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
};

const GenderSlice = createSlice({
  name: "gender",
  initialState,
  reducers: {    
  },
  extraReducers: (builder) => {   
    builder.addCase(getGenderData.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.status = "idle";
    });
    builder.addCase(getGenderData.rejected, (state, action) => {
        state.data = [];
        state.status = "Failed to Getting Gender data";
      });
  },
});

export const getGenderData = createAsyncThunk(
  "gender/get",
  async () => {
    const data = await fetch(`${BaseUrl}/Gender/available`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);


export default GenderSlice.reducer;
