import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = { 
  status: "idle",
  error:''  
};

const BookmarkedStorySlice = createSlice({
  name: "BookmarkedStory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addBookmarkStory.pending, (state,action) => {       
          state.status = "loading";
      });
    builder.addCase(addBookmarkStory.fulfilled, (state, action) => {    
      state.status = "idle";
    });  
      builder.addCase(removeBookmarkStory.pending, (state,action) => {       
        state.status = "loading";
    });
  builder.addCase(removeBookmarkStory.fulfilled, (state, action) => {    
    state.status = "idle";
  }); 
  },
});

export const addBookmarkStory = createAsyncThunk("addBookmarkStory/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/bookmarkstory`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const removeBookmarkStory = createAsyncThunk(
    "removeBookmarkStory/get",
    async ({ userId, storyId }) => {
      const data = await fetch(`${BaseUrl}/bookmarkstory?log_in_user_id=${userId}&story_id=${storyId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await data.json();
      return result;
    }
  );
export default BookmarkedStorySlice.reducer;
